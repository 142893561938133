export default {
  setHoveringTooltip(state, id) {
    state.hoveringTooltip = id;
  },

  clearHoveringTooltip(state) {
    state.hoveringTooltip = null;
  },

  showTooltip(state, id) {
    state.tooltip = id;
  },

  hideTooltip(state) {
    state.tooltip = null;
  },
};
