<!--
  This component handles the rendering of warning and error messages. It does not
  control application flow or perform any blocking or non-blocking actions.
-->
<template>
  <div style="position: relative">
    <Message v-model="showErr" :message="message" color="error" @close="closeMessage('error')" />
    <Message
      v-model="showSuccess"
      :message="message"
      color="success"
      @close="closeMessage('success')"
    />
    <Message
      v-model="showWarn"
      :message="message"
      color="warning"
      @close="closeMessage('warn')"
    ></Message>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { COMPANY_SUPPORT_EMAIL, ERROR_APP, ERROR_USER } from '@/data/constants/constantsMessages';
import Message from './Message';
import { ErrorKeys as K } from '@/data/constants';

export default {
  name: 'Alerts',
  components: {
    Message,
  },

  data() {
    return {
      COMPANY_SUPPORT_EMAIL,
    };
  },

  methods: {
    ...mapMutations('error', { setErrorState: 'setErrorState' }),

    closeMessage(key) {
      this.setErrorState({ key, val: false });
    },
  },

  computed: {
    ...mapState('error', {
      error: (state) => state.error,
      warn: (state) => state.warn,
      success: (state) => state.success,
      message: (state) => state.message,
    }),

    showErr: {
      /**
       * @returns {boolean}
       */
      get() {
        return this.error;
      },

      set(val) {
        const key = 'error';
        this.setErrorState({ key, val });
      },
    },

    showSuccess: {
      /**
       * @returns {boolean}
       */
      get() {
        return this.success;
      },

      set(val) {
        const key = 'success';
        this.setErrorState({ key, val });
      },
    },

    showWarn: {
      /**
       * @returns {boolean}
       */
      get() {
        return this.warn;
      },

      set(val) {
        const key = 'warn';
        this.setErrorState({ key, val });
      },
    },

    /**
     * @returns {string}
     */
    errorText() {
      if (this.error) {
        return ERROR_APP;
      }
      return ERROR_USER;
    },
  },
};
</script>

<style>
.v-snack__wrapper {
  max-width: 400px !important;
  padding-right: 4px !important;
}
</style>
