/**
 * Regular expressions used across the application.
 */

export const EMAIL_RE = /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/;

export const ALPHANUMERIC_RE = /[a-zA-Z\d]/;
export const ALPHANUMERIC_START_RE = /^[a-zA-Z\d]/;
export const ALPHANUMERIC_END_RE = /[a-zA-Z\d]$/;
export const NON_ALPHANUMERIC_RE = /[^a-zA-Z\d]/;

export const HTML_TAGS = /<.+?>|<\/.+?>|&nbsp;/gi;
export const HTML_END_TAGS = /<\/(?:h1|h2|h3|p)+?>/gi;
