/**
 * Line data formatted for Diagram Editor.
 * Represents a connection between two blocks.
 */
export default class Line {
  /**
   * Create a new Line, which is formatted to be consumed by the Diagram Editor.
   * Lines connect blocks to each other.
   * @param {object} line - Object representing a link
   * @param {String} line.id - UUID string
   * @param {String} line.type - link type
   * @param {String} line.label - label text of string - *not currently supported*
   * @param {String} line.head - UUID of head block
   * @param {String} line.tail - UUID of tail block
   */
  constructor(line) {
    this.id = line.id;
    this.type = line.type;
    this.label = line.label;
    this.head = line.head;
    this.tail = line.tail;
  }
}
