<!-- 
  This is the payment method section of the checkout form. It contains the stripe
  card element which we used to accept user cards. Currently it is rendered on the
  subscribe page above the billing address section
-->

<template>
  <section class="mb-5">
    <v-text-field
      label="Name on card"
      v-model="value.nameOnCard"
      solo
      dense
      color="blue lighten-3"
      :rules="[rules.required]"
    ></v-text-field>
    <StripeElementCard
      :pk="STRIPE_PK"
      ref="cardInput"
      hidePostalCode
      :elementStyle="style"
      :elementsOptions="options"
      @element-change="$emit('errorCheck', $event)"
    />
  </section>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { SUB_PAYMENT } from '@/data/constants/constantsMessages';
import { STRIPE_PK } from '@/data/var.config';

export default {
  name: 'Payment',
  components: {
    StripeElementCard,
  },
  props: {
    value: Object,
    getToken: Boolean,
  },
  data() {
    return {
      STRIPE_PK,
      headerText: SUB_PAYMENT,
      rules: {
        required: (value) => !!value || 'Required',
      },
      options: {
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap' }],
      },
      style: {
        base: {
          color: 'black',
          fontFamily: `'Roboto', sans-serif`,
          fontWeight: 'normal',
          fontSize: '16px',
          letterSpacing: 'normal',
          '::placeholder': {
            color: 'rgba(0,0,0,.6)',
          },
        },
        invalid: {
          iconColor: '#fa755a',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
#card-number {
  height: 50px;
  width: 100%;
}
</style>
