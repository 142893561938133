import { render, staticRenderFns } from "./SubscribePrompt.vue?vue&type=template&id=97a53c5c&scoped=true&"
import script from "./SubscribePrompt.vue?vue&type=script&lang=js&"
export * from "./SubscribePrompt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a53c5c",
  null
  
)

export default component.exports