/**
 * Patent Tags is a text tagging system used by Alice to describe the parts of a patent document.
 *
 * We define all the currently used tags here, so we can easily parse them in the specification into HTMl
 */

export const TITLE = '<t>';
export const TITLE_END = '</t>';

export const HEADING = '<h>';
export const HEADING_END = '</h>';
