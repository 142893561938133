/**
 * See docs: https://github.com/auth0/auth0.js
 */

import auth0 from 'auth0-js';
import Vue from 'vue';
import config from '@/data/var.config';
import { ACTION_LOGIN, ACTION_LOGOUT } from '@/data/constants/constantsGoogleAnalyticsEvents';

let webAuth = new auth0.WebAuth({
  domain: config.LOGIN_DOMAIN,
  clientID: config.AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/callback`,
  audience: `https://${config.AUTH_DOMAIN}/api/v2/`,
  responseType: 'token id_token',
  scope: 'openid profile email update:current_user_metadata update:current_user read:current_user',
});

function initializeManagementAPI() {
  // Get access token
  const token = window.localStorage.getItem('access_token');
  if (!token) return;

  // Return an Auth0 management API object
  return new auth0.Management({
    domain: config.LOGIN_DOMAIN,
    token,
  });
}

let auth = new Vue({
  data() {
    return {
      user: null, // Object containing user data
      loading: false, // Whether the app is waiting to receive user data from Auth0
      management: null, // Contains Auth0 API methods
    };
  },

  watch: {
    user: {
      /**
       * Initialize the Auth0 Management API
       * @param user - Value of whatever is being watched. In this case, it's the user data object.
       */
      handler(user) {
        if (user) {
          this.management = initializeManagementAPI();
        }
      },
    },
  },

  computed: {
    token: {
      get: function () {
        return localStorage.getItem('id_token'); // Contains metadata about a user
      },

      /**
       * @param id_token - ID token containing metadata about a user
       */
      set: function (id_token) {
        localStorage.setItem('id_token', id_token);
      },
    },

    accessToken: {
      get: function () {
        return localStorage.getItem('access_token');
      },

      set: function (accessToken) {
        localStorage.setItem('access_token', accessToken);
      },
    },

    expiresAt: {
      get: function () {
        return localStorage.getItem('expires_at');
      },

      /**
       * @param expiresIn - Amount of time remaining before an access token expires FIXME: in milliseconds?
       */
      set: function (expiresIn) {
        let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
        localStorage.setItem('expires_at', expiresAt);
      },
    },
  },

  created() {
    this.management = initializeManagementAPI();
  },

  methods: {
    async universalLogin(options) {
      try {
        await this.$store.dispatch('draft/saveUserContent');

        // FIXME: May need to move this block into a finally section if this is causing problems
        // Track login event with Google Analytics
        this.$gtag.event(ACTION_LOGIN, {
          event_label: options ? options.connection : 'universal-login',
        });

        // Communicate directly with Auth0 to authenticate a user
        webAuth.authorize(options);
      } catch (error) {
        console.error(error);
      }
    },

    login(email, password) {
      this.$store.dispatch('draft/saveUserContent');
      return new Promise((resolve, reject) => {
        webAuth.login(
          {
            email,
            password,
            realm: config.REALM,
          },
          (err, res) => {
            if (err) {
              reject(err);
            }
            this.$gtag.event(ACTION_LOGIN, { event_label: 'email-password' });
            resolve(res);
          }
        );
      });
    },

    signup(email, password, name, nickname, cb) {
      this.$store.dispatch('');
      webAuth.signup(
        {
          email,
          password,
          name,
          nickname,
          connection: config.REALM,
        },
        cb
      );
    },

    logout() {
      this.$gtag.event(ACTION_LOGOUT);
      return new Promise(() => {
        webAuth.logout({
          returnTo: `https://patentpal.com/`,
          clientID: config.AUTH_CLIENT_ID,
        });
        this.clearAuthData();
      });
    },

    isAuthenticated() {
      return new Date().getTime() < this.expiresAt;
    },

    checkSession() {
      return webAuth.checkSession({}, (err, res) => {
        if (res) {
          this.updateAuthData(res);
        }
      });
    },

    async refresh(load = false) {
      this.loading = load;
      return webAuth.checkSession({}, (error, response) => {
        if (response) {
          this.updateAuthData(response);
          this.loading = false;
        }

        if (error) {
          this.loading = false;
          if (error.code === 'login_required') {
            this.clearAuthData();
            window.location.href = `${window.location.origin}/login`;
          }
        }
      });
    },

    handleAuthentication() {
      return new Promise((resolve, reject) => {
        return webAuth.parseHash((err, authResult) => {
          if (authResult) {
            this.updateAuthData(authResult);
            resolve(this.user);
          } else if (err) {
            this.logout();
            reject(err);
          }
        });
      });
    },

    formatUser(idTokenPayload) {
      const metaData =
        idTokenPayload['https://app.patentpal.com/user_metadata'] ||
        idTokenPayload['user_metadata'];
      const appData =
        idTokenPayload['https://app.patentpal.com/app_metadata'] || idTokenPayload['app_metadata'];
      const sub = idTokenPayload['user_id'];
      delete idTokenPayload['https://app.patentpal.com/user_metadata'];
      delete idTokenPayload['https://app.patentpal.com/app_metadata'];
      return { ...idTokenPayload, metaData, appData, ...(sub && { sub }) };
    },

    updateAuthData(authData) {
      this.expiresAt = authData.expiresIn;
      this.accessToken = authData.accessToken;
      this.token = authData.idToken;
      this.user = this.formatUser(authData.idTokenPayload);
    },

    clearAuthData() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      this.user = null;
    },

    async getUserData() {
      await this.management.getUser(this.user.sub, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          this.user = this.formatUser(res);
        }
      });
    },

    async patchUserMetadata(metaData) {
      const { management } = this;

      return management.patchUserMetadata(this.user.sub, metaData, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          this.user.metaData = res.user_metadata;
        }
      });
    },
  },
});

export default {
  /**
   * Connect this plugin with Vue and Vuex
   * @param Vue
   * @param store - Vuex store
   */
  install: function (Vue, store) {
    Vue.prototype.$auth = auth; // Connect Auth to Vue, allowing access to this plugin with this.$auth
    auth.$store = store; // Connect Auth to Vuex
  },
};
