<!-- 
  This is the customization window. It allows users to set custom phrases that we
  use in the generated spec and to also set things like verbose flowchart steps
-->

<template>
  <v-overlay :value="showConfigMenu">
    <v-card
      light
      :width="showPreview ? 1160 : 620"
      height="650"
      class="configMenu"
      v-click-outside="{ handler: close, include: include }"
    >
      <!-- Start close button -->
      <v-row no-gutters justify="end">
        <v-btn icon height="30" width="30" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <!-- End close button -->

      <!-- Start profile list column -->
      <div class="custom">
        <scrollable class-name="column profiles" auto-hide="move">
          <v-list class="py-0 inner-padding customList">
            <draggable v-model="profiles">
              <ProfileListItem
                @delete="deleteItem"
                @clicked="handleClick"
                @nameChanged="saveUserProfiles"
                v-for="(profile, index) in profiles"
                :key="profile.id"
                :profile="profile"
                :index="index"
                :activeProfile="activeProfile.id"
              />
            </draggable>
            <v-list-item @click="add" v-if="profiles.length < 10" :ripple="false">
              <v-list-item-icon class="my-auto mr-3">
                <v-icon size="20">mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>New</v-list-item-title>
            </v-list-item>
          </v-list>
        </scrollable>
        <!-- End profile list column -->

        <!-- Start settings column -->
        <scrollable
          auto-hide="move"
          ref="contentContainer"
          class-name="column customPadding settings"
        >
          <section
            v-for="(section, index) in formSections"
            :key="`section-${index}`"
            class="text-center mb-7"
          >
            <h4>{{ section.title }}</h4>
            <div
              v-for="(option, inputIndex) in section.options"
              :key="`section-${index}-input-${inputIndex}`"
            >
              <v-tooltip
                top
                v-model="option.show"
                transition="none"
                :nudge-bottom="9"
                :ref="`tooltip-${phraseKey(option.label)}`"
                content-class="patentpal-tooltip"
                :position-x="position.x"
                :position-y="position.y"
                eager
              >
                <span v-text="option.tooltipText" />
              </v-tooltip>
              <div
                v-if="option.toggleInput"
                @mouseenter="handleHover($event, option, false)"
                @mouseleave="handleMouseleave($event, option)"
              >
                <v-checkbox
                  v-model="activeProfile[phraseKey(option.label)]"
                  :label="option.label"
                  :ripple="false"
                  color="secondary"
                  hide-details
                />
              </div>
              <v-text-field
                v-else
                hide-details
                dense
                outlined
                filled
                color="initial"
                @mouseenter="handleHover($event, option)"
                @mouseleave="handleMouseleave($event, option)"
                :value="activeProfile[phraseKey(option.label)]"
                @input="handleInput($event, option.label)"
                @focus="handleFocus($event, option.label)"
                @blur="handleBlur($event, option.label)"
                @keypress.enter.prevent="(e) => e.target.blur()"
                height="34"
                class="mb-4"
              />
            </div>
          </section>
        </scrollable>
        <!-- End settings column -->

        <!-- Start preview column -->
        <div class="previewColumn" v-if="showPreview">
          <v-snackbar
            v-model="snackbar"
            top
            absolute
            :timeout="-1"
            content-class="text-center pr-3 white--text"
            :min-width="0"
            color="darkBackground"
            transition="none"
            dark
          >
            Phrase not in the current spec
          </v-snackbar>
          <scrollable auto-hide="move" class-name="column panel rounded" ref="specScroll">
            <text-editor
              id="quill-preview"
              v-model="preview"
              class-name="description"
              ref="previewEditor"
              event_category="specification"
              disabled
            />
          </scrollable>
        </div>
        <!-- End preview column -->
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
import TextEditor from '@/components/generate/TextEditor';
import Scrollable from '@/components/global/Scrollable';
import { HTML_TAGS, phrases as p, TierLimits as tl } from '@/data/constants';
import ProfileListItem from '@/components/UserConfigMenu/ProfileListItem';
import { mapActions, mapMutations, mapState } from 'vuex';
import { v4 as uuid } from 'uuid';
import { capitalize } from 'lodash/string';
import draggable from 'vuedraggable';

const hoverClass = 'input--hover';
const focusClass = 'input--focus';

export default {
  name: 'UserConfigsMenu',
  components: { ProfileListItem, Scrollable, TextEditor, draggable },
  props: {
    showConfigMenu: Boolean,
  },
  data() {
    return {
      addingNewItem: false,
      osInstance: null,
      formSections: [
        {
          title: 'Brief Summary',
          options: [
            {
              label: 'Summary start',
              tooltipText: 'This phrase appears at the start of the summary',
              show: false,
              toggleInput: false,
            },
            {
              label: 'Family start',
              tooltipText: 'This phrase appears before each subsequent independent claim',
              show: false,
              toggleInput: false,
            },
            {
              label: 'Concept start',
              tooltipText: 'This phrase appears before each concept segment',
              show: false,
              toggleInput: false,
            },
            {
              label: 'Context start',
              tooltipText: 'This phrase appears before each context segment',
              show: false,
              toggleInput: false,
            },
            { label: 'Including', tooltipText: 'including', show: false },
            { label: 'May include', tooltipText: 'may include', show: false, toggleInput: false },
            {
              label: 'May also include',
              tooltipText: 'may also include',
              show: false,
              toggleInput: false,
            },
            {
              label: 'Independent claims only',
              tooltipText: 'Generate the brief summary section only from independent claims',
              show: false,
              toggleInput: true,
            },
          ],
        },
        {
          title: 'Figures',
          options: [
            {
              label: 'Truncate overflow text',
              tooltipText: 'Show remaining text as "..." when it exceeds the bounds of a block',
              show: false,
              toggleInput: true,
            },
            {
              label: 'Verbose steps',
              tooltipText: 'Include the entire text for each flowchart step',
              show: false,
              toggleInput: true,
            },
          ],
        },
        {
          title: 'General',
          options: [
            {
              label: 'Highlight tagged text',
              tooltipText: 'Whether to highlight spans of generated text',
              show: false,
              toggleInput: true,
            },
          ],
        },
      ],
      position: {
        x: 0,
        y: 0,
      },
      profiles: [],
      snackbar: false,
    };
  },

  watch: {
    activeProfile: {
      handler(val) {
        for (const key in val) {
          const nodes = this.getNodes(key);
          this.forLoop(nodes, (node) => this.setTextContent(node, val[key], key));
        }
      },
    },
  },

  mounted() {
    const content = window.localStorage.getItem('userProfiles');
    const data = content ? JSON.parse(content) : null;

    if (data?.profiles.length > 0) {
      this.profiles = data.profiles;
      for (let i = 0, l = this.profiles.length; i < l; i++) {
        const profile = this.profiles[i];
        if (profile.name === data.active) {
          this.updateActiveProfile(profile);
          return;
        }
      }
    } else {
      this.profiles = [this.activeProfile];
    }
  },

  computed: {
    ...mapState({ activeProfile: (state) => state.draft.activeProfile }),

    preview: {
      /**
       * @returns {string}
       */
      get() {
        return this.$store.state.draft.specification;
      },
    },

    /**
     * @returns {boolean}
     */
    truncateCheckbox() {
      return this.activeProfile.truncateText;
    },

    /**
     * @returns {boolean}
     */
    showPreview() {
      const trimmed = this.$store.state.draft.specification.replace(HTML_TAGS, '').trim();
      return trimmed.length > 0;
    },
  },

  methods: {
    ...mapMutations({
      alterActiveProfile: 'draft/alterActiveProfile',
    }),

    ...mapActions({
      freeTierCheck: 'draft/freeTierCheck',
      updateActiveProfile: 'draft/updateActiveProfile',
    }),

    include() {
      return [document.querySelector('.included')];
    },

    handleInput(val, phrase) {
      const key = this.phraseKey(phrase);
      const nodes = this.getNodes(key);
      this.forLoop(nodes, (node) => {
        val = val.length > 0 ? val : ' ';
        this.setTextContent(node, val, key);
      });
      this.alterActiveProfile({ key, val });
    },

    getNodes(key) {
      return document.querySelectorAll(`:scope #quill-preview .${key}`);
    },

    toggleTruncate() {
      this.$store.commit('draft/toggleTruncateText');
    },

    handleFocus(e, phrase, scroll = true) {
      const key = this.phraseKey(phrase);
      const nodes = this.getNodes(key);
      if (this.showPreview && scroll) this.scroll(nodes);
      this.highlightPhrases(nodes);
    },

    scroll(nodes = null) {
      const osInstance = this.$refs.specScroll.$refs.osRef.osInstance();
      if (nodes) {
        const el = this.getClosestNode(nodes, osInstance);
        osInstance.scroll(
          { el, scroll: { y: 'ifneeded', x: 'never' }, margin: 50, block: 'end' },
          100
        );
      } else {
        return osInstance.scroll();
      }
    },

    getClosestNode(nodes, scroll = this.$refs.specScroll?.$refs.osRef.osInstance()) {
      const viewTop = scroll.scroll().position.y;
      const viewHeight = scroll.getState('viewportSize.height');
      const viewBottom = viewHeight + viewTop;
      const viewCenter = viewHeight / 2 + viewTop;

      let closestNode = null;
      let shortestDistance = null;

      for (let i = 0, l = nodes.length; i < l; i++) {
        const node = nodes[i];
        const nodeTop = node.offsetTop;
        const nodeBottom = nodeTop + node.offsetHeight;

        const isInView = nodeBottom <= viewBottom && nodeTop >= viewTop;

        if (isInView) {
          closestNode = null;
          return;
        }

        const distance = nodeBottom < viewCenter ? viewCenter - nodeTop : nodeBottom - viewCenter;

        if (shortestDistance === null) {
          shortestDistance = distance;
          closestNode = node;
        }

        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestNode = node;
        }
      }

      return closestNode;
    },

    async handleHover(e, option, showPrompt = true) {
      const key = this.phraseKey(option.label);
      const isDiv = e.target.tagName === 'DIV';

      if (this.showPreview && scroll) {
        const nodes = this.getNodes(key);
        this.highlightPhrases(nodes, true);
        if (nodes.length === 0 && showPrompt) {
          this.snackbar = true;
        } else {
          this.scroll(nodes);
        }
      }

      const rect = e.target.getBoundingClientRect();
      const tooltipWidth = this.calculateTooltipWidth(option.tooltipText);

      this.position.x = isDiv ? rect.x + tooltipWidth : rect.x + tooltipWidth - 12;
      this.position.y = rect.y;
      // this.tooltips[key].show = true;
      option.show = true;
    },

    calculateTooltipWidth(text) {
      const div = document.createElement('div');
      div.classList.add('v-tooltip__content', 'patentpal-tooltip', 'menuable__content__active');
      const span = document.createElement('span');
      span.textContent = text;
      div.appendChild(span);
      const app = document.getElementById('app');
      app.appendChild(div);
      const tooltipWidth = div.getBoundingClientRect().width / 2;
      div.remove();
      span.remove();
      return tooltipWidth;
    },

    handleMouseleave(e, option) {
      const key = this.phraseKey(option.label);
      const nodes = this.getNodes(key);
      option.show = false;
      this.snackbar = false;
      this.removeHighlight({ nodes, hovered: true });
    },

    highlightPhrases(nodes, hovered = false) {
      const className = hovered ? hoverClass : focusClass;
      this.forLoop(nodes, (node) => {
        if (!node.classList.contains(className)) {
          node.classList.add(className);
        }
      });
    },

    removeHighlight({ nodes, key, hovered = false, empty = false }) {
      const className = hovered ? hoverClass : focusClass;
      this.forLoop(nodes, (node) => {
        if (node.classList.contains(className)) {
          node.classList.remove(className);
          if (empty) this.setTextContent(node, p[key], key);
        }
      });
    },

    handleBlur(e, phrase) {
      const key = this.phraseKey(phrase);
      const nodes = this.getNodes(key);
      let empty = false;

      if (this.activeProfile[key].trim().length === 0) {
        this.activeProfile[key] = p[key];
        empty = true;
      }

      this.removeHighlight({ nodes, key, empty });

      if (this.showPreview) {
        this.updateSpec();
      }

      this.alterActiveProfile({ key, val: this.activeProfile[key].trim() });
      this.saveUserProfiles();
    },

    updateSpec() {
      const classNamesReg = new RegExp(`${hoverClass}|${focusClass}`, 'g');
      this.$store.commit(
        'draft/changeDescription',
        this.$refs.previewEditor?.quill.container.firstChild.innerHTML.replace(classNamesReg, '')
      );
    },

    setTextContent(node, val) {
      val = val.trim().length > 0 ? val.trim() : val;
      val = this.ensureSentenceCase(val, node);
      node.textContent = val;
    },

    /**
     * Checks if a string is the start of a sentence or not and capitalizes it if so.
     * @param {string} text
     * @param node
     * @return {string|*}
     */
    ensureSentenceCase(text, node) {
      // Get previous text
      const prevText = node.previousSibling?.textContent;
      if (!prevText) return capitalize(text);

      // Check previous text
      const isEmpty = !prevText.trim();
      const isSentenceEnd = /[.!?]['"]?\s*$/.test(prevText); // E.g. ends in a period

      return isEmpty || isSentenceEnd ? capitalize(text) : text;
    },

    forLoop(iterable, cb, start = 0, end = iterable.length) {
      for (let i = start, l = end; i < l; i++) {
        const currentInstance = iterable[i];
        cb(currentInstance, i, iterable);
      }
    },

    phraseKey(phrase) {
      if (phrase === 'Truncate overflow text') {
        return 'truncateText';
      }
      const key = phrase.toLowerCase().replace(/\s/gi, '_').replace('start', 'intro');
      return key;
    },

    handleClick(profile) {
      this.freeTierCheck({
        cb: () => {
          this.updateActiveProfile(profile);

          if (this.showPreview) {
            this.$nextTick(() => {
              this.updateSpec();
            });
          }
        },
        message: tl.CHANGE_PROFILE_TEXT,
      });
    },

    add() {
      this.freeTierCheck({
        cb: () => {
          const newProfile = this.createProfile('New');
          this.profiles.push(newProfile);

          this.updateActiveProfile(this.profiles[this.profiles.length - 1]);
          if (this.showPreview) {
            this.updateSpec();
          }
          this.saveUserProfiles();
        },
        message: tl.ADD_PROFILE_TEXT,
      });
    },

    deleteItem(index) {
      this.profiles.splice(index, 1);
      const prevProfile = this.profiles[index - 1];
      const nextProfile = this.profiles[index];

      if (nextProfile) {
        this.updateActiveProfile(nextProfile);
      } else if (prevProfile) {
        this.updateActiveProfile(prevProfile);
      } else {
        const defaultProfile = this.createProfile();
        this.profiles.push(defaultProfile);

        this.updateActiveProfile(this.profiles[0]);
        this.saveUserProfiles();
      }
    },

    createProfile(name = 'Default') {
      const useDefaults = name === 'Default';
      return {
        name,
        id: uuid(),
        editing: name === 'New',
        truncateText: useDefaults ? true : !!this.activeProfile.truncateText,
        summary_intro: useDefaults ? p.summary_intro : this.activeProfile.summary_intro,
        family_intro: useDefaults ? p.family_intro : this.activeProfile.family_intro,
        concept_intro: useDefaults ? p.concept_intro : this.activeProfile.concept_intro,
        context_intro: useDefaults ? p.context_intro : this.activeProfile.context_intro,
        including: useDefaults ? p.including : this.activeProfile.including,
        may_include: useDefaults ? p.may_include : this.activeProfile.may_include,
        may_also_include: useDefaults ? p.may_also_include : this.activeProfile.may_also_include,
        independent_claims_only: useDefaults
          ? p.independent_claims_only_default
          : this.activeProfile.independent_claims_only,
      };
    },

    saveUserProfiles() {
      const local = window.localStorage;
      const profiles = this.profiles;
      const active = this.activeProfile.name;

      const data = JSON.stringify({ profiles, active });

      local.setItem('userProfiles', data);
    },

    close() {
      this.saveUserProfiles();
      this.$emit('closeMenu', this.activeProfile);
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px !important;
  margin-bottom: 25px;
}
.configMenu {
  padding-bottom: 30px;
  background: #f0f0f0 !important;

  .v-list-item {
    padding-left: 30px !important;
  }

  .v-list-item__action {
    margin: 0 !important;
  }
}

.customList {
  background-color: inherit !important;
}

.column {
  height: 100%;
}

.custom {
  height: calc(100% - 30px);
  padding-right: 30px;
  display: flex;
}

.customPadding2 {
  padding: 0 5px !important;
}

.previewColumn {
  width: 500px !important;
  max-width: 100%;
  margin-left: 40px;
  position: relative;
}

.settings {
  width: 400px;
  max-width: 100%;
  position: relative;
}

.profiles {
  width: 150px !important;
  max-width: 100% !important;
  min-width: 100px !important;
  margin-right: 40px;
}
</style>
