/**
 * Default options for the customization overlay.
 */

// Summary
export const summary_intro = `embodiments of the present disclosure may include`;
export const family_intro = `embodiments of the present disclosure may also include`;
export const concept_intro = `embodiments may also include`;
export const context_intro = `in some embodiments`;
export const including = 'including';
export const may_include = `may include`;
export const may_also_include = `may also include`;
export const independent_claims_only_default = false;

// Figures
export const truncate_text_default = false;

// General
