<!-- 
  Subscribe prompt. Uses our PromptCard component internally but we created a separate
  component because it needs slightly more functionality than the basic PromptCard
  provides
-->

<template>
  <v-overlay :value="subscribePrompt" class="included">
    <PromptCard
      :text="message"
      okay-text="Subscribe"
      cancel-text="Not now"
      @okay="handleOkay"
      @cancel="closePrompt"
    />
  </v-overlay>
</template>

<script>
import { PromptCard } from '@/components/global';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'SubscribePrompt',
  components: {
    PromptCard,
  },

  computed: {
    ...mapState('draft', {
      subscribePrompt: (state) => state.subscribePrompt,
      message: (state) => state.subscribePromptMessage,
    }),
  },

  methods: {
    ...mapMutations('draft', ['hideSubscribePrompt']),
    ...mapActions('draft', ['navigateToSubscribePage']),

    handleOkay() {
      this.navigateToSubscribePage();
      this.hideSubscribePrompt();
    },

    closePrompt() {
      this.hideSubscribePrompt();
    },
  },
};
</script>

<style lang="scss" scoped></style>
