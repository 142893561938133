import config from '@/data/var.config.js';
import { TierLimits as tl } from '@/data/constants';
import { unpackDiagrams } from '@/support/utilities/unpackers';
import { formatErrorMessage } from '@/support/utilities';
import { postAlice } from '@/support/alice';
import { packClaims } from './packers';
import {
  ACTION_GENERATE_FAIL,
  ACTION_USED_CUSTOM,
  ACTION_USED_DEFAULT,
  CATEGORY_GENERATE,
  CATEGORY_PHRASE_CUSTOMIZATION,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import * as customizations from '@/data/constants/customizations';

/**
 * Check if a user should have access to generating additional drafts.
 * @param appData
 * @param userData
 * @param currentMonth
 * @returns {boolean}
 */
export function checkDraftLimit(appData, userData, currentMonth) {
  return (
    !appData.subscribed &&
    userData.draftsThisMonth >= config.DRAFT_LIMIT &&
    userData.thisMonth === currentMonth
  );
}

/**
 * Pack the request data into expected format for alice
 */
export function packRequestData(state, claims) {
  return {
    document: packClaims(claims),
    options: {
      ...state.activeProfile,
    },
  };
}

/**
 * Checks if the app is loading or not
 * @param {} param0
 * @returns
 */
export function notLoading({ rootState, state }) {
  return !rootState.upload.uploading && !state.loading && !state.insertingDocData;
}

/**
 * We show the subscription prompt if the user has reached the free tier limit
 * @param {*} param0
 */
export function showSubscribePrompt({ dispatch }) {
  dispatch('toggleSubscribePrompt', { show: true, message: tl.DRAFT_LIMIT_TEXT });
}

/**
 * Shows the loading overlay and sends claims and customizations to alice
 * @param {*} param0
 * @param {*} claims
 * @returns
 */
export function sendRequestToAlice({ state, commit }, claims) {
  commit('showLoadingPhrases');
  commit('setLoading', true);

  const data = packRequestData(state, claims);

  return postAlice('generate_draft', data);
}

/**
 * Unpacks the response from Alice and starts the sequential load
 * @param {*} res
 * @param {*} claims
 * @param {*} cx
 * @returns
 */
export function unpackResponse(res, claims, cx) {
  const { figures: f, specification: description } = res.data;
  const diagrams = unpackDiagrams(f);

  cx.commit('setOutlineData', res.data);
  cx.commit('setSequentialSkipFalse');
  cx.commit('startInserting');
  cx.commit('setStep', 2);
  cx.commit('setDiagrams', []);
  return Promise.all([
    cx.dispatch('loadClaims', claims),
    cx.dispatch('loadDiagrams', diagrams),
    cx.dispatch('loadDescription', description),
  ]).then(() => cx.commit('setSequentialSkipFalse'));
}

/**
 * Updates Auth0 profile data with the number of drafts the user has generated
 * @param {*} auth
 * @param {*} appData
 * @param {*} userData
 * @param {*} month
 * @returns
 */
export function updateAuth0Data(auth, appData, userData, month) {
  if (isNaN(userData.totalDrafts)) {
    userData.totalDrafts = 1;
  } else {
    userData.totalDrafts++;
  }

  if (isNaN(userData.draftsThisMonth)) {
    userData.draftsThisMonth = 1;
    userData.thisMonth = month;
  } else if (month === userData.thisMonth) {
    userData.draftsThisMonth++;
  } else {
    userData.lastMonth = userData.thisMonth;
    userData.draftsLastMonth = userData.draftsThisMonth;
    userData.thisMonth = month;
    userData.draftsThisMonth = 1;
  }

  return auth.patchUserMetadata({
    month: null,
    generatedDrafts: null,
    draftsThisMonth: userData.draftsThisMonth,
    thisMonth: userData.thisMonth,
    totalDrafts: userData.totalDrafts,
    lastMonth: userData.lastMonth,
    draftsLastMonth: userData.draftsLastMonth,
  });
}

/**
 * Logs events to google analytics
 * @param {*} app
 * @param {*} cx
 */
export function logEvents(app, cx) {
  const activePhrases = JSON.stringify(cx.state.activeProfile);
  const defaultPhrases = getDefaultPhrases();

  if (activePhrases === defaultPhrases) {
    app.$gtag.event(ACTION_USED_DEFAULT, {
      event_category: CATEGORY_PHRASE_CUSTOMIZATION,
    });
  } else {
    app.$gtag.customMap({ dimension2: 'phrases' });
    app.$gtag.event(ACTION_USED_CUSTOM, {
      event_category: CATEGORY_PHRASE_CUSTOMIZATION,
      phrases: activePhrases,
    });
  }
  if (!app.$auth.user?.metaData.seenTutorialOutputs) {
    app.$tours.homeTour.start();
    app.$auth.patchUserMetadata({ draftTutorialOutputs: null, seenTutorialOutputs: true });
  }
}

/**
 * returns the default phrases in JSON string to compare to the users current
 * phrases customizations. We do this to determine if the user is using the default
 * phrases or if they are using their own customizations for analytic purposes.
 * @returns
 */
function getDefaultPhrases() {
  const obj = {
    summary_intro: customizations.summary_intro,
    family_intro: customizations.family_intro,
    concept_intro: customizations.concept_intro,
    context_intro: customizations.context_intro,
    including: customizations.including,
    may_include: customizations.may_include,
    may_also_include: customizations.may_also_include,
  };

  return JSON.stringify(obj);
}

/**
 * Saves the claims and outlines, we do this to track changes to the users current document
 * @param {*} commit
 */
export function saveNewDocumentData(commit) {
  commit('saveClaims');
  commit('saveOutline');
}

export function handleServerRequestError(err, gtag, commit) {
  console.error(err);
  const res = err.response;

  gtag.event(ACTION_GENERATE_FAIL, {
    event_category: CATEGORY_GENERATE,
    event_label: formatErrorMessage(res?.data?.message, res?.data?.id),
  });

  commit('stopInserting');
  commit('stopLoading');
  commit('error/handleError', err, { root: true });
}

export function unpackSubscriptionData(app) {
  const month = new Date().toLocaleString('default', { month: 'long' });
  const appData = app.$auth.user.appData;
  const userData = app.$auth.user.metaData;

  return { month, appData, userData };
}
