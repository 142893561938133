/*
  This is our interface for all interactions with API, Try to keep these functions
  simple and easy to read. If a function is getting complex please abstract it out
  into ./ApiHelpers.js
*/

import AWS from 'aws-sdk';
import axios from 'axios';
import config from '@/data/var.config';
import { requestDocClaims } from './ApiHelpers';
import { v4 as uuid } from 'uuid';

AWS.config.update({
  region: config.S3_BUCKET_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.AWS_ID_POOL,
  }),
});

/**
 * Sends a post request to Alice to generate outputs from claims
 * @param {*} type
 * @param {*} param1
 * @returns
 */
export async function postAlice(type, { outlines, document, options }) {
  const data = {
    type,
    data: {
      ...(outlines && { outlines }),
      ...(document && { document }),
    },
    ...(options && { options }),
  };

  console.log('DATA SENT TO ALICE:', data);

  const res = await axios.post(config.ALICE_URL, data);

  console.log('DATA RECEIVED FROM ALICE', res.data);

  return res;
}

/**
 * Uploads doc file provided by user to s3 bucket
 * @param {File} file File provided by user
 * @param {Function} callBack
 */
export async function postDocToS3(file, callBack) {
  if (file) {
    const { name } = file;
    const folderKey = `${encodeURIComponent('documents')}/`;

    const generatedNumber = new Uint32Array(1);
    window.crypto.getRandomValues(generatedNumber);

    const fileKey = `${folderKey}${generatedNumber[0]}_${name}`;

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: config.S3_BUCKET_NAME,
        Key: fileKey,
        Body: file,
        ACL: 'private',
      },
    });

    const res = await upload.on('httpUploadProgress', callBack).promise();

    return requestDocClaims(res.key);
  }
  return null;
}

/**
 * Uploads user profile pictures to AWS S3
 * @param {*} file
 * @param {*} callBack
 * @returns
 */
export async function postImageToS3(file, callBack) {
  if (file) {
    const { name } = file;
    const generatedNumber = uuid();

    const fileKey = `${generatedNumber}_${name.toLowerCase().replace(/\s/gi, '-')}`;

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: 'patentpal-user-images',
        Key: fileKey,
        Body: file,
        ACL: 'private',
      },
    });

    return await upload.on('httpUploadProgress', callBack).promise();
  }
}

/**
 * Updates the users Auth0 Meta data
 * @param {String} id - User Auth0 ID
 * @param {String} firstName  - New user name
 * @param {String} lastName
 * @param {String} picture - New user picture URL
 * @param {String} [email] - new user email. Optional
 */
export async function updateUser(
  id,
  firstName,
  lastName,
  nickname,
  company,
  phone,
  picture,
  stripe_id,
  email = null
) {
  const userData = {
    id,
    stripe_id,
    data: {
      picture,
      nickname,
      given_name: firstName,
      family_name: lastName,
      name: `${firstName} ${lastName}`,
      user_metadata: {
        phone,
        company,
      },
      ...(email && { email }),
    },
  };
  return await axios.patch(`${config.USER_MANAGEMENT_URL}/users`, userData);
}

export async function postToAuth0(endpoint, data) {
  return await axios.post(`https://${config.AUTH_DOMAIN}/${endpoint}`, data);
}

export async function createSubscription(data) {
  return axios.post(`${config.USER_MANAGEMENT_URL}/subscribe`, data);
}

export async function getUserData(user_id) {
  return axios.get(`${config.USER_MANAGEMENT_URL}/users/${user_id}`);
}

export async function deleteUser(user_id, stripe_id) {
  return axios.delete(`${config.USER_MANAGEMENT_URL}/users`, {
    data: {
      user_id,
      stripe_id,
    },
  });
}
