<!--
  Menu dropdown for User Account
  [Profile, Log Out/In, Signup]
-->
<template>
  <v-menu
    dark
    offset-y
    bottom
    transition="slide-y-transition"
    content-class="remove-right-offset patentpal-menu"
    rounded="t-0"
  >
    <template #activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs" class="userInfoContainer">
        <v-toolbar-title>{{ username }}</v-toolbar-title>

        <v-avatar :color="picture ? 'transparent' : 'primary'" size="40">
          <v-img v-if="picture" :src="picture" alt="User image" />
          <span v-else>{{ initials }}</span>
        </v-avatar>
      </div>
    </template>

    <v-list>
      <v-list-item @click="showProfile(true)">
        <v-list-item-icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ MENU_PROFILE }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="navigateToSubscribePage" v-if="!subscribed">
        <v-list-item-icon><v-icon>mdi-sync</v-icon></v-list-item-icon>
        <v-list-item-title>Subscribe</v-list-item-title>
      </v-list-item>

      <v-list-item @click="manageSubscription">
        <v-list-item-icon><v-icon>mdi-receipt</v-icon></v-list-item-icon>
        <v-list-item-title>{{ MENU_BILLING }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="showLogoutPrompt">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ GEN_LOG_OUT }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapActions } from 'vuex';
import config from '@/data/var.config';
import {
  MENU_BILLING,
  GEN_SUBSCRIBE,
  GEN_LOG_OUT,
  MENU_PROFILE,
} from '@/data/constants/constantsMessages';

export default {
  name: 'UserMenu',
  data() {
    return {
      MENU_BILLING,
      MENU_PROFILE,
      GEN_SUBSCRIBE,
      GEN_LOG_OUT,
      picture: null,
    };
  },

  methods: {
    ...mapMutations({
      showProfile: 'draft/toggleProfileOverlay',
      setLoading: 'draft/setLoading',
      saveUserContent: 'draft/saveUserContent',
      setErrorState: 'error/setErrorState',
      showLogoutPrompt: 'draft/showLogoutPrompt',
      hideLoadingPhrases: 'draft/hideLoadingPhrases',
    }),
    ...mapActions('draft', ['navigateToSubscribePage', 'saveUserContent']),

    login() {
      this.$auth.universalLogin();
    },

    async manageSubscription() {
      const { sub } = this.$auth.user;
      try {
        this.hideLoadingPhrases();
        this.setLoading(true);
        const res = await axios.post(`${config.USER_MANAGEMENT_URL}/customer-portal`, {
          user_id: sub,
        });
        this.saveUserContent();
        window.location.href = res.data.session_url;
      } catch (err) {
        this.setLoading(false);
        this.setErrorState({ key: 'error', val: true });
        console.error(err);
      }
    },
  },

  computed: {
    /**
     * checks if username is defined
     * @returns {String} either user's name or 'Guest'
     */
    username() {
      if (this.$auth.user && this.$auth.user) {
        return this.$auth.user.nickname;
      }
      return '';
    },

    subscribed() {
      if (this.$auth.user) {
        return this.$auth.user.appData.subscribed;
      }
      return false;
    },

    initials() {
      if (this.$auth.user) {
        // const firstInitial = this.$auth.user.given_name[0]?.toUpperCase();
        // const lastInitial = this.$auth.user.family_name[0]?.toUpperCase();
        const names = this.$auth.user.name.split(' ');
        const firstInitial = names[0][0].toUpperCase();
        const lastInitial = names[1] ? names[1][0].toUpperCase() : '';

        return `${firstInitial}${lastInitial}`;
      }
      return '';
    },
  },

  watch: {
    '$auth.user': {
      immediate: true,
      handler(user) {
        if (!user?.picture.includes('gravatar')) {
          this.picture = user?.picture;
        } else {
          this.picture = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.userInfoContainer {
  display: flex;
  align-items: center;
  height: 100% !important;
}

.v-toolbar__title {
  margin: 10px;
}
</style>
