/*
  These functions handle VSDX export

  Inspired by Jgraph's vsdx export on Draw.io
  https://github.com/jgraph/drawio/blob/master/src/main/webapp/js/diagramly/vsdx/VsdxExport.js

  License: https://github.com/jgraph/drawio/blob/master/LICENSE
*/

import JSZip from 'jszip';
import getVsdxSkeleton from './vsdxSkeleton';
import mxgraph from './mxGraphIndex.js';
import { BLOCK_PILL_STYLE, UNIT_SIZE, VISIO_UNIT_SIZE } from '@/data/constants/figureConstants';
import { BLOCK_PARENT_STYLE, FIG_STYLE } from '@/data/constants/figureConstants';
import { fileDate, titleCase } from '../utilities';
import {
  getVisioFigFontSize,
  getVisioFontSize,
  calculateCalloutLinePoints,
} from '@/support/diagram/DiagramHelpers';

/* Global Constants */
const XMLNS = 'http://schemas.microsoft.com/office/visio/2012/main';
const XMLNS_R = 'http://schemas.openxmlformats.org/officeDocument/2006/relationships';
const RELS_XMLNS = 'http://schemas.openxmlformats.org/package/2006/relationships';
const PAGES_TYPE = 'http://schemas.microsoft.com/visio/2010/relationships/page';
const IMAGE_TYPE = 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/image';
const VISIO_PAGES = 'visio/pages/';
const CONTENT_TYPES_XML = '[Content_Types].xml';
const PART_NAME = 'PartName';
const idsMap = {};
let idsCounter = 1;
const pageHeight = 11;

const { mxUtils } = mxgraph;
/* End Global Constants */

/**
 * Main controller function. This is what you will call to generate and and download
 * a visio file.
 * @param {*} editors
 * @param {*} title
 */
export default function exportVsdx(editors, title) {
  const zip = new JSZip();
  const pages = {};
  const pageLayers = {};
  const media = {};

  try {
    editors.forEach((editor, index) => {
      const { graph, illustration } = editor;
      const diagramName = `Page ${index + 1}`;

      pages[diagramName] = _convertMxModel2Page(graph, illustration);

      pageLayers[diagramName] = _collectLayers(graph);

      if (illustration) {
        const type = illustration.match(/(?:image\/)([a-z]+)(?:;)/)[1];
        const mediaName = `image${index + 1}.${type}`;
        media[mediaName] = illustration.replace(/data:image\/.+;base64,/, '');
      }
    });

    _createVsdxSkeleton(zip, editors.length);
    _addMedia(zip, media);
    _addXmlPages(zip, pages, pageLayers);

    _saveFile(zip, title);
  } catch (err) {
    console.error(err);
  }
}

/*
  --- Internal functions below this line ---
*/

/**
 * Adds images to the media folder in the visio file
 * @param {} zip
 * @param {*} media
 */
function _addMedia(zip, media) {
  for (const name in media) {
    _writeImageToZip(zip, `visio/media/${name}`, media[name]);
  }
}

/**
 * Layers are similar to any visual editor. We do not currently support layers in
 * our figure editor but if you wanted to support them you could. This may need review
 * since we don't currently support layers it's possible this function may not
 * work as expected
 * @param {*} graph
 * @returns
 */
function _collectLayers(graph) {
  const layers = graph.model.getChildCells(graph.model.root);

  return layers.map((layer) => {
    if (layer.visible) {
      return {
        name: layer.value || 'Background',
        visible: layer.visible,
        locked: layer.style && layer.style.indexOf('locked=1') >= 0,
      };
    }
  });
}

/**
 * Loops through the pages in the figure editor and converts them to xml before
 * adding them to the pages folder in the visio file.
 * @param {*} zip
 * @param {*} pages
 * @param {*} pageLayers
 */
function _addXmlPages(zip, pages, pageLayers) {
  const pagesXmlDoc = mxUtils.createXmlDocument();
  const pagesRelsXmlDoc = mxUtils.createXmlDocument();

  const pagesRoot = _createElement(pagesXmlDoc, XMLNS, 'Pages');
  const pagesRelsRoot = _createElement(pagesRelsXmlDoc, RELS_XMLNS, 'Relationships');

  pagesRoot.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', XMLNS);
  pagesRoot.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:r', XMLNS_R);

  let i = 1;

  for (const name in pages) {
    const pageName = `page${i}.xml`;
    const xmlDoc = pages[name];
    const foreignData = xmlDoc.getElementsByTagName('ForeignData')[0];
    if (foreignData) {
      const imageType = foreignData.getAttribute('CompressionType').toLowerCase();
      const pageRelName = `page${i}.xml.rels`;
      const pageRelDoc = mxUtils.createXmlDocument();
      const pageRelRoot = _createElement(pageRelDoc, RELS_XMLNS, 'Relationships');

      const relationship = _createElement(pageRelDoc, RELS_XMLNS, 'Relationship');

      relationship.setAttribute('Id', `rId1`);
      relationship.setAttribute('Type', IMAGE_TYPE);
      relationship.setAttribute('Target', `../media/image${i}.${imageType}`);
      pageRelRoot.appendChild(relationship);
      pageRelDoc.appendChild(pageRelRoot);
      _writeXmlDoc2Zip(zip, `${VISIO_PAGES}_rels/${pageRelName}`, pageRelDoc);
    }

    const pageElement = _createElement(pagesXmlDoc, XMLNS, 'Page');
    pageElement.setAttribute('ID', i - 1);
    pageElement.setAttribute('NameU', name);
    pageElement.setAttribute('Name', name);
    pageElement.setAttribute('ViewScale', 0.6875);
    pageElement.setAttribute('ViewCenterY', 5.5454545454545);
    pageElement.setAttribute('ViewCenterX', 4.4393939393939);

    const pageSheet = _createElement(pagesXmlDoc, XMLNS, 'PageSheet');
    const visioPageHeight = 11;
    const visioPageWidth = 8.5;

    pageSheet.appendChild(_createCellElem('PageWidth', visioPageWidth, pagesXmlDoc));

    pageSheet.appendChild(_createCellElem('PageHeight', visioPageHeight, pagesXmlDoc));
    pageSheet.appendChild(
      (() => {
        const scale = _createCellElem('PageScale', 1, pagesXmlDoc);
        scale.setAttribute('U', 'IN_F');
        return scale;
      })()
    );
    pageSheet.appendChild(
      (() => {
        const scale = _createCellElem('DrawingScale', 1, pagesXmlDoc);
        scale.setAttribute('U', 'IN_F');
        return scale;
      })()
    );

    pageSheet.appendChild(_createCellElem('DrawingSizeType', 0, pagesXmlDoc));
    pageSheet.appendChild(_createCellElem('DrawingScaleType', 0, pagesXmlDoc));

    const relElement = _createElement(pagesXmlDoc, XMLNS, 'Rel');
    relElement.setAttributeNS(XMLNS_R, 'r:id', `rId${i}`);

    const layersSection = _createElement(pagesXmlDoc, XMLNS, 'Section');
    layersSection.setAttribute('N', 'Layer');

    const layers = pageLayers[name];

    for (let j = 0, layersLength = layers.length; j < layersLength; j++) {
      const layerRow = _createElement(pagesXmlDoc, XMLNS, 'Row');
      layerRow.setAttribute('IX', `${j}`);

      layersSection.appendChild(layerRow);

      layerRow.appendChild(_createCellElem('Name', layers[j].name, pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Color', '255', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Status', '0', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Visible', layers[j].visible ? '1' : '0', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Print', '1', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Active', '0', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Lock', layers[j].locked ? '1' : '0', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Snap', '1', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('Glue', '1', pagesXmlDoc));
      layerRow.appendChild(_createCellElem('NameUniv', layers[j].name, pagesXmlDoc));
      layerRow.appendChild(_createCellElem('ColorTrans', '0', pagesXmlDoc));
    }

    pageSheet.appendChild(layersSection);

    pageElement.appendChild(pageSheet);
    pageElement.appendChild(relElement);
    pagesRoot.appendChild(pageElement);

    const relationship = _createElement(pagesRelsXmlDoc, RELS_XMLNS, 'Relationship');

    relationship.setAttribute('Id', `rId${i}`);
    relationship.setAttribute('Type', PAGES_TYPE);
    relationship.setAttribute('Target', pageName);
    pagesRelsRoot.appendChild(relationship);

    _writeXmlDoc2Zip(zip, `${VISIO_PAGES}${pageName}`, xmlDoc);
    i++;
  }

  pagesXmlDoc.appendChild(pagesRoot);
  pagesRelsXmlDoc.appendChild(pagesRelsRoot);
  _writeXmlDoc2Zip(zip, `${VISIO_PAGES}pages.xml`, pagesXmlDoc);
  _writeXmlDoc2Zip(zip, `${VISIO_PAGES}_rels/pages.xml.rels`, pagesRelsXmlDoc);
}

/**
 * This adds the XML document to the zip file. All pages are just XML documents
 * @param {} zip
 * @param {*} name
 * @param {*} xmlDoc
 * @param {*} noHeader
 */
function _writeXmlDoc2Zip(zip, name, xmlDoc, noHeader) {
  zip.file(
    name,
    (noHeader ? '' : '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>') +
      mxUtils.getXml(xmlDoc, '\n')
  );
}

/**
 * Adds images to the zip file
 * @param {*} zip
 * @param {*} name
 * @param {*} image
 */
function _writeImageToZip(zip, name, image) {
  zip.file(name, image, { base64: true });
}

/**
 * Creates the empty visio file that we can then insert pages into
 * @param {*} zip
 * @param {*} pageCount
 */
function _createVsdxSkeleton(zip, pageCount) {
  const files = getVsdxSkeleton();
  for (const id in files) {
    if (pageCount > 1 && id === CONTENT_TYPES_XML) {
      const doc = mxUtils.parseXml(files[id]);
      const root = doc.documentElement;

      const { children } = root;
      let page1 = null;

      children.forEach((child) => {
        if (child.getAttribute(PART_NAME) === '/visio/pages/page1.xml') {
          page1 = child;
        }
      });

      for (let i = 2; i <= pageCount; i++) {
        const newPage = page1.cloneNode();
        newPage.setAttribute(PART_NAME, `/visio/pages/page${i}.xml`);
        root.appendChild(newPage);
      }

      _writeXmlDoc2Zip(zip, id, doc, true);
    } else {
      zip.file(id, files[id]);
    }
  }
}

/**
 * Saves the visio file to the users computer
 * @param {*} zip
 * @param {*} title
 */
function _saveFile(zip, title) {
  zip.generateAsync({ type: 'base64' }).then((content) => {
    const oldLink = document.getElementById('visioDownloadLink');
    if (oldLink) oldLink.remove();
    const link = document.createElement('a');
    link.id = 'visioDownloadLink';
    link.href = `data:application/vnd.visio2013;base64,${content}`;
    link.download = `${fileDate()} - ${titleCase(title)} - Figs.vsdx`;
    link.click();
    link.remove();
  });
}

/**
 * Creates an XML element
 */
function _createElement(doc, nameSpace, name) {
  return doc.createElementNS != null
    ? doc.createElementNS(nameSpace, name)
    : doc.createElement(name);
}

/**
 * This creates an ID for a cell and adds it to the idsMap to ensure we have no duplicates.
 *
 * In visio ID's always start at 1 and increment for each ID.
 * @param {*} cellId
 * @returns
 */
function _getCellVsdxId(cellId) {
  let vsdxId = idsMap[cellId];

  if (vsdxId == null) {
    vsdxId = idsCounter++;
    idsMap[cellId] = vsdxId;
  }
  return vsdxId;
}

/**
 * Creates a Cell XML element. Cell XML elements are different from Cells in mxGraph.
 *
 * See: https://docs.microsoft.com/en-us/office/client-developer/visio/cell-elementvisio-xml
 * @param {*} name
 * @param {*} val
 * @param {*} xmlDoc
 * @param {*} formula
 * @returns
 */
function _createCellElem(name, val, xmlDoc, formula) {
  const cell = _createElement(xmlDoc, XMLNS, 'Cell');
  cell.setAttribute('N', name);
  cell.setAttribute('V', val);

  if (formula) cell.setAttribute('F', formula);

  return cell;
}

/**
 * Shapes are blocks, callouts, and images
 * @param {*} xmlDoc
 * @param {*} id
 * @param {*} cell
 * @param {*} illustration
 * @returns
 */
function _createNewShape(xmlDoc, id, cell, illustration = null) {
  const height = (cell.geometry.height / UNIT_SIZE) * VISIO_UNIT_SIZE; // convert our measurements into visio measurements
  const width =
    cell.style === FIG_STYLE
      ? ((cell.geometry.width + UNIT_SIZE) / UNIT_SIZE) * VISIO_UNIT_SIZE
      : (cell.geometry.width / UNIT_SIZE) * VISIO_UNIT_SIZE; // convert our measurements into visio measurements

  /*
    x and y in visio represent the center of the shape not the top left corner.
    This means we need to find the center point of the shape by multiplying the
    width and height by 0.5 then adding that number to x and y


    It should be noted that positive y values move the shape up rather than
    down. To counteract this we subtract the new y message from the height of the
    page.
  */
  const x = (cell.geometry.x / UNIT_SIZE + 1) * VISIO_UNIT_SIZE + width * 0.5;
  const y = pageHeight - ((cell.geometry.y / UNIT_SIZE) * VISIO_UNIT_SIZE + height * 0.5);

  const shape = _createElement(xmlDoc, XMLNS, 'Shape');
  // setting attributes
  shape.setAttribute('ID', id);
  illustration ? shape.setAttribute('Type', 'Foreign') : shape.setAttribute('Type', 'Shape');
  shape.setAttribute('TextStyle', 3);
  shape.setAttribute('FillStyle', cell.style.includes('shape=callout') ? 2 : 3);
  shape.setAttribute('LineStyle', 3);

  // add cells
  shape.appendChild(_createCellElem('PinX', x, xmlDoc)); // set x coordinate relative to parent. Based on Pin which is center of shape
  shape.appendChild(_createCellElem('PinY', y, xmlDoc)); // set y coordinate relative to parent. Based on Pin which is center of shape

  /* sets the width and height of shape */
  shape.appendChild(_createCellElem('Width', width, xmlDoc));
  shape.appendChild(_createCellElem('Height', height, xmlDoc));

  /*
    Setting point of rotation which should be center of shape.
    Formula for LocPinX by default is Width*0.5.
    Formula for LocPinY by default is Height*0.5
  */
  shape.appendChild(
    (() => {
      const cell = _createCellElem('LocPinX', width * 0.5, xmlDoc);
      cell.setAttribute('F', 'Width*0.5');
      return cell;
    })()
  );
  shape.appendChild(
    (() => {
      const cell = _createCellElem('LocPinY', height * 0.5, xmlDoc);
      cell.setAttribute('F', 'Height*0.5');
      return cell;
    })()
  );

  /*
    Set flips and rotations. Since we don't currently support flipping and
    rotation in editor all values default to 0
  */
  shape.appendChild(_createCellElem('Angle', 0, xmlDoc)); // since false, it is not rotated
  shape.appendChild(_createCellElem('FlipX', 0, xmlDoc)); // since false, it is not flipped horizontally
  shape.appendChild(_createCellElem('FlipY', 0, xmlDoc)); // since false, it is not filled vertically

  /*
    Next line sets behavior of ResizeMode.

    0 = Use Group Settings.
    1 = Reposition Only.
    2 = Scale with Group

    0 is the default behavior in Visio
  */
  shape.appendChild(_createCellElem('ResizeMode', 0, xmlDoc));

  if (cell.style === BLOCK_PILL_STYLE) {
    shape.appendChild(_createCellElem('Rounding', 0.5, xmlDoc));
  }

  if (illustration) {
    shape.appendChild(_createCellElem('ImgOffSetX', 0, xmlDoc, 'ImgWidth*0'));
    shape.appendChild(_createCellElem('ImgOffSetY', 0, xmlDoc, 'ImgHeight*0'));
    shape.appendChild(_createCellElem('ImgWidth', width, xmlDoc, 'Width*1'));
    shape.appendChild(_createCellElem('ImgHeight', height, xmlDoc, 'Height*1'));
    shape.appendChild(_createCellElem('ClippingPath', '', xmlDoc, ''));
  }

  if (
    cell.style !== 'align=left;strokeColor=none;fill=none' &&
    cell.style !== FIG_STYLE &&
    !cell.style.includes('shape=image')
  ) {
    const geo = _createGeoSection(xmlDoc, cell, width);
    shape.appendChild(geo);
  }

  if (illustration) {
    const type = illustration.match(/(?:image\/)([a-z]+)(?:;)/)[1];
    const foreignData = _createElement(xmlDoc, XMLNS, 'ForeignData');
    foreignData.setAttribute('ForeignType', 'Bitmap');
    foreignData.setAttribute('CompressionType', type.toUpperCase());
    const rel = _createElement(xmlDoc, XMLNS, 'Rel');
    rel.setAttribute('r:id', 'rId1');
    foreignData.appendChild(rel);
    shape.appendChild(foreignData);
  }

  if (cell.value) {
    const vertAlign = cell.style.includes('shape=callout') ? 1 : 0;
    const { text, paragraph, character } = _createText(xmlDoc, cell);
    if (character) shape.appendChild(character);
    if (paragraph) {
      shape.appendChild(_createCellElem('VerticalAlign', vertAlign, xmlDoc));
      shape.appendChild(paragraph);
    }
    shape.appendChild(text);
  }

  return shape;
}

/**
 * Creates the XML for the text of the shape. The text section handles formatting
 * and rendering of text in a shape
 * @param {*} xmlDoc
 * @param {*} cell
 * @returns
 */
function _createText(xmlDoc, cell) {
  const figFontSize = getVisioFigFontSize();
  const text = _createElement(xmlDoc, XMLNS, 'Text');
  let paragraph;
  let character;

  if (!cell.value.includes('FIG')) {
    const textParts = cell.value?.split('<u>');

    if (cell.style === BLOCK_PARENT_STYLE || cell.style === FIG_STYLE) {
      paragraph = _createElement(xmlDoc, XMLNS, 'Section');
      paragraph.setAttribute('N', 'Paragraph');

      // create row
      const row = _createElement(xmlDoc, XMLNS, 'Row');
      row.setAttribute('IX', 0);
      row.appendChild(_createCellElem('HorzAlign', 0, xmlDoc)); // sets horizontal text alignment to the left. 0 = left, 1 = center, 2 = right
      // row.appendChild(_createCellElem('VerticalAlign', 0, xmlDoc));
      paragraph.appendChild(row);
    }

    if (cell.style.includes('shape=callout')) {
      paragraph = _createElement(xmlDoc, XMLNS, 'Section');
      paragraph.setAttribute('N', 'Paragraph');

      // create row
      const row = _createElement(xmlDoc, XMLNS, 'Row');
      row.setAttribute('IX', 0);
      // sets horizontal text alignment to the left. 0 = left, 1 = center, 2 = right
      row.appendChild(
        _createCellElem(
          'HorzAlign',
          cell.block.direction === 0 || cell.block.direction === 2 || cell.block.direction === 4
            ? 2
            : 0,
          xmlDoc
        )
      );
      row.appendChild(_createCellElem('VerticalAlign', 1, xmlDoc));
      paragraph.appendChild(row);
    }

    // create text element
    if (textParts.length > 1) {
      character = _createCharSection(xmlDoc);
      const cp = _createElement(xmlDoc, XMLNS, 'cp');
      cp.setAttribute('IX', 0);
      const pp = _createElement(xmlDoc, XMLNS, 'pp');
      pp.setAttribute('IX', 0);
      const tp = _createElement(xmlDoc, XMLNS, 'tp');
      tp.setAttribute('IX', 0);
      const firstText = xmlDoc.createTextNode(
        textParts[0].replace(/(<([^>]+)>)/gi, '').replace(/(<\/u>?)/gi, '')
      );
      text.appendChild(cp);
      text.appendChild(pp);
      text.appendChild(tp);
      text.appendChild(firstText);

      const cp1 = _createElement(xmlDoc, XMLNS, 'cp');
      cp1.setAttribute('IX', 1);
      const secondText = xmlDoc.createTextNode(
        textParts[1].replace(/(<([^>]+)>)/gi, '').replace(/['</u']/gi, '')
      );
      const cp2 = _createElement(xmlDoc, XMLNS, 'cp');
      cp2.setAttribute('IX', 2);
      text.appendChild(cp1);
      text.appendChild(secondText);
      text.appendChild(cp2);
    } else {
      const textNode = xmlDoc.createTextNode(textParts[0]);
      text.appendChild(textNode);
    }
  } else if (cell.value.includes('FIG')) {
    character = _createElement(xmlDoc, XMLNS, 'Section');
    character.setAttribute('N', 'Character');
    const row = _createElement(xmlDoc, XMLNS, 'Row');
    row.setAttribute('IX', 0);
    row.appendChild(_createCellElem('Style', 51, xmlDoc));
    row.appendChild(
      (() => {
        const cell = _createCellElem('Size', figFontSize, xmlDoc);
        cell.setAttribute('U', 'PT');
        return cell;
      })()
    );
    character.appendChild(row);

    const cp = _createElement(xmlDoc, XMLNS, 'cp');
    cp.setAttribute('IX', 0);
    const textNode = xmlDoc.createTextNode(
      cell.value.replace(/(<([^>]+)>)/gi, '').replace(/['</u']/gi, '')
    );

    text.appendChild(cp);
    text.appendChild(textNode);
  }
  return { text, paragraph, character };
}

/**
 * The char section is a child of the text section and contains text style information
 * @param {*} xmlDoc
 * @returns
 */
function _createCharSection(xmlDoc) {
  const charSection = _createElement(xmlDoc, XMLNS, 'Section');
  const blockFontSize = getVisioFontSize();
  charSection.setAttribute('N', 'Character');

  for (let i = 1; i <= 2; i++) {
    const row = _createElement(xmlDoc, XMLNS, 'Row');
    row.setAttribute('IX', i);
    row.appendChild(_createCellElem('Font', 'Themed', xmlDoc, 'THEMEVAL()'));
    row.appendChild(_createCellElem('Color', 'Themed', xmlDoc, 'THEMEVAL()'));
    if (i === 1) row.appendChild(_createCellElem('Style', 4, xmlDoc));
    if (i === 2) row.appendChild(_createCellElem('Style', 'Themed', xmlDoc, 'THEMEVAL()'));
    row.appendChild(_createCellElem('Case', 0, xmlDoc));
    row.appendChild(_createCellElem('Pos', 0, xmlDoc));
    row.appendChild(_createCellElem('FontScale', 1, xmlDoc));
    row.appendChild(_createCellElem('Size', blockFontSize, xmlDoc));
    row.appendChild(_createCellElem('DblUnderline', 0, xmlDoc));
    row.appendChild(_createCellElem('DoubleStrikeThrough', 0, xmlDoc));
    row.appendChild(_createCellElem('Letterspace', 0, xmlDoc));
    row.appendChild(_createCellElem('ColorTans', 0, xmlDoc));
    row.appendChild(_createCellElem('AsianFont', 'Themed', xmlDoc, 'THEMEVAL()'));
    row.appendChild(_createCellElem('ComplexScriptFont', 'Themed', xmlDoc, 'THEMEVAL()'));
    row.appendChild(_createCellElem('ComplexScriptSize', -1, xmlDoc));
    row.appendChild(_createCellElem('LandID', 'en-US', xmlDoc));

    charSection.appendChild(row);
  }
  return charSection;
}

/**
 * The Geo (Geometry) section of a shape contains information about its dimensions
 * and lines. This is where we actually draw the shape.
 * @param {*} xmlDoc
 * @param {*} cell
 * @param {*} width
 * @returns
 */
function _createGeoSection(xmlDoc, cell, width) {
  /*
    Geometry Section defines the coordinates of the lines that make up the shape
    all values are relative to the parent shape
  */
  const geoSection = _createElement(xmlDoc, XMLNS, 'Section');
  geoSection.setAttribute('N', 'Geometry');
  geoSection.setAttribute('IX', 0); // setting index

  geoSection.appendChild(_createCellElem('NoFill', 0, xmlDoc)); // Since false, shapes fill applies to path
  geoSection.appendChild(_createCellElem('NoLine', 0, xmlDoc)); // Since false, a line will be drawn around boundary
  geoSection.appendChild(_createCellElem('NoShow', 0, xmlDoc)); // Since false, lines will be shown
  geoSection.appendChild(_createCellElem('NoSnap', 0, xmlDoc)); // Since false, allows other shapes to snap to path
  geoSection.appendChild(_createCellElem('NoQuickDrag', 0, xmlDoc)); // Since false, shape can be selected or grabbed

  // Drawing Lines
  if (cell.style.includes('shape=callout')) {
    const points = calculateCalloutLinePoints(
      { ...cell.geometry, direction: cell.block.direction },
      (1 * VISIO_UNIT_SIZE) / width,
      0,
      1,
      true
    );
    points.forEach(({ x, y }, i) => {
      i === 0 ? _moveTo(xmlDoc, geoSection, x, y) : _drawLine(xmlDoc, geoSection, x, y);
    });
  } else {
    for (let i = 1; i <= 5; i++) {
      geoSection.appendChild(_drawGeoLines(xmlDoc, i));
    }
  }
  return geoSection;
}

/**
 * Draws a single line to the given x and y coordinates.
 *
 * Currently this is only used for drawing callout lines
 * @param {*} xmlDoc
 * @param {*} parent
 * @param {*} x
 * @param {*} y
 */
function _drawLine(xmlDoc, parent, x = 0, y = 0) {
  const row = _createElement(xmlDoc, XMLNS, 'Row');
  const index = parent.children.length;

  row.setAttribute('T', 'RelLineTo');
  row.setAttribute('IX', index);

  row.appendChild(_createCellElem('X', x, xmlDoc));
  row.appendChild(_createCellElem('Y', y, xmlDoc));

  parent.appendChild(row);
}

/**
 * Moves to the given coordinates without drawing a line. Usually used to set
 * the start position before drawing lines
 *
 * Currently only used for drawing callout lines
 * @param {*} xmlDoc
 * @param {*} parent
 * @param {*} x
 * @param {*} y
 */
function _moveTo(xmlDoc, parent, x = 0, y = 0) {
  const row = _createElement(xmlDoc, XMLNS, 'Row');
  const index = parent.children.length;

  row.setAttribute('T', 'RelMoveTo');
  row.setAttribute('IX', index);

  row.appendChild(_createCellElem('X', x, xmlDoc));
  row.appendChild(_createCellElem('Y', y, xmlDoc));

  parent.appendChild(row);
}

/**
 * This function is used to draw a basic rectangle
 * @param {*} xmlDoc
 * @param {*} i
 * @param {*} cords
 * @returns
 */
function _drawGeoLines(xmlDoc, i, cords = null) {
  const xVal = i === 2 || i === 3 ? 1 : 0; // if i is 2 or 3 xVal is 1 else it's 0
  const yVal = i === 3 || i === 4 ? 1 : 0; // if i is 3 or 4 yVal is 1 else it's 0
  const x = cords ? cords.x : xVal;
  const y = cords ? cords.y : yVal;

  const row = _createElement(xmlDoc, XMLNS, 'Row');
  row.setAttribute('T', i === 1 ? 'RelMoveTo' : 'RelLineTo');
  row.setAttribute('IX', i);

  row.appendChild(_createCellElem('X', x, xmlDoc));
  row.appendChild(_createCellElem('Y', y, xmlDoc));
  return row;
}

/**
 * Creates a connection (Line) between 2 blocks from a given mxGraph cell that
 * represents the connection
 * @param {*} xmlDoc
 * @param {*} id
 * @param {*} cell
 * @param {*} graph
 * @returns
 */
function _createNewConnection(xmlDoc, id, cell, graph) {
  /*
    Connections are just shapes with the name 'Dynamic Connector'
  */
  const shape = _createElement(xmlDoc, XMLNS, 'Shape');
  const state = graph.view.getState(cell, true);
  const { style } = cell;
  const points = state.absolutePoints;
  const scale = graph.view.scale;
  const begX = (points[0].x / scale / UNIT_SIZE + 1) * VISIO_UNIT_SIZE;
  const begY = pageHeight - (points[0].y / scale / UNIT_SIZE) * VISIO_UNIT_SIZE;
  const endX = (points[points.length - 1].x / scale / UNIT_SIZE + 1) * VISIO_UNIT_SIZE;
  const endY = pageHeight - (points[points.length - 1].y / scale / UNIT_SIZE) * VISIO_UNIT_SIZE;
  const xDif = begX - endX;
  const yDif = begY - endY;
  shape.setAttribute('Master', 4);
  shape.setAttribute('Type', 'Shape');
  shape.setAttribute('Name', 'Dynamic connector');
  shape.setAttribute('NameU', 'Dynamic connector');
  shape.setAttribute('ID', id);
  shape.appendChild(_createCellElem('PinX', begX + xDif / 2, xmlDoc, 'Inh'));
  shape.appendChild(_createCellElem('PinY', begY - yDif / 2, xmlDoc, 'Inh'));
  shape.appendChild(_createCellElem('Width', 0.5, xmlDoc, 'GUARD(EndX-BeginX)'));
  shape.appendChild(_createCellElem('Height', 0.25, xmlDoc, 'GUARD(EndY-BeginY)'));
  shape.appendChild(_createCellElem('LocPinX', 0, xmlDoc, 'Inh'));
  shape.appendChild(_createCellElem('LocPinY', 0, xmlDoc, 'Inh'));
  shape.appendChild(
    _createCellElem('BeginX', begX, xmlDoc, '_WALKGLUE(BegTrigger,EndTrigger,WalkPreference)')
  );
  shape.appendChild(
    _createCellElem('BeginY', begY, xmlDoc, '_WALKGLUE(BegTrigger,EndTrigger,WalkPreference)')
  );
  shape.appendChild(
    _createCellElem('EndX', endX, xmlDoc, '_WALKGLUE(EndTrigger,BegTrigger,WalkPreference)')
  );
  shape.appendChild(
    _createCellElem('EndY', endY, xmlDoc, '_WALKGLUE(EndTrigger,BegTrigger,WalkPreference)')
  );
  shape.appendChild(_createCellElem('LayerMember', 1, xmlDoc));
  shape.appendChild(
    _createCellElem(
      'BegTrigger',
      2,
      xmlDoc,
      `_XFTRIGGER(Sheet.${cell.source ? _getCellVsdxId(cell.source.id) : id}!EventXFMod)`
    )
  );
  shape.appendChild(
    _createCellElem(
      'EndTrigger',
      2,
      xmlDoc,
      `_XFTRIGGER(Sheet.${cell.target ? _getCellVsdxId(cell.target.id) : id}!EventXFMod)`
    )
  );
  shape.appendChild(_createCellElem('TxtPinX', 0.25, xmlDoc, 'Inh'));
  shape.appendChild(_createCellElem('TxtPinY', VISIO_UNIT_SIZE, xmlDoc, 'Inh'));
  if (style.includes('endArrow=classic')) shape.appendChild(_createCellElem('EndArrow', 5, xmlDoc));
  if (style.includes('startArrow=classic'))
    shape.appendChild(_createCellElem('BeginArrow', 5, xmlDoc));
  shape.appendChild(
    (() => {
      const controlSec = _createElement(xmlDoc, XMLNS, 'Section');
      controlSec.setAttribute('N', 'Control');
      controlSec.appendChild(
        (() => {
          const row = _createElement(xmlDoc, XMLNS, 'Row');
          row.setAttribute('N', 'TextPosition');
          row.appendChild(_createCellElem('Y', 0.25, xmlDoc));
          row.appendChild(_createCellElem('X', VISIO_UNIT_SIZE, xmlDoc));
          row.appendChild(_createCellElem('XDyn', 0.25, xmlDoc, 'Inh'));
          row.appendChild(_createCellElem('YDyn', VISIO_UNIT_SIZE, xmlDoc, 'Inh'));
          return row;
        })()
      );
      return controlSec;
    })()
  );
  shape.appendChild(
    (() => {
      const geoSec = _createElement(xmlDoc, XMLNS, 'Section');
      geoSec.setAttribute('N', 'Geometry');
      geoSec.setAttribute('IX', 0);
      if (xDif && yDif) {
        const sourceY = pageHeight - (cell.source.geometry.y / UNIT_SIZE) * VISIO_UNIT_SIZE;
        const sourceHeight = (cell.source.geometry.height / UNIT_SIZE) * VISIO_UNIT_SIZE;
        for (let i = 1; i <= 4; i++) {
          const row = _createElement(xmlDoc, XMLNS, 'Row');
          row.setAttribute('IX', i);
          row.setAttribute('T', i === 1 ? 'MoveTo' : 'LineTo');
          if (i === 1) {
            row.appendChild(_createCellElem('Y', yDif / 2, xmlDoc));
            row.appendChild(_createCellElem('X', -xDif / 2, xmlDoc));
          }
          if (i === 2) {
            if (begY === sourceY || begY === sourceY - sourceHeight) {
              row.appendChild(_createCellElem('X', -xDif / 2, xmlDoc));
              row.appendChild(_createCellElem('Y', -yDif / 2, xmlDoc));
            } else {
              row.appendChild(_createCellElem('X', -xDif - xDif / 2, xmlDoc));
              row.appendChild(_createCellElem('Y', yDif / 2, xmlDoc));
            }
          }
          if (i === 3) {
            row.appendChild(_createCellElem('X', -xDif - xDif / 2, xmlDoc));
            row.appendChild(_createCellElem('Y', -yDif / 2, xmlDoc));
          }
          if (i === 4) {
            row.setAttribute('Del', 1);
          }
          geoSec.appendChild(row);
        }
      } else {
        for (let i = 1; i <= 3; i++) {
          const row = _createElement(xmlDoc, XMLNS, 'Row');
          row.setAttribute('IX', i);
          row.setAttribute('T', i === 1 ? 'MoveTo' : 'LineTo');
          if (i === 1) row.appendChild(_createCellElem('Y', yDif / 2, xmlDoc));
          if (i === 1) row.appendChild(_createCellElem('X', -xDif / 2, xmlDoc));
          if (i === 2) row.appendChild(_createCellElem('X', -xDif - xDif / 2, xmlDoc));
          if (i === 2) row.appendChild(_createCellElem('Y', -yDif / 2, xmlDoc));
          if (i === 3) row.setAttribute('Del', 1);
          geoSec.appendChild(row);
        }
      }
      return geoSec;
    })()
  );
  return shape;
}

/**
 * This takes the mxGraph instance and loops through its pages converting them into
 * xml documents
 * @param {*} graph
 * @param {*} illustration
 * @returns
 */
function _convertMxModel2Page(graph, illustration) {
  const xmlDoc = mxUtils.createXmlDocument();
  const root = _createElement(xmlDoc, XMLNS, 'PageContents');

  root.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', XMLNS);
  root.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:r', XMLNS_R);
  root.setAttribute('xml:space', 'preserve');

  const shapes = _createElement(xmlDoc, XMLNS, 'Shapes');
  root.appendChild(shapes);
  const { model } = graph;

  const layers = graph.model.getChildCells(graph.model.root);
  const cells = Object.values(model.cells);
  const layerIdsMaps = {};

  layers.forEach((layer, index) => {
    layerIdsMaps[layer.id] = index;
  });

  cells.forEach((cell) => {
    const layerIndex = cell.parent != null ? layerIdsMaps[cell.parent.id] : null;

    // Create shapes and connections for page
    if (layerIndex != null) {
      if (cell.style.includes('shape=image')) {
        const id = _getCellVsdxId(cell.id);
        const shape = _createNewShape(xmlDoc, id, cell, illustration);
        shapes.appendChild(shape);
      } else if (cell.vertex) {
        const id = _getCellVsdxId(cell.id);
        const shape = _createNewShape(xmlDoc, id, cell);
        if (shape != null) shapes.appendChild(shape);
      } else if (cell.edge) {
        const id = _getCellVsdxId(cell.id);
        const connection = _createNewConnection(xmlDoc, id, cell, graph);
        if (connection !== null) shapes.appendChild(connection);
      }
    }
  });

  const connects = _createElement(xmlDoc, XMLNS, 'Connects');

  cells.forEach((cell) => {
    if (cell.edge) {
      if (cell.source) {
        const connect = _createElement(xmlDoc, XMLNS, 'Connect');
        connect.setAttribute('FromSheet', _getCellVsdxId(cell.id));
        connect.setAttribute('FromCell', 'BeginX');
        connect.setAttribute('toSheet', _getCellVsdxId(cell.source.id));
        connects.appendChild(connect);
      }
      if (cell.target) {
        const connect = _createElement(xmlDoc, XMLNS, 'Connect');
        connect.setAttribute('FromSheet', _getCellVsdxId(cell.id));
        connect.setAttribute('FromCell', 'EndX');
        connect.setAttribute('toSheet', _getCellVsdxId(cell.target.id));
        connects.appendChild(connect);
      }
    }
  });

  root.appendChild(connects);

  xmlDoc.appendChild(root);

  return xmlDoc;
}
