/**
 * Text data used throughout the application
 */

import { capitalize, lowerCase } from 'lodash/string';

export const COMPANY_COMMON_NAME = 'PatentPal';
export const COMPANY_LEGAL_NAME = 'PatentPal Inc.';
export const COMPANY_SUPPORT_EMAIL = 'support@patentpal.com';

export const GEN_BACK = 'Back';
export const GEN_BILLING = 'Billing';
export const GEN_CLAIM = 'Claim';
export const GEN_DESCRIPTION = 'Specification';
export const GEN_DIAGRAM = 'Figure';
export const GEN_DOCUMENT = 'Document';
export const GEN_DOWNLOAD = 'Download';
export const GEN_DRAFT = 'Draft';
export const GEN_EMAIL = 'Email';
export const GEN_GENERATE = 'Generate';
export const GEN_GOOGLE = 'Google';
export const GEN_HOME = 'Home';
export const GEN_LINKEDIN = 'LinkedIn';
export const GEN_LOG_IN = 'Log in';
export const GEN_LOG_OUT = 'Log out';
export const GEN_PASSWORD = 'Password';
export const GEN_PRODUCT = COMPANY_COMMON_NAME;
export const GEN_PROFILE = 'Profile';
export const GEN_SIGN_UP = 'Sign up';
export const GEN_SUBSCRIBE = 'Subscribe';
export const GEN_WORD = 'Word';
export const GEN_RESET = 'Reset password';

export const DOC_TITLE = `New ${lowerCase(GEN_DOCUMENT)}`;
export const DOC_BLANK = `Blank ${lowerCase(GEN_DOCUMENT)}`;
export const DOC_UPLOAD = `Upload ${GEN_WORD} ${lowerCase(GEN_DOCUMENT)}`;

export const DRAFT_INPUT = `Input ${lowerCase(GEN_CLAIM)}s`;
export const DRAFT_GENERATE = `${capitalize(GEN_GENERATE)} ${lowerCase(GEN_DRAFT)}`;

export const SUBSCRIBE_SUCCESS =
  'Welcome! Let us know if you have any questions at support@patentpal.com.';

export const ERROR_APP = `An error occurred. Please contact <a href="mailto:support@patentpal.com" style="color: inherit"> ${COMPANY_SUPPORT_EMAIL}</a>`;
export const ERROR_USER =
  'An error occurred. Please double check your inputs. If the error persists, please contact';
export const ERROR_EMAIL_EXISTS = 'An account with this email already exists';
export const ERROR_PASSWORD_UPPER = 'Password must include an uppercase letter';
export const ERROR_PASSWORD_LOWER = 'Password must include a lowercase letter';
export const ERROR_PASSWORD_NUMBER = 'Password must include a number';
export const ERROR_PASSWORD_LENGTH = 'Password must be 8+ characters long';

export const FORM_FORGOT = `Forgot ${lowerCase(GEN_PASSWORD)}`;
export const FORM_RESET = `Reset ${lowerCase(GEN_PASSWORD)}`;
export const FORM_GOOGLE = `${capitalize(GEN_LOG_IN)} with ${GEN_GOOGLE}`;
export const FORM_LINKEDIN = `${capitalize(GEN_LOG_IN)} with ${GEN_LINKEDIN}`;
export const FORM_FIRST = 'First name';
export const FORM_LAST = 'Last name';
export const FORM_EMAIL = `Work ${lowerCase(GEN_EMAIL)} address`;
export const FORM_NEW_USER = 'New user?';
export const FORM_RETURN_USER = 'Already signed up?';

export const MENU_BACK = `${GEN_BACK} to ${lowerCase(GEN_HOME)}`;
export const MENU_BILLING = GEN_BILLING;
export const MENU_NEW_DOC = `New ${lowerCase(GEN_DOCUMENT)}`;
export const MENU_PROFILE = GEN_PROFILE;
export const MENU_UPLOAD = `Upload ${lowerCase(GEN_DOCUMENT)}`;

export const PRIVACY_TEXT = `By using ${GEN_PRODUCT} you consent to our`;
export const PRIVACY_LINK = 'Privacy Policy';

export const SUB_TITLE = 'Select a plan';
export const SUB_SUBTITLE = 'First two weeks free, cancel anytime';
export const SUB_PER = '/month';
export const SUB_MONTH = '$150';
export const SUB_ANNUAL = '$100';
export const SUB_PAYMENT = 'Payment method';
export const SUB_BILLING = `${GEN_BILLING} address`;
export const SUB_ADDRESS_ONE = 'Address line 1';
export const SUB_ADDRESS_TWO = 'Address line 2';
export const SUB_CITY = 'City';
export const SUB_STATE = 'State';
export const SUB_COUNTRY = 'Country';
export const SUB_ZIP = 'Zip code';
export const SUB_CONFIRM = 'Start trial';
