<!-- 
  Generic prompt component, used throughout the app for rendering different prompts.
-->
<template>
  <v-card light :max-width="maxWidth" :width="width">
    <div class="content">
      <h3 class="dark-text" v-html="title" />
      <p v-html="text" />
      <iframe
        v-if="video"
        class="youtubeVideo"
        src="https://www.youtube.com/embed/tmoBrD5Jn6g"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <v-card-actions class="p-0">
      <v-spacer></v-spacer>
      <v-btn v-if="!cancelHidden" text @click="$emit('cancel')">
        <span class="dark-text px-2">
          {{ compCancelText }}
        </span>
      </v-btn>
      <v-btn text @click="$emit('okay')" class="p-0" min-width="1px">
        <span :class="okayStyle">
          {{ compOkayText }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PromptCard',
  props: {
    title: String,
    text: String,
    colorOkay: {
      type: Boolean,
      default: true,
    },
    video: {
      type: Boolean,
      default: false,
    },
    suggested: Boolean,
    cancelHidden: Boolean,
    colors: Boolean,
    okayText: String,
    cancelText: String,
    maxWidth: {
      type: String,
      default: '400px',
    },
    width: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    /**
     * @returns {String}
     */
    compOkayText() {
      return this.okayText ? this.okayText : 'Okay';
    },
    /**
     * @returns {String}
     */
    compCancelText() {
      return this.cancelText ? this.cancelText : 'Cancel';
    },

    /**
     * @returns {String}
     */
    okayStyle() {
      return this.colorOkay ? 'primary-text bold px-2' : 'dark-text bold px-2';
    },

    /**
     * @returns {String}
     */
    suggestedClass() {
      return this.suggested ? 'suggested' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 15px;
  padding: 0px 0px 20px;
  color: $app-text-color;
  margin-bottom: 0 !important;
}

h3 {
  font-weight: normal;
  margin-bottom: 5px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 25px 30px 0;
}

.v-card__actions {
  padding: 15px 30px 20px !important;
  padding-top: 0 !important;
  display: flex;
  justify-content: space-between;
  .v-btn.v-btn {
    padding: 0;
  }
}

.youtubeVideo {
  width: 100%;
  aspect-ratio: 16/9;
  align-self: center;
  margin-bottom: 10px;
  border-radius: 4px;
  border: none;
}
</style>
