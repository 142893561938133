/**
 * This file contains messages related to tier limits.
 * If the user hits a tier limit they will receive the appropriate message
 */

import config from '@/data/var.config';

export const DRAFT_LIMIT_TEXT = `You've reached the ${config.DRAFT_LIMIT} draft limit for this month. Please subscribe to get unlimited access.`;
export const ADD_PROFILE_TEXT = 'Please subscribe to add additional customization profiles.';
export const CHANGE_PROFILE_TEXT = 'Please subscribe to switch customization profiles.';
export const DEFAULT_TEXT = 'Please subscribe to get unlimited access.';
