/**
 * Block
 *
 * An internal data structured used for the figure editor.
 * A block is a child of a figure.
 */

import { figureConstants as constants } from '@/data/constants';
import { getTextWidth, removeTags } from '@/support/utilities';

export default class Block {
  /**
   * @param {object} block Object to be converted to block
   *
   * @param {String} block.id - A valid UUID
   * @param {String} block.type - CONCEPT, CONTEXT, or null
   * @param {String} block.shape - Geometric shape, e.g. PILL
   *
   * @param {String} block.text - Concept text
   * @param {String} block.label - Numerical label
   *
   * @param {Number} block.x - An integer inclusively between 0 and 50
   * @param {Number} block.y - An integer inclusively between 0 and 70
   * @param {Number} block.width - An integer inclusively between 0 and 50
   * @param {Number} block.height - An integer inclusively between 0 and 70
   *
   * @param {String} block.parent - ID of the parent block
   * @param {String[]} block.children - IDs of child blocks
   *
   * @param {Object} block.concept
   * @param {Object[]} block.lines
   */
  constructor(block) {
    this.id = block.id;
    this.type = block.type;
    this.shape = block.shape;

    // Contents
    this.text = block.text;
    this.label = block.label;

    // Measures
    this.x = block.x;
    this.y = block.y;
    this.width = block.width;
    this.height = block.height;

    // Tree
    this.parent = block.parent;
    this.children = block.children;

    // References
    this.concepts = block.concept; // TODO: Rename to "concept"
    this.links = block.lines; // TODO: Rename to "lines"

    /*
    Direction of the block's callout line, if one exists. Possible directions:
      0 - No callout
      1 - Top-left
      2 - Top-right
      3 - Bottom-left
      4 - Bottom-right
    */
    this.direction = 0;
  }

  /**
   * Update this block with new values from an mxCell object.
   * @param {Object} mxCell - mxCell object
   */
  update(mxCell) {
    const geometry = mxCell.geometry;
    const { text, label } = this.getTextAndLabel(mxCell.value);

    // Update measures
    this.x = geometry.x / constants.UNIT_SIZE;
    this.y = geometry.y / constants.UNIT_SIZE;
    this.width = geometry.width / constants.UNIT_SIZE;
    this.height = geometry.height / constants.UNIT_SIZE;

    // Update contents
    this.text = text;
    this.label = label;
  }

  /**
   * Extract this block's text and label from the message of an mxCell.
   * Helper for update().
   * @param {String} message - MxCell message
   * @returns {Object} - Object with text and label
   */
  getTextAndLabel(message) {
    const labelRegex = /\b(\d+[a-z]*)\b/gi;

    // Get label
    const labelMatch = message?.match(labelRegex);
    let label = labelMatch ? labelMatch[0] : '';
    label = removeTags(label);

    // Get text
    let text = message?.replace(labelRegex, ''); // Remove label
    text = removeTags(text);

    return { text, label };
  }

  /**
   * Update the numerical part of block's label based on a figure number.
   * @param {String} figureNumber
   * @param isCallout
   */
  updateNumber(figureNumber, isCallout = false) {
    // Get block label
    const { label } = this;
    if (!label) return;

    // Separate the number and letter portions of the label
    const labelParts = label.match(/[a-z]+|[^a-z]+/gi);
    if (!labelParts) return;
    let [labelNumber, labelLetter] = labelParts;
    labelLetter = labelLetter ? labelLetter : '';

    // Update label with new label number
    const lastTwoDigits = labelNumber ? labelNumber.slice(-2) : '';
    labelNumber = `${figureNumber}${lastTwoDigits}`;
    this.label = `${labelNumber}${labelLetter}`;

    // Adjust the width of the callout block to prevent its text from overlapping with its callout line
    if (isCallout)
      this.width = getTextWidth(this.label, constants.FONT_SIZE) / constants.UNIT_SIZE + 3;
  }

  /**
   * Add a link to this block. TODO: Double check and refactor this method
   * @param {String} id - UUID referencing a link object
   */
  addLinkId(id) {
    const existingLink = this.links.find((link) => link.id === id);
    if (!existingLink) this.links.push(id);
  }

  /**
   * Remove a link from this block. TODO: Double check and refactor this method
   * @param id - FIXME: Is this the UUID or the link object?
   */
  removeLink(id) {
    const index = this.links.find((link) => link === id);
    this.links.splice(index, 1);
  }
}
