import { selectFile } from '@/support/utilities';
import { CATEGORY_UPLOAD } from '@/data/constants/constantsGoogleAnalyticsEvents';

export default {
  /**
   * Generic state mutation for upload module
   *
   * @param {Object} state - Vuex state module
   * @param {String} key - String representing the state attribute to update
   * @param val - Value to set
   */
  setUploadState(state, { key, val }) {
    state[key] = val;
  },

  toggleDialog(state, payload = { val: !state.dialog, overwrite: false }) {
    state.drop = payload.val;
    state.dialog = payload.val;
    state.overwrite = payload.overwrite;
  },

  triggerSelection(state, cb) {
    selectFile(cb, '.docx');
    state.overwrite = false;
  },

  saveFile(state, { e, action }) {
    this.$app.$gtag.event(action, { event_category: CATEGORY_UPLOAD });
    e.preventDefault();
    state.drop = true;
    state.file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    if (e.target.files) e.target.remove();
  },

  startUpload(state) {
    state.uploading = true;
  },

  endUpload(state) {
    state.uploading = false;
    state.drop = false;
    state.file = null;
    state.progress = 0;
  },
};
