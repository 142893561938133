import { messages } from '@/data/constants';

const state = {
  error: false,
  warn: false,
  success: false,
  message: messages.ERROR_APP,
};

export default state;
