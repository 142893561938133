<!--
  Show Loading spinner based on Vuex state.draft.loading, cycles through phrases
  state changed by ClaimsAndGraphPanel when generating Diagrams and used in UploadPopup
-->

<template>
  <div class="loadingContainer">
    <v-progress-circular size="100" indeterminate />
    <p v-if="showPhrase" style="margin-top: 50px">{{ phrase }}</p>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { PHRASES, PHRASE_DURATION } from '@/data/constants/constantsLoadingPhrases';

export default {
  name: 'Loading',
  data() {
    return {
      phrase: 'Processing request...',
      phrases: PHRASES,
      rotatePhrases: null,
      progressInterval: null,
      interval: 0,
      progress: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.rotatePhrases);
    clearInterval(this.progressInterval);
  },
  computed: {
    ...mapState({
      loading: (state) => state.draft.loading,
      showPhrase: (state) => state.draft.loadingPhrases,
    }),
  },
  methods: {
    ...mapMutations('draft', ['setLoading']),

    createPhraseInterval() {
      return setInterval(() => {
        this.phrase = this.phrases[this.interval];
        if (this.interval === 5) {
          clearInterval(this.rotatePhrases);
        } else {
          this.interval++;
        }
      }, PHRASE_DURATION);
    },

    createProgressInterval() {
      return setInterval(() => {
        if (this.progress < 95) {
          this.progress += 1.4;
        } else {
          clearInterval(this.progressInterval);
        }
      }, 140);
    },

    finishLoading() {
      this.phrase = 'Processing request';
      this.interval = 0;
      this.progress = 0;
      clearInterval(this.rotatePhrases);
      clearInterval(this.progressInterval);
    },
  },

  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.rotatePhrases = this.createPhraseInterval();
          this.progressInterval = this.createProgressInterval();
        } else {
          this.finishLoading();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
