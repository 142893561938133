/* 
  This is our draft state module. It was the first Vuex module I created, so it
  has evolved to contain much of the global state for the app.

  It's likely this could be refactored into one or two more vuex modules though.

  For example, we have stored values such as 'loading', 'showTutorial', and 'activeProfile'
  These values are all used in different parts of the app and it could indicate
  that this module could be broken up into slightly smaller ones.
*/

import { Outline } from '@/data/Outline';
import Figure from '@/data/Figure';
import { TierLimits as tl } from '@/data/constants';
import { v4 as uuid } from 'uuid';
import * as customizations from '@/data/constants/customizations';

const state = {
  // Core app state
  claims: '',
  outlines: [new Outline({ tags: [], concepts: [], points: [{ text: '', children: [] }] })],
  figures: [new Figure(1, 'defaultUUID')],
  specification: '',
  step: 1,
  furthestStep: 1,

  // Render controls
  showProfileOverlay: false, // Whether or not the profile overlay should be rendered
  tooltip: false,
  loadingPhrases: true, // Whether or not loading phrases should be shown on the loading indicator
  loading: false, // Whether or not to show the loading indicator
  browserPrompt: false, // Whether or not to show the browser alert window when user leaves the app.
  subscribePrompt: false, // Whether or not to show the subscribe prompt
  subscribePromptMessage: tl.DEFAULT_TEXT, // What message should be shown in subscribe prompt
  showLogoutPrompt: false,
  tutorialCloseButtons: false,
  showWorkspace: false,

  // Saved user contents
  savedOutlines: null,
  savedClaims: null,

  // Scroll instances. Used during the sequential load to scroll the panels as data is inserted
  specScrollInstance: null,
  figureScrollInstance: null,
  rootScroll: null, // This will contain a reference to the root scroll container of the app

  // Sequential load controls
  sequential: true, // Whether or not to load sequentially
  sequentialSkip: false, // Whether or not the sequential load should be skipped
  insertingDocData: false, // Whether or not the app is inserting data during the sequential load
  nextFigure: true,

  // States based on user actions
  viewingTutorial: false, // If the user is viewing the tutorial.

  // The users active customization profile
  activeProfile: {
    name: 'Default',
    editing: false,
    id: uuid(),
    truncateText: customizations.truncate_text_default,
    verbose_steps: true,
    highlight_tagged_text: false,
    summary_intro: customizations.summary_intro,
    family_intro: customizations.family_intro,
    concept_intro: customizations.concept_intro,
    context_intro: customizations.context_intro,
    including: customizations.including,
    may_include: customizations.may_include,
    may_also_include: customizations.may_also_include,
    independent_claims_only: customizations.independent_claims_only_default,
  },
};

export default state;
