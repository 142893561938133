/**
 * App entry point. Initializes and configures Vue.
 */

import Vue from 'vue';
import App from '@/App.vue';

import VueRouter from 'vue-router';
import { Plugin as fragment } from 'vue-fragment';
import VueMeta from 'vue-meta';
import VueTour from 'vue-tour';
import store from './vuex';
import { vuetify, auth, router } from '@/plugins';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { STRIPE_PK } from '@/data/var.config';
import VueGtag from 'vue-gtag';
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';

/*
  Add scrollbar styles to the app. We need this to make OverlayScrollbarsPlugin work correctly.
  The plugin needs to reference these styles, so we need to import these styles in main.js.
  Our own components do not currently reference these styles directly.
*/
import 'overlayscrollbars/css/OverlayScrollbars.css';

// Prevents Vue from printing the "you're in development mode" tip to the console
Vue.config.productionTip = false;

// External libraries
Vue.use(VueRouter);
Vue.use(fragment);
Vue.use(VueMeta);
Vue.use(OverlayScrollbarsPlugin);

/*
  Custom transition used by our Tooltip component.
  See: https://vuetifyjs.com/en/styles/transitions/#create-your-own.
*/
const myTransition = createSimpleTransition('my-transition'); // Custom transition defined in App.vue
Vue.component('my-transition', myTransition); // Register custom transition with Vue

// Tutorial plugin
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

// External third-party services
Vue.use(auth); // Auth0
Vue.use(StripePlugin, { pk: STRIPE_PK }); // Stripe
Vue.use(VueGtag, { config: { id: 'UA-173540556-1' } }, router); // Google Analytics

// Mount Vue
const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    this.$auth.$store = this.$store;
  },
});
store.$app = app;
app.$mount('#app'); // Insert the app into the DOM. #app is defined in index.html.
