import { packOutline } from '@/support/utilities';

export default {
  /**
   * Denotes if the figure and specification should be displayed
   * @param {Object} state - Draft state
   * @returns
   */
  showDiagrams({ step }) {
    return step === 2;
  },

  /**
   * Checks if the current outline is the initial outline
   * @param {Object} state - draft state
   * @returns
   */
  initialOutline({ outlines }) {
    return (
      outlines.length === 1 &&
      outlines[0].points.length === 1 &&
      outlines[0].points[0].children.length === 0 &&
      outlines[0].points[0].text.length === 0
    );
  },

  /**
   * Checks if outlines have changed since they were last saved
   * @param {Object} state - draft state
   * @param {Getters} getters - Object containing the other getters in this module
   * @returns
   */
  outlineChanged({ outlines, savedOutlines }, getters) {
    if (getters.initialOutline) {
      return false;
    }
    const currentOutlines = JSON.stringify(packOutline(outlines));
    return currentOutlines !== savedOutlines;
  },

  /**
   * Checks if claims have changes since they were last saved
   * @param {Object} state - draft state
   * @param {Object} getters  - Object containing other getters in this module
   * @returns
   */
  claimsChanged({ claims, savedClaims }, getters) {
    if (getters.validClaims) {
      return claims !== savedClaims;
    }
    return false;
  },

  /**
   * Checks if claims are valid. Used to prevent sending requests with invalid claims
   * @param {Object} state - draft state
   * @returns
   */
  validClaims({ claims }) {
    return claims.trim().length > 0;
  },
};
