/**
 * Internal data structure used in the figure editor.
 */

import Line from './Line';
import Block from './Block';
import { PAGE_LEFT_PADDING, PAGE_TOP_PADDING } from '@/data/constants/figureConstants';

/**
 * {@link Figure} data formatted to be consumed by diagram editor
 * @type {Figure}
 */
export default class Figure {
  /**
   * Creates a new Figure which is formatted to be consumed by the diagram editor
   * @param {string} number - diagram figure number
   * @param {string} id - UUID string of figure
   * @param illustration
   * @param {boolean} isOverflow - Whether a figure is part of an overflow group
   */
  constructor(number, id, illustration = null, isOverflow = /[a-z]/gi.test(number)) {
    this.id = id;
    // This is the figure label. Historically it was just called number which is why we call it number here
    this.number = number;
    this.overflow = isOverflow; // TODO: Rename this to isOverflow

    // References
    this.blocks = [];
    this.links = [];
    this.editor = null;
    // this is the data URL of the illustration file. See: https://developer.mozilla.org/en-US/docs/web/http/basics_of_http/data_urls
    this.illustration = illustration;
  }

  /**
   * Create a new list of Blocks
   * @param {Object[]} blocks
   * @param {String} blocks[].id - A valid UUID
   * @param {String} blocks[].text - A string of text
   * @param {String} blocks[].label - A string of text
   * @param {String} blocks[].type - Must be one of CONTEXT, CONCEPT, or null
   * @param {Number} blocks[].x - Must be a number greater than -1 and less than 51
   * @param {Number} blocks[].y - Must be a number greater than -1 and less than 71
   * @param {Number} blocks[].width - Must be a number greater than -1 and less than 51
   * @param {Number} blocks[].height - Must be a number greater than -1 and less than 71
   * @param {String} blocks[].parent - Must be a valid UUID
   * @param {Object[]} blocks[].children
   * @param {Object[]} blocks[].links
   * @param {Object[]} blocks[].concepts
   */
  createBlocks(blocks) {
    this.blocks = blocks.map((b) => {
      b.x += PAGE_LEFT_PADDING;
      b.y += PAGE_TOP_PADDING;
      return new Block(b);
    });
  }

  /**
   * Creates a new list of Connections
   * @param {object[]} links list of links from server
   * @param {String} links[].id - UUID string
   * @param {String} links[].label - label text of string - *not currently supported*
   * @param {String} links[].head - UUID of head block
   * @param {String} links[].tail - UUID of tail block
   * @param {String} links[].type - Link type
   */
  createLinks(links) {
    this.links = links.map((l) => new Line(l));
  }

  /**
   * Create a new link and add to JSON
   * @param {Object} link mxCell edge representing a connection between 2 blocks
   */
  createNewLink(link) {
    const newLink = new Line({
      id: link.id,
      label: link.value,
      head: link.source.id,
      tail: link.target.id,
      type: 'UNDIRECTED',
    });
    this.links.push(newLink);
    const head = this.blocks.find((b) => b.id === newLink.head);
    const tail = this.blocks.find((b) => b.id === newLink.tail);
    head.addLinkId(newLink.id);
    tail.addLinkId(newLink.id);
  }

  /**
   * Create a new block from a mxGraph cell and add it to this figure.
   * @param blockMXG - mxGraph cell representing a block
   */
  createNewBlock(blockMXG) {
    const { text, label } = this.extractTextAndLabels(blockMXG.value);
    const geo = blockMXG.geometry;
    const newBlock = new Block({
      id: blockMXG.id,
      x: geo.x / 10,
      y: geo.y / 10,
      width: geo.width / 10,
      height: geo.height / 10,
      text,
      label,
      parent: null,
      children: [],
      type: null,
      links: [],
      concepts: [],
    });
    this.blocks.push(newBlock);
  }

  /**
   * Get a block's text and label from an mxCell's value.
   * @param {string} mxCellValue
   * @returns {{text: *, label: *}}
   */
  extractTextAndLabels(mxCellValue) {
    if (mxCellValue.includes('\n')) {
      const split = mxCellValue.split('\n');
      const text = split[0];
      const label = split[1].replace(/<u>/gi, '').replace(/<\/u>/gi, '');

      return { text, label };
    }
    const split = mxCellValue.split(' ');
    const text = split[0];
    const label = split[1].replace(/<u>/gi, '').replace(/<\/u>/gi, '');

    return { text, label };
  }

  /**
   * Remove a link its head and tail blocks and from this figure.
   * @param id
   */
  removeLink(id) {
    const index = this.links.findIndex((l) => l.id === id);
    const link = this.links[index];
    const head = this.blocks.find((b) => b.id === link.head);
    const tail = this.blocks.find((b) => b.id === link.tail);
    head.removeLink(id);
    tail.removeLink(id);
    this.links.splice(index, 1);
  }

  /**
   * Remove a block from this figure.
   * @param {string} id - UUID
   */
  removeBlock(id) {
    const index = this.blocks.findIndex((b) => b.id === id);

    // Remove related links
    const deletedLinks = this._removeRelatedLinksFromFigure(this.blocks[index].links);
    this._removeRelatedLinksFromHeadAndTail(deletedLinks);

    this.blocks.splice(index, 1);
  }

  /**
   * Remove links associated with a block from this figure. Helper for removeBlock().
   * @param {Line[]} blockLinks
   * @returns {Object[]}
   */
  _removeRelatedLinksFromFigure(blockLinks) {
    const deletedLinks = [];
    blockLinks.forEach((link) => {
      const linkIndex = this.links.findIndex((l) => l.id === link);
      const linkObject = this.links[linkIndex];
      const head = this.blocks.find((b) => b.id === linkObject.head);
      const tail = this.blocks.find((b) => b.id === linkObject.tail);
      const deletedLink = {
        id: link,
        head,
        tail,
      };
      deletedLinks.push(deletedLink);
      this.links.splice(linkIndex, 1);
    });
    return deletedLinks;
  }

  /**
   * Remove links associated with a block from head and tail blocks. Helper for removeBlock().
   * @param deletedLinks
   */
  _removeRelatedLinksFromHeadAndTail(deletedLinks) {
    if (deletedLinks) {
      deletedLinks.forEach((link) => {
        link.head.removeLink(link.id);
        link.tail.removeLink(link.id);
      });
    }
  }
}
