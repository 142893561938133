<!-- 
  Our custom logout prompt used to confirm that the user wants to leave the app
-->

<template>
  <v-overlay :value="logoutPrompt">
    <PromptCard
      title="Log out"
      text="Are you sure you want to log out? You will lose your current progress."
      okay-text="Log out"
      @okay="logout"
      @cancel="hideLogoutPrompt"
    />
  </v-overlay>
</template>

<script>
import PromptCard from './PromptCard';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'LogoutPrompt',
  components: {
    PromptCard,
  },

  computed: {
    ...mapState('draft', { logoutPrompt: (state) => state.showLogoutPrompt }),
  },

  methods: {
    ...mapMutations('draft', [
      'hideLogoutPrompt',
      'setLoading',
      'hideBrowserPrompt',
      'hideLoadingPhrases',
    ]),

    closePrompt() {
      this.hideLogoutPrompt();
    },

    logout() {
      this.hideBrowserPrompt();
      this.hideLogoutPrompt();
      this.hideLoadingPhrases();
      this.setLoading(true);
      try {
        this.$auth.logout({
          returnTo: window.location.origin,
          localOnly: false,
        });
      } catch (err) {
        this.setLoading(false);
        console.error(err);
      }
    },
  },
};
</script>

<style scoped></style>
