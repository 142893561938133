/**
 * Google Analytics events tracked by the client.
 */

// Google Analytics categories
export const CATEGORY_GENERATE = 'generate'; // A server response from Alice for generating a draft
export const CATEGORY_DOWNLOAD = 'download'; // When a user downloads an exported file
export const CATEGORY_DOWNLOAD_MENU_BUTTON = 'download menu button';
export const CATEGORY_DRAFT = 'draft'; // User creates a new empty document
export const CATEGORY_UPLOAD = 'upload';
export const CATEGORY_CLAIMS = 'Claims Panel'; // Edits to the claims panel
export const CATEGORY_SPEC = 'Specification Panel';
export const CATEGORY_FIG = 'Figure Panel';
export const CATEGORY_FIG_LABEL_EDITOR = 'Figure Label Editor';
export const CATEGORY_PROFILE_OVERLAY = 'Profile Overlay';
export const CATEGORY_GENERATE_TRIGGER = 'Generate trigger'; // How a user triggers a generate event, e.g. ctrl + enter
export const CATEGORY_TUTORIAL = 'Tutorial';
export const CATEGORY_PHRASE_CUSTOMIZATION = 'Phrase customization';

// Specific user actions
export const ACTION_LOGIN = 'login'; // Every time the user logs in
export const ACTION_SIGNUP = 'sign_up'; // Every time a user signs up
export const ACTION_LOGOUT = 'logout'; // Every time a user logs out
export const ACTION_CLICK = 'click'; // Every time a user clicks an elements
export const ACTION_ENTER = 'ctrl_enter_key_press'; // User used the ctrl + enter shortcut to generate
export const ACTION_EDIT = 'edit'; // Any time the user edits something
export const ACTION_OPEN = 'open'; // Opening something, e.g. profile overlay
export const ACTION_CLOSE = 'close';
export const ACTION_DELETE_ACCOUNT = 'deleted account';
export const ACTION_REQUEST_PASSWORD_RESET = 'requested password reset';
export const ACTION_UPDATE_PROFILE = 'updated profile';
export const ACTION_NEW_DOCUMENT = 'new document';
export const ACTION_DOWNLOAD_VISIO = 'visio download';
export const ACTION_DOWNLOAD_WORD = 'word download';
export const ACTION_GENERATE_PASS = 'generation succeeded';
export const ACTION_GENERATE_FAIL = 'generation failed';
export const ACTION_CLICK_GENERATE_BUTTON = 'click generate button';
export const ACTION_USE_SIMPLE_CLAIMS = 'used simple system claims';
export const ACTION_USE_SIMPLE_METHOD = 'used simple method claims';
export const ACTION_USE_SEISMIC_ANCHOR = 'used seismic anchor example';
export const ACTION_USE_MOTION_CAPTURE_METHOD = 'used motion capture method';
export const ACTION_CHANGE_FIG_LABEL = 'changed figure label';
export const ACTION_DROP_UPLOAD = 'drop upload';
export const ACTION_MAIN_MENU_SELECT_UPLOAD = 'select upload main menu';
export const ACTION_DASHBOARD_SELECT_UPLOAD = 'select upload from dashboard';
export const ACTION_TUTORIAL_START = 'tutorial started from main menu';
export const ACTION_TUTORIAL_OPT_IN = 'tutorial opt in';
export const ACTION_TUTORIAL_OPT_OUT = 'tutorial opt out';
export const ACTION_TUTORIAL_QUIT = 'tutorial_quit';
export const ACTION_TUTORIAL_FINISHED = 'tutorial_finished';
export const ACTION_USED_DEFAULT = 'Used default phrases';
export const ACTION_USED_CUSTOM = 'Used custom phrases';
