import { GenerateHelpers as gh } from '@/support/utilities';
import { formatPara } from '@/support/utilities/unpackerHelpers/descriptionFormatters';
import {
  ACTION_GENERATE_PASS,
  CATEGORY_GENERATE,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import config from '@/data/var.config.js';
import { TierLimits as tl, phrases as p, CookieKeys as c } from '@/data/constants';
import { packDiagrams, packOutline } from '@/support/utilities';
import { unpackDiagrams } from '@/support/utilities/unpackers';
import { decrypt, encrypt } from '@/support/utilities/encryption';
// Don't delete ActionContext this is how we can set it as the type in the doc strings
// eslint-disable-next-line no-unused-vars
import { ActionContext } from 'vuex';

export default {
  /**
   * Send a generate request to alice, wait for response, then unpack response.
   * @param {ActionContext} cx - Vuex context object
   * @param {String?} claims - claims text. Defaults to state.claims
   * @returns
   */
  async generateDraft(cx, claims = cx.state.claims) {
    try {
      await this.$app.$auth.getUserData();

      const app = this.$app;
      const { month, appData, userData } = gh.unpackSubscriptionData(app);

      if (gh.checkDraftLimit(appData, userData, month) && config.TIER_CHECKS) {
        gh.showSubscribePrompt(cx);
        return;
      }

      if (gh.notLoading(cx)) {
        const res = await gh.sendRequestToAlice(cx, claims);
        await gh.unpackResponse(res, claims, cx);
      }

      cx.commit('saveGeneratedDraft');

      app.$gtag.event(ACTION_GENERATE_PASS, { event_category: CATEGORY_GENERATE });

      await gh.updateAuth0Data(app.$auth, appData, userData, month);

      gh.logEvents(app, cx);
      gh.saveNewDocumentData(cx.commit);
    } catch (err) {
      gh.handleServerRequestError(err, this.$app.$gtag, cx.commit);
    }
  },

  /**
   * Navigate to the subscribe page
   * @param {ActionContext} arg1 - vuex context object
   * @param {Object?} routeParams - Optional params that will be mapped the subscribe page route.
   */
  async navigateToSubscribePage({ commit }, routeParams = {}) {
    const router = this.$app.$router;
    const id = this.$app.$auth.user?.sub;

    try {
      commit('hideLoadingPhrases');
      commit('setLoading', true);
      await router.push({ name: 'subscribe', params: { id, ...routeParams } });
    } catch (err) {
      commit('error/handleError', err, { root: true });
    } finally {
      commit('setLoading', false);
    }
  },

  /**
   * Handles the sequential load of the figures
   * @param {ActionContext} context - Vuex action context
   * @param {Object[]} d - Array of transfer data figures
   * @returns
   */
  loadDiagrams({ commit, state }, d) {
    const diagrams = [];
    let i = 0;
    return new Promise((resolve) => {
      const interval = setInterval(async () => {
        // This checks if the sequential load should be skipped or not
        if (state.sequentialSkip) {
          commit('setDiagrams', d);
          clearInterval(interval);
          resolve();
        } else if (state.nextFigure) {
          commit('dontLoadNextFigure');
          if (i >= d.length) {
            resolve();
            clearInterval(interval);
            commit('loadNextFigure');
          } else {
            diagrams.push(d[i]);
            commit('setDiagrams', diagrams);
            i++;
          }
        }
      }, 0);
    }).then(() =>
      setTimeout(() => {
        state.figureScrollInstance.scroll({ y: '100%' });
      }, 50)
    );
  },

  /**
   * handles loading the claims during the sequential load process.
   * @param {ActionContext} arg1 - Vuex context object
   * @param {String} claims - Claims to be inserted into editor
   * @returns
   */
  loadClaims({ commit }, claims) {
    commit('changeClaims', '');
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('changeClaims', claims);
        resolve();
      }, 10);
    });
  },

  /**
   * Handles sequential load of the specification
   * @param {ActionContext} arg1 - Vuex context object
   * @param {String} doc - Specification text
   * @returns
   */
  loadDescription({ commit, state }, doc) {
    commit('changeDescription', '');
    const para = doc.split('\n');

    return new Promise((resolve) => {
      let i = 0;
      const interval = setInterval(() => {
        if (state.sequentialSkip) {
          commit('setDescription', doc);
          clearInterval(interval);
          resolve();
        } else if (i === para.length) {
          resolve();
          clearInterval(interval);
          state.specScrollInstance.scroll({ y: '100%' });
        } else {
          const formatPara1 = formatPara(para[i]);
          commit('addParagraph', formatPara1);
          state.specScrollInstance.scroll({ y: '100%' });
          i++;
        }
      }, 50);
    }).then(() =>
      setTimeout(() => {
        state.specScrollInstance.scroll({ y: '100%' });
      }, 50)
    );
  },

  /**
   *
   * @param {ActionContext} context - Vuex action context
   * @param {Object?} arg1 - Optional object containing whether or not the
   * subscribe prompt should be shown and a message to display.
   */
  toggleSubscribePrompt(
    { state, commit },
    { show = !state.subscribePrompt, message = tl.DEFAULT_TEXT }
  ) {
    if (show) {
      commit('setSubscribePromptMessage', message);
      commit('showSubscribePrompt');
    } else {
      commit('hideSubscribePrompt');
    }
  },

  /**
   * Cancels the sequential load
   * @param {ActionContext} context - vuex context object
   */
  skipSequentialLoad({ commit }) {
    commit('setSequentialSkipTrue');
    commit('stopSequentialLoad');
  },

  /**
   * Checks if the user is subscribed or not. If the user is subscribed it will run the provided callback.
   * Otherwise it will display the subscribe prompt
   * @param {ActionContext} context - vuex context object
   * @param {Object} arg2
   * @param {Function} arg.cb - callback function to call after the free tier check
   * @param {String} arg.message - Message to display if the free tier check fails
   */
  freeTierCheck({ dispatch }, { cb, message = tl.DEFAULT_TEXT }) {
    if (this.$app.$auth.user.appData.subscribed || !config.TIER_CHECKS) {
      cb();
    } else {
      dispatch('toggleSubscribePrompt', { show: true, message });
    }
  },

  /**
   * Checks that the provided profile has up to date keys. If it does not it will
   * add the keys with default values and finally sets the profile as the active profile
   * @param {ActionContext} context - Vuex context object
   * @param {Object} profile - Profile object to set as the active profile
   */
  updateActiveProfile({ commit }, profile) {
    const hasTruncateText = 'truncateText' in profile;
    const hasVerboseSteps = 'verbose_steps' in profile;
    const hasHighlightTaggedText = 'highlight_tagged_text' in profile;
    const hasPhrases = 'phrases' in profile;
    const hasIndependentClaimsOnly = 'independent_claims_only' in profile;

    if (!hasTruncateText) {
      profile.truncateText = p.truncate_text_default;
    }

    if (!hasIndependentClaimsOnly) {
      profile.independent_claims_only = p.independent_claims_only_default;
    }

    if (!hasVerboseSteps) {
      profile.verbose_steps = true;
    }

    if (!hasHighlightTaggedText) {
      profile.highlight_tagged_text = false;
    }

    if (hasPhrases) {
      const phrases = profile.phrases;
      for (const key in phrases) {
        profile[key] = phrases[key];
      }
      delete profile.phrases;
    }

    commit('setActiveProfile', profile);
  },

  /**
   * encrypts and saves the users contents to session storage
   * @param {ActionContext} context - vuex context object
   */
  saveUserContent({ commit, state }) {
    const session = window.sessionStorage;
    if (state.claims.trim().length > 0 || state.step > 1) {
      const content = {
        claims: state.claims,
        furthestStep: state.furthestStep,
        step: state.step,
        figures: packDiagrams(state.figures),
        specification: state.specification,
        outlines: packOutline(state.outlines),
        savedOutlines: state.savedOutlines,
        savedClaims: state.savedClaims,
      };

      const jsonString = JSON.stringify(content);
      const cy = encrypt(jsonString);
      session.setItem(c.USER_CONTENT, cy);
    }
    commit('hideBrowserPrompt');
  },

  /**
   * gets user contents from session storage, decrypts it and saves it to state
   * @param {ActionContext} context - vuex context
   */
  getUserContent({ commit }) {
    const session = window.sessionStorage;
    const cy = session.getItem(c.USER_CONTENT);
    if (cy) {
      const content = decrypt(cy);
      const parsed = JSON.parse(content);

      if (parsed.step === 2) {
        commit('stopSequentialLoad');
      }
      commit('changeClaims', parsed.claims);
      commit('setDiagrams', unpackDiagrams(parsed.figures));
      commit('setOutlineData', parsed);
      commit('changeDescription', parsed.specification);
      commit('setStep', parsed.step);
      commit('setFurthestStep', parsed.furthestStep);
      commit('saveOutline', parsed.savedOutlines);
      commit('saveClaims', parsed.savedClaims);
      session.removeItem(c.USER_CONTENT);
    }
  },
};
