<!--
  A wrapper around the core app, providing peripheral functionality.
  Includes the top menu bar, the app overlay, the customization window, some prompts, the tutorial, etc.
-->

<template>
  <div
    @drag.prevent
    @dragenter.prevent="handleDragEnter"
    @dragover.prevent
    @drop.prevent="handleDrop"
    id="AppLayout"
  >
    <HeaderComponent @showConfigMenu="showConfigMenu = true" />
    <v-fade-transition>
      <ProfileOverlay v-if="showProfile" />
    </v-fade-transition>
    <Alerts />
    <UploadOverlay />
    <UserConfigsMenu :show-config-menu="showConfigMenu" @closeMenu="showConfigMenu = false" />
    <main class="main__default-view" v-if="!hideContent">
      <slot />
    </main>
    <LogoutPrompt />
    <SubscribePrompt />
    <v-overlay :value="mobileAlert" opacity=".75">
      <PromptCard
        video
        text="Oops, we do not yet support mobile devices. Please use a laptop or desktop computer instead."
        title="Mobile device"
        cancel-hidden
        okay-text="Log out"
        @okay="logout"
      />
    </v-overlay>
    <v-overlay :value="browserAlert" opacity=".75">
      <PromptCard
        :colorOkay="false"
        :text="`Looks like you may be using ${browser}. For the best experience, please consider using <a href='https://www.google.com/chrome/' target='_blank' class='blue--text'>Chrome</a>.`"
        cancel-text="Don't show again"
        @okay="hideBrowserAlert"
        @cancel="dontShowAgain"
      />
    </v-overlay>
    <tutorial-component v-if="!hideContent" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { UploadOverlay } from '@/components/upload';
import { TutorialComponent } from '@/components/tutorial';
import { ProfileOverlay, Alerts, PromptCard, SubscribePrompt } from '@/components/global';
import { COMPANY_LEGAL_NAME, COMPANY_SUPPORT_EMAIL } from '@/data/constants/constantsMessages';
import LogoutPrompt from '@/components/global/LogoutPrompt';
import { ACTION_DROP_UPLOAD } from '@/data/constants/constantsGoogleAnalyticsEvents';
import UserConfigsMenu from '@/components/UserConfigMenu/UserConfigsMenu';
import HeaderComponent from '@/components/header';

// noinspection JSUnresolvedFunction
export default {
  name: 'AppLayout',

  components: {
    UserConfigsMenu,
    LogoutPrompt,
    PromptCard,
    ProfileOverlay,
    TutorialComponent,
    UploadOverlay,
    Alerts,
    HeaderComponent,
    SubscribePrompt,
  },

  data() {
    return {
      show: false,
      dialog: false,
      COMPANY_LEGAL_NAME,
      COMPANY_SUPPORT_EMAIL,
      mobileAlert: false,
      hideContent: false,
      showConfigMenu: false,
      browserAlert: false,
      browser: '',
    };
  },

  async mounted() {
    window.addEventListener('unload', () => {
      localStorage.removeItem('graph-data');
    });

    // noinspection JSUnresolvedVariable
    const isOnMobile = this.checkForMobile(navigator.userAgent || navigator.vendor || window.opera);

    if (isOnMobile) {
      this.mobileAlert = true;
      this.hideContent = true;
    }

    const usingChrome = this.checkForChrome();

    if (!usingChrome && !isOnMobile) {
      const doNotShow = !!localStorage.getItem('doNotShowBrowserAlert');
      if (!doNotShow) {
        this.browserAlert = true;
        this.hideContent = true;
      }
    }
  },

  computed: {
    ...mapState({
      showProfile: (state) => state.draft.showProfileOverlay,
    }),
  },

  methods: {
    ...mapMutations({
      setUploadState: 'upload/setUploadState',
      saveFile: 'upload/saveFile',
      setError: 'error/setErrorState',
      setLoading: 'draft/setLoading',
      hideLoadingPhrases: 'draft/hideLoadingPhrases',
    }),

    dontShowAgain() {
      const local = window.localStorage;
      local.setItem('doNotShowBrowserAlert', 'true');
      this.browserAlert = false;
      this.hideContent = false;
    },

    hideBrowserAlert() {
      this.browserAlert = false;
      this.hideContent = false;
    },

    logout() {
      this.hideLoadingPhrases();
      this.mobileAlert = false;
      this.setLoading(true);

      try {
        this.$auth.logout();
      } catch (err) {
        console.error(err);
        this.setLoading(false);
        this.mobileAlert = true;
      }
    },

    /**
     * Check whether the user is using a mobile device.
     * @param {string} agent
     * @returns {boolean}
     */
    checkForMobile(agent) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          agent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br[ev]w|bumb|bw-[nu]|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do[cp]o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly[-_]|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-[mpt]|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c[- _agpst]|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac[ -/]|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja[tv]a|jbro|jemu|jigs|kddi|keji|kgt[ /]|klon|kpt |kwc-|kyo[ck]|le(no|xi)|lg( g|\/[klu]|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t[- ov]|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30[02]|n50[025]|n7(0[01]|10)|ne([cm]-|on|tf|wf|wg|wt)|nok[6i]|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan[adt]|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c[-01]|47|mc|nd|ri)|sgh-|shar|sie[-m]|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel[im]|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c[- ]|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          agent.substring(0, 4)
        )
      );
    },

    checkForChrome() {
      const userAgent = navigator.userAgent;

      let usingChrome = userAgent.indexOf('Chrome') > -1;

      if (!usingChrome) {
        if (userAgent.indexOf('Safari') > -1) this.browser = 'Safari';
      }

      if (userAgent.indexOf('Firefox') > -1) {
        this.browser = 'Firefox';
        usingChrome = false;
      }

      if (userAgent.indexOf('Edg') > -1) {
        this.browser = 'Microsoft Edge';
        usingChrome = false;
      }

      // noinspection JSUnresolvedVariable,JSUnresolvedFunction
      if (navigator.brave !== undefined && navigator.brave.isBrave()) {
        this.browser = 'Brave';
        usingChrome = false;
      }

      if (navigator.userAgent.match(/Opera|OPR\//)) {
        this.browser = 'Opera';
        usingChrome = false;
      }

      return usingChrome;
    },

    handleDragEnter(e) {
      const draggingFile = e.dataTransfer.types[0] === 'Files';
      if (draggingFile) {
        this.setUploadState({ key: 'drop', val: true });
      }
    },

    handleDrop(e) {
      this.show = false;
      const file = e.dataTransfer.files[0];

      if (file) {
        if (
          file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          this.saveFile({ e, action: ACTION_DROP_UPLOAD });
        } else {
          const startsWithVowel = /^[aieouAIEOU].*/;
          this.setUploadState({ key: 'drop', val: false });
          this.setError({
            key: 'error',
            val: true,
            message: `You can only use Word files, the file you provided was ${
              startsWithVowel.test(file.type) ? 'an' : 'a'
            } ${file.type.replace(/\/[a-z]*/, '')} file.`,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';
.main__default-view {
  min-height: 100vh;
}
</style>
