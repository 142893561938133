/**
 * Vuetify and its basic configurations.
 *
 * vuetify is a component library for Vue2 and they are working on adding support
 * for Vue3. Currently, they are the main reason we have not migrated to Vue3.
 *
 * Any elements you see in the component templates that are prepende with `v-`
 * are vuetify components. Some common ones:
 * 1. `<v-overlay>`
 * 2. `<v-snackbar>`
 * 3. `<v-text-input>`
 * 4. `<v-snackbar>`
 *
 * keep in mind that Vue supports element attributes like `v-bind`, `v-model`,
 * `v-for`, and `v-if` if it is a attribute this is provided vue not vuetify. E.G.:
 * 1. `<div v-for="item in items"/>`
 * 2. `<input v-model="text" />`
 * 3. `<v-snackbar v-model="showSnackbar" />` - This is a vuetify component that is using the vue `v-model` attribute
 *
 * Vuetify docs: https://vuetifyjs.com/en/
 */

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e16c6c',
        accent: '#fc6666',
        secondary: '#83D1FE',
        lightGray: '#E5E5E5',
        darkBackground: '#4E4E4E',
      },
    },
  },
});
