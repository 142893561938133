<!--
  Root app component and the ancestor of all other components in the app.
  Set global components and layouts here.
-->

<template>
  <Scrollable class-name="app" ref="rootScroll">
    <v-app class="myFont">
      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
    </v-app>
  </Scrollable>
</template>

<script>
import Scrollable from '@/components/global/Scrollable';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'App',

  components: {
    Scrollable,
  },

  data() {
    return { refresh: null };
  },

  metaInfo: {
    title: 'Draft',
    titleTemplate: 'PatentPal %s',
  },

  async mounted() {
    if (localStorage.getItem('access_token')) {
      this.refreshAuth(true);
    }

    const content = !!window.sessionStorage.getItem('userContent');
    if (content) {
      this.getUserContent(); // noinspection JSCheckFunctionSignatures
      this.setShowWorkspace(true);
    }

    const scrollInstance = this.$refs.rootScroll.$refs.osRef.osInstance();

    this.setRootScrollInstance(scrollInstance);
  },

  beforeDestroy() {
    clearInterval(this.refresh);
  },

  computed: {
    ...mapState('draft', ['showWorkspace']),
  },

  methods: {
    ...mapMutations('draft', ['setRootScrollInstance', 'setShowWorkspace']),
    ...mapActions('draft', ['getUserContent']),
    refreshAuth(load = false) {
      if (window) {
        this.$auth.refresh(load);
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import './scss/variables';

//#region Global styles

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  overflow-y: auto !important;
}

html,
body {
  background: $light-gray;
}

.app {
  min-height: 100vh;
}

//#endregion

//#region Utility styles

// Remove rounding on top corners. TODO: Rename to remove-rounding-on-top-corners.
.top-corners {
  border-radius: 0 0 4px 4px !important;
}

// Removes the gap to the left of a component so that it would align with the browser's left edge. TODO: Rename this to remove-left-offset or align-left.
.remove-left-margin {
  left: 0 !important;
}

// Removes the gap to the right of a component so that it would align with a browser's right edge
.remove-right-offset {
  right: 0 !important;
  left: initial !important; // Reset left since some Vuetify components are positioned relative to their left property
}

.my-transition {
  &-enter-active,
  &-enter,
  &-enter-to {
    opacity: 1 !important;
  }

  &-enter-active {
    transition: opacity 0.3s !important;
  }

  &-leave,
  &-leave-to {
    transition: opacity 0.5s !important;
  }

  &-enter,
  &-leave-to {
    opacity: 0 !important;
  }
}

//#region Text
.italic {
  font-style: italic;
}

.bold {
  font-weight: bold !important;
  letter-spacing: normal !important;
}

.black-text {
  color: $black;
}

.primary-text {
  color: $primary !important;
}

.dark-text {
  color: $app-text-color;
}

.soft-text {
  color: $soft-text-color !important;
}

.light-text {
  color: $light-text-color;
}

// Text color inside text editors
.panel-text {
  color: $panel-text-color;
}

// Greyed-out text, e.g. on some buttons
.disabled-text {
  color: $disabled-text-color;
}
//#endregion

//#endregion

//#region PatentPal components

// Modifies shadow styling for drop-down menu items. Shifts the shadow down so that the menu doesn't pop out as much.
.patentpal-menu {
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%) !important;
}

// Menu tooltips. TODO: Create a tooltip component and add this class to that
.patentpal-tooltip {
  background-color: $dark-gray !important;
  opacity: 1 !important;
  transition: none !important;
}

// Safari's scrollbar. TODO: Check if this is safe to remove.
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

// figLabel is programmatically generated by DiagramEditor.vue and added as a global component
.figLabel {
  display: inline-block;
  box-sizing: border-box;
  line-height: 22px;
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid $focus;
  }
}

//#endregion

//#region Third-party components

//#region mxGraph

// Hide the error message window in an mxGraph panel
div.mxWindow {
  display: none !important;
}

//#region Context menu

body#pp-body > div.mxPopupMenu {
  border: none !important;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%) !important;
  border-radius: 4px;
  padding: 8px 0 !important;

  tr {
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  td.mxPopupMenuItem {
    height: 48px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    padding-left: 16px;
  }
}

body div.mxPopupMenu {
  -webkit-box-shadow: 3px 3px 6px #c0c0c0;
  -moz-box-shadow: 3px 3px 6px #c0c0c0;
  box-shadow: 3px 3px 6px #c0c0c0;
  background: white;
  position: absolute !important;
  border: 3px solid #e7e7e7;
  padding: 3px !important;
  display: block;
}

body table.mxPopupMenu {
  border-collapse: collapse;
  margin: 0;
}

body tr.mxPopupMenuItem {
  color: black;
  cursor: default;
}

body td.mxPopupMenuItem {
  padding: 3px 30px 3px 10px;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  &.mxDisabled {
    color: $soft-text-color;
  }
}

body td.mxPopupMenuIcon {
  background-color: white;
  padding: 0;
}

body tr.mxPopupMenuItemHover {
  background-color: #eeeeee;
  color: black;
}

table.mxPopupMenu hr {
  border-top: solid 1px #cccccc;
}

table.mxPopupMenu tr {
  font-size: 4pt;
}

//#endregion

// Selection box when a user clicks and drags their mouse
div.mxRubberband {
  position: absolute;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #0000ff;
  background: #0cddff;
}

// Text editors in a figure
.mxCellEditor.mxPlainTextEditor {
  display: inline-block;
  border: 3px solid $focus;
  padding-top: 0;
}

// Hide tooltips on hover
.mxTooltip {
  display: none !important;
}

//#endregion

//#region Vuetify

//#region v-app overrides see docs: https://vuetifyjs.com/en/components/application/

// v-application is class for the v-app component which is the root wrapper around the app that applies styles to all vuetify components. See docs: https://vuetifyjs.com/en/components/application/
.v-application {
  //font-family: 'Open Sans', sans-serif !important;
  ul {
    padding-left: 0 !important;
  }
}

// Applies to the v-app component when theme is light
.theme--light.v-application {
  background-color: $light-gray !important;
}

//#endregion

// This contains vuetify overrides, But it's specific to the user customization window. So you may be able to move this into the userConfigsMenu.vue component
.configMenu {
  // v-snack is the class for the vuetify snackbar component which we use to display contextual messages to the user
  .v-snack {
    top: 20px !important;
  }

  // Wrapper around the snackbar component
  .v-snack__wrapper {
    transition: none !important;
  }

  // Action area of the snackbar. action area is the vue slot for adding buttons to the snackbar. We use it to add the x button. see docs: https://vuetifyjs.com/en/api/v-snackbar/#slots
  .v-snack__action {
    margin: 0 !important;
  }

  // Text field component with the outlined class
  .v-text-field--outlined {
    fieldset {
      border: none !important;
      transition-property: border-width !important;
    }

    &.v-input--is-focused {
      fieldset {
        border: none !important;
      }
    }
    .v-label {
      top: 6px !important;
    }

    .v-label--active {
      transform: translateY(-14px) scale(0.75) !important;
    }
  }

  // This is overriding the minimum height of the inputs in the config menu. Normally vuetify input min height is 40px
  //noinspection CssMissingComma
  .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control,
  > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  // Adds blue highlight to inputs on hover removes transition
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: white;
    transition: none !important;
    &:hover {
      background: $highlight !important;
    }
  }

  // Adds blue highlight to inputs on focus
  .theme--light.v-text-field--filled.v-input--is-focused > .v-input__control > .v-input__slot {
    background: $highlight !important;
  }
}

//#region - overlay component

// This is referring to the content of any overlay component
.v-overlay__content {
  max-width: 90%;
}

// Preventing mouse events on all overlays This was to fix a bug that was causing the upload icon to flash in and our when dragging and drop files
.prevent.v-overlay__content,
.prevent * {
  pointer-events: none !important;
}

//#endregion

//#region v-stepper overrides - Applies to the progress bar above the workspace

// v-step refers to a step in the v-stepper component, which is what we use to build the progress bar above the workspace
.v-step {
  border-radius: 4px !important;
  background-color: #fff !important;
  color: $dark-gray !important;
  text-align: left !important;
  padding: 0 !important;
  .v-step__arrow {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
}

// The content of any vuetify tooltip component
.v-tooltip__content {
  pointer-events: auto !important;
  transition: opacity 0.5s;

  p {
    color: $dark-gray !important;
    padding: 0 35px;
    margin-bottom: 30px !important;
  }
  &.menuable__content__active {
    &.custom-tooltip {
      transition: opacity 0.5s;
      opacity: 1 !important;
    }
  }

  &.custom-tooltip {
    div {
      display: flex;
      flex-direction: column;
      align-content: end;
      height: 100%;
      width: 100%;
    }
    background-color: $white !important;
    border-radius: 4px !important;
    padding: 0;
    pointer-events: auto !important;
    overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
    z-index: 20;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  }

  &.top-arrow {
    &::before {
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      border-bottom: solid 8px $white;
      transform: translateX(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      bottom: 100%;
      left: 50%;
      height: 0;
      width: 0;
    }
  }

  &.left-arrow {
    &::before {
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-right: solid 8px $white;
      transform: translateY(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 50%;
      right: 100%;
      height: 0;
      width: 0;
    }
  }

  &.right-arrow {
    &::before {
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-left: solid 8px $white;
      transform: translateY(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 50%;
      left: 100%;
      height: 0;
      width: 0;
    }
  }

  &.bottom-arrow {
    &::before {
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      border-top: solid 8px $white;
      transform: translateX(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 100%;
      left: 50%;
      height: 0;
      width: 0;
    }
  }

  &.left-edge {
    &::before {
      left: 6% !important;
    }
  }

  &.right-edge {
    &::before {
      left: 80% !important;
    }
  }
}

// v-stepper is the progress bar at the top of the workspace
.v-stepper__step--inactive {
  .v-stepper__step__step {
    background-color: #bdbdbd !important;
  }
}
// This targets the icon inside of the progress bar steps
.v-stepper__step__step {
  width: 40px !important;
  height: 40px !important;
  font-size: 18px !important;
}

// This is ensuring the steps inside of the progress bar do not having a pointer cursor
.v-stepper__step {
  cursor: default !important;
}

// This styles a completed step in the progress bar. Mainly applies to step 1 element when the user is on step 2
.v-stepper__step--complete {
  .stepper__step__step.primary {
    background-color: $soft-gray !important;
  }
}

// This targets the text inside of the progress bar steps
.v-stepper__label {
  font-size: 16px !important;
}

//#endregion

//#region menu overrides

// ensuring the content of a list with dark theme has a dark background. Used because some of our drop down menus had a light background when it should have been dark
.theme--dark.v-list,
.v-menu__content {
  background-color: $dark-background !important;
}
.v-menu__content.theme--light {
  background: white !important;
}

//#endregion

//#region v-tab was used when we had the outline editor. I don't believe these styles are being used anymore
// this applies to the bar that contains the tabs
.v-tabs-bar {
  background-color: $light-gray !important;
}

// applies to all individual tabs
.v-tab {
  text-transform: initial !important;
  height: 30px !important;
}

// applies to the contain element of the tabs
.v-tabs {
  height: 30px !important;
}

// This applies to the underline that normally appears below the tabs, we are hiding it.
.v-tabs-slider {
  display: none !important;
}

// active style of active tab
.v-tab--active {
  color: black !important;
}

//#endregion

//#region v-btn overrides v-btn is the vuetify button component

// Applies to all vuetify button components
.v-btn {
  text-transform: none !important;
  font-weight: normal !important;
  font-size: 17px !important;
  letter-spacing: normal !important;
  &.suggested {
    font-weight: bold !important;
  }
  &.v-btn--icon {
    text-indent: 0;
  }
}

//#region input overrides

// Custom style that overrides v-input styles in the profile list of the customization window
.profile-list-input {
  .v-input__slot:before {
    border: none !important;
  }
  .v-input__slot:after {
    border: none !important;
  }
}

// Style overrides for checkbox componenets
.v-input--checkbox {
  label.v-label {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
  }
}

//#endregion

// Drop-down menus with a light theme, e.g. state and country selection on the subscribe page

//#endregion

//#region Quill

/*
  Highlighted spans of text, e.g. when hovering over phrases in the customization window.
  This class is dynamically generated by TextEditor.vue.
*/
.highlight-spans {
  #quill-description {
    span {
      background-color: $highlight;
    }
  }
}

// Text formatting toolbar that shows up when a user selects a span of text
.ql-tooltip {
  position: absolute;
  transition: visibility 0.2s, opacity 0.2s;
  z-index: 3;
}

// Hidden text formatting toolbar
.ql-tooltip.ql-hidden {
  display: initial !important;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  visibility: hidden;
}

// Text editors
#quill-claims,
#quill-description,
#quill-preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100% !important;
  border: none !important;
  .ql-editor {
    padding: 20px !important;
    flex-grow: 1;
    min-height: 100%;
    width: 100%;
    transition: height 0.3s, width 0.3s;
    //noinspection CssInvalidPropertyValue
    overflow-y: overlay;
    p,
    li,
    h1,
    h2,
    h3 {
      margin-bottom: 16px;
      transition: opacity 50ms;
    }
    ul,
    ol {
      padding-left: 0.5em;
    }

    h1,
    h2,
    h3 {
      font-size: inherit;
      margin-top: 32px;
      &:first-child {
        margin-top: 0;
      }
    }

    h1 {
      font-weight: bold;
    }

    h2 {
      font-weight: normal;
    }

    h1,
    h2 {
      text-align: center;
      text-transform: uppercase;
    }

    h3 {
      font-style: italic;
      font-weight: normal;
    }

    &::before {
      font-style: normal;
      font-weight: normal;
      color: $soft-gray;
      padding-left: 5px !important;
      background-color: white;
    }

    &:focus {
      &::before {
        display: none;
      }
    }

    .hidden {
      opacity: 0;
    }
  }
}

// Spec preview in the customization window
#quill-preview {
  .ql-editor {
    padding: 15px 20px 0 20px !important;
    position: relative;
  }
}

// Quill's internal container that wraps around its text editor
.ql-container {
  font-family: 'Times New Roman', Times, serif, Arial, sans-serif !important;
}

//#endregion

//#endregion

//#endregion
</style>
