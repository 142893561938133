<!--
  Generic 404 Error Page

  If a user for any reason visits a page that does not exist, they would arrive at this page.
-->

<template>
  <v-container class="container">
    <h1>404 Not found</h1>
    <p>Uh oh! This page does not exist. Click the link below to return to the application.</p>
    <v-btn color="primary" to="/">Back to application</v-btn>
  </v-container>
</template>

<script>
import 'vue-meta';
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404',
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
  display: flex;
  max-width: 500px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 50px;
  }
  p {
    margin-bottom: 100px;
  }
}
</style>
