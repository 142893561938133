<!-- 
  This is the text editor for our claims and specification panels
-->

<template>
  <VueEditor
    :id="id"
    :value="value"
    @input="$emit('input', $event)"
    @text-change="(current, old, source) => $emit('textChange', { current, old, source })"
    @ready="(q) => (this.quill = q)"
    :editorOptions="editorSettings"
    :class="editorClass"
    @focus="handleFocus"
    @blur="handleBlur"
    ref="editor"
    :disabled="disabled"
  />
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  ACTION_CLICK,
  ACTION_EDIT,
  ACTION_ENTER,
  CATEGORY_GENERATE_TRIGGER,
} from '@/data/constants/constantsGoogleAnalyticsEvents';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

// Custom clipboard plugin for overriding the default behavior of paste
class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const temp = e.clipboardData.getData('text/plain').trim();
    const split = temp.split('\n');
    let text = '';
    split.forEach((span, i) => {
      if (span.trim().length > 0) {
        // Trim whitespace except for tabs from start and end of strings
        text += `${span.replace(/(?<!\w|;|\.|!|\?|,)[^\S\t]/gi, '').trimEnd()}${
          i < split.length - 1 ? '\n' : ''
        }`;
      }
    });

    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;

    this.quill.updateContents(delta);
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

let Inline = Quill.import('blots/inline');
class LinkBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('class', value);
    return node;
  }

  static formats(node) {
    return node.getAttribute('class');
  }
}
LinkBlot.blotName = 'span';
LinkBlot.tagName = 'span';

Quill.register(LinkBlot);
Quill.register('modules/clipboard', PlainClipboard, true);

export default {
  name: 'TextEditor',
  components: { VueEditor },
  props: {
    value: String,
    id: String,
    className: String,
    event_category: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    // sets debug messages to error, suppresses unnecessary warnings
    Quill.debug('error');

    // Quill does not always blur when expected so we have to add a custom listener.
    this.quill.root.addEventListener('blur', () => this.quill.blur());
  },

  data() {
    return {
      editorSettings: {
        theme: 'bubble',
        startVal: '',
        placeholder: '',
        modules: {
          toolbar: false,
          clipboard: {
            matchVisual: false,
          },
          keyboard: {
            bindings: {
              generate: {
                key: 13,
                shortKey: true,
                handler: async () => {
                  if (this.id === 'quill-claims') {
                    this.$gtag.event(ACTION_ENTER, { event_category: CATEGORY_GENERATE_TRIGGER });
                    this.startSequentialLoad();
                    await this.generateDraft();
                    this.stopSequentialLoad();
                  }
                },
              },
              handleEnter: {
                key: 13,
                shortKey: false,
                handler(e) {
                  const quill = this.quill;
                  const sel = quill.getSelection();
                  if (sel) {
                    const { index } = sel;
                    const bounds = quill.getBounds(index);
                    const scroll = e.instance.scroll();
                    const state = e.instance.getState();
                    const yPos = scroll.position.y;
                    const height = state.viewportSize.height;
                    const { bottom, top } = bounds;
                    const currentScrollPos = yPos + height;
                    if (currentScrollPos < bottom) {
                      const newPos = bottom - currentScrollPos + yPos;
                      e.instance.scroll(newPos + 20);
                    } else if (top < yPos) {
                      e.instance.scroll(top - 20);
                    }
                  }
                },
              },
            },
          },
        },
        formats: ['indent', 'header', 'bold', 'span'],
      },
      quill: null,
    };
  },

  computed: {
    ...mapState('draft', { highlightSpans: (state) => state.activeProfile.highlight_tagged_text }),

    editorClass() {
      let classes = '';

      if (this.className) {
        classes += `${this.className} `;
      }

      if (this.highlightSpans) {
        classes += 'highlight-spans';
      }

      return classes;
    },
  },

  methods: {
    ...mapMutations('draft', ['startSequentialLoad', 'stopSequentialLoad']),
    ...mapActions('draft', ['generateDraft']),

    handleFocus() {
      const { event_category } = this;
      this.$gtag.event(ACTION_CLICK, { event_category });
      this.startVal = this.value;
      this.$emit('focus');
    },

    handleBlur() {
      const { event_category } = this;
      if (this.startVal !== this.value) {
        const startLength = this.startVal.length;
        const endLength = this.value.length;
        const event_label =
          startLength > endLength
            ? `Removed ${startLength - endLength} chars. Total length is ${endLength}.`
            : `Added ${endLength - startLength} chars. Total length is ${endLength}.`;
        this.$gtag.event(ACTION_EDIT, { event_category, event_label: event_label });
      }
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss">
.description {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background-color: white;
  &:hover {
    cursor: text;
  }
  .input--focus {
    background-color: $highlight;
  }
  .input--hover {
    background-color: $highlight;
  }
}
</style>
