/**
 * This file contains the code for exporting DOCX files
 */

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'save-as';
import { HTML_TAGS, HTML_END_TAGS } from '@/data/constants';
import { fileDate, titleCase } from '@/support/utilities';

const MIME_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const GENERATE_TYPE = 'blob';

/**
 * Export a word document from the provided specification
 * @param {String} specification
 */
export function exportWordFile(specification) {
  loadFile(`./patentTemplateV3.docx`, function (error, content) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });

    const spans = specification.split(HTML_END_TAGS);
    const data = packDocument(spans);

    doc.render(data);

    const out = doc.getZip().generate({
      type: GENERATE_TYPE,
      mimeType: MIME_TYPE,
    });

    saveAs(out, `${fileDate()} - ${titleCase(data.title)} - Spec.docx`);
  });
}

/**
 * Reads the binary content of a file and passes the provided callback function
 * @param {String} path - Path to file
 * @param {Function} callback - Function to run after the file has been read
 */
function loadFile(path, callback) {
  PizZipUtils.getBinaryContent(path, callback);
}

/**
 * Format document into object to pass to DOCX library.
 * @param {String[]} spans - Array of string representing the specification
 * @returns {Object}
 */
function packDocument(spans) {
  return spans.reduce(
    (data, span, index) => {
      if (span.includes('h1') && !data.hasClaims) {
        data.title = span.replace(HTML_TAGS, '');
      }

      if (span.includes('<h2>') && span.includes('claims')) {
        data.hasClaims = true;
      }

      if (span.includes('<h2>') && !data.hasClaims) {
        const section = {
          heading: span.replace(HTML_TAGS, '').toUpperCase(),
          paragraphs: packParagraphs(spans, index),
        };
        data.docSections.push(section);
      }

      if (span.replace(HTML_TAGS, '').match(/^\s*\(?\d+.?\)?/)) {
        const claim = {
          claim: span.replace(HTML_TAGS, '').replace(/\s*\(?\d.?\)?/, ''),
          children: packClaimChildren(spans, index),
        };

        data.claims.push(claim);
      }

      return data;
    },
    { docSections: [], title: '', claims: [], hasClaims: false }
  );
}

/**
 * Pack section paragraphs into an array of objects
 * @param {String[]} spans - Array of strings representing paragraphs in specification
 * @param {Number} start - Index to start at in array
 * @returns
 */
function packParagraphs(spans, start) {
  const paragraphs = [];
  for (let i = start + 1, l = spans.length; i < l; i++) {
    const span = spans[i];
    if (span.includes('<h2>')) {
      break;
    }
    if (span.trim().length > 0) paragraphs.push({ text: span.replace(HTML_TAGS, '') });
  }
  return paragraphs;
}

/**
 * Packs the claims section into the document if it is present
 * @param {String[]} spans - Array of paragraphs in the specification
 * @param {Number} start - Index to start at
 * @returns
 */
function packClaimChildren(spans, start) {
  const children = [];
  for (let i = start + 1, l = spans.length; i < l; i++) {
    const span = spans[i];
    if (span.replace(HTML_TAGS, '').match(/^\s*\(?\d+.?\)?/) || span.includes('<h2>')) {
      break;
    }
    if (span.trim().length > 0) children.push({ text: span.replace(HTML_TAGS, '') });
  }
  return children;
}
