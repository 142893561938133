import { postDocToS3 } from '@/support/alice';

export default {
  async uploadFile({ state, commit }) {
    commit('startUpload');
    return await postDocToS3(state.file, (res) => {
      const progress = Math.round((res.loaded / res.total) * 100);
      commit('setUploadState', { key: 'progress', val: progress });
    });
  },
};
