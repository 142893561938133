<!-- 
  Tooltip component, used to render tooltips throughout the app. Has some special
  funtionality like keeping tooltips visible while they are being hovered.
-->

<template>
  <v-tooltip
    :bottom="bottom"
    :left="left"
    :right="right"
    :top="top"
    v-model="show"
    max-width="300px"
    :content-class="tooltipClass"
    transition="my-transition"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <v-btn light icon @click="hideTooltip" class="align-self-end mt-1 mr-1" small
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <p>{{ message }}</p>
    </div>
  </v-tooltip>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'Tooltip',
  props: {
    message: String,
    value: Boolean,
    id: String,
    leftEdge: {
      type: Boolean,
      default: false,
    },
    rightEdge: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovering: false,
      interval: null,
    };
  },

  computed: {
    ...mapState({ tooltip: (state) => state.tooltip.tooltip }),

    /**
     * @returns {string}
     */
    tooltipClass() {
      let str = 'custom-tooltip';

      if (this.top) str += ' bottom-arrow';
      if (this.bottom) str += ' top-arrow';
      if (this.right) str += ' left-arrow';
      if (this.left) str += ' right-arrow';
      if (this.leftEdge) str += ' left-edge';
      if (this.rightEdge) str += ' right-edge';

      return str;
    },

    show: {
      /**
       * @returns {boolean}
       */
      get() {
        return this.tooltip === this.id;
      },

      set(val) {
        if (val) {
          this.showTooltip(this.id);
        } else {
          this.hideTooltip();
        }
      },
    },
  },

  methods: {
    ...mapMutations('tooltip', [
      'showTooltip',
      'hideTooltip',
      'setHoveringTooltip',
      'clearHoveringTooltip',
    ]),
    ...mapActions('tooltip', ['toggleTooltip']),

    mouseLeave() {
      this.clearHoveringTooltip();
    },

    mouseEnter() {
      this.setHoveringTooltip(this.id);
      if (!this.show) {
        this.toggleTooltip(this.id);
      }
    },
  },
};
</script>

<style scoped></style>
