/**
 * Constants used in the figure editor and figure exports.
 *
 * TODO: Group related constants into code blocks and add block comments.
 */

// Figure units
export const FONT_SIZE = 13.3333333; // Pixels, converts to 10 pt font
export const FIG_FONT_SIZE = 32; // Pixels, converts to 24 pt font
export const UNIT_SIZE = 12; // A grid unit on the diagram canvas is 12px by 12px
export const VISIO_UNIT_SIZE = 0.125; // 1 unit in Visio is 1/8th of an inch

// Dimensions
export const PANEL_WIDTH = 68;
export const PANEL_HEIGHT = 88;
export const PANEL_WIDTH_PX = PANEL_WIDTH * UNIT_SIZE; // Pixels
export const PANEL_HEIGHT_PX = PANEL_HEIGHT * UNIT_SIZE; // Pixels
export const CANVAS_WIDTH = 50;
export const CANVAS_HEIGHT = 70;
export const PAGE_TOP_PADDING = 8;
export const PAGE_LEFT_PADDING = 9;

// Figure label
export const FIG_TAG_START = `<span class='figLabel'>`;
export const FIG_TAG_END = `</span>`;
export const FIG_STYLE = `fontColor=black;fontStyle=3;fontSize=${FIG_FONT_SIZE};strokeColor=none;fill=none;editable=1;autosize=1;movable=0;selectable=0;resizable=0`;
export const FIG_WIDTH = 9;
export const FIG_HEIGHT = 3;
export const FIG_PADDING = 3; // Space between the bottom of a canvas and the top of a figure label
export const FIG_X = (PAGE_LEFT_PADDING + CANVAS_WIDTH / 2 - FIG_WIDTH / 2) * UNIT_SIZE;
export const FIG_Y = (PAGE_TOP_PADDING + CANVAS_HEIGHT + FIG_PADDING) * UNIT_SIZE;

// mxGraph styles for blocks
export const BLOCK_PARENT_STYLE =
  'whiteSpace=wrap;align=left;verticalAlign=top;overflow=hidden;editable=0;movable=0;selectable=0;resizable=0';
export const BLOCK_CHILD_STYLE =
  'whiteSpace=wrap;overflow=hidden;editable=0;movable=0;selectable=0;resizable=0';
export const BLOCK_PILL_STYLE =
  'whiteSpace=wrap;overflow=hidden;rounded=1;editable=0;movable=0;selectable=0;resizable=0';
export const BLOCK_IMG_STYLE = `editable=0;movable=0;selectable=0;resizable=0;shape=image;imageAlign=center;aspect=fixed`;
export const CALLOUT_BLOCK_BOTTOM_LEFT =
  'shape=callout;direction=0;editable=0;align=right;selectable=1;resizable=1;fill=none';
export const CALLOUT_BLOCK_BOTTOM_RIGHT =
  'shape=callout;direction=1;editable=0;align=left;selectable=1;resizable=1;fill=none';
export const CALLOUT_BLOCK_TOP_LEFT =
  'shape=callout;direction=2;editable=0;align=right;selectable=1;resizable=1;fill=none';
export const CALLOUT_BLOCK_TOP_RIGHT =
  'shape=callout;direction=3;editable=0;align=left;selectable=1;resizable=1;fill=none';
export const CALLOUT_BLOCK_NO_CALLOUT =
  'shape=callout;direction=5;editable=0;align=right;selectable=1;resizable=1;fill=none';

// Default figure editor styles
export const DEFAULT_STROKE_COLOR = '#000000';
export const DEFAULT_FILL_COLOR = '#ffffff';
export const DEFAULT_FONT_COLOR = '#000000';
export const DEFAULT_STROKE_WIDTH = 1;
export const DEFAULT_FONT_FAMILY = 'Times New Roman, Arial, sans-serif';
export const DEFAULT_LABEL_PADDING = 3;
export const DEFAULT_EDGE_CURVE = 0; // Curvature of lines in mxGraph. 0 represents a 90-degree right angle.

// mxGraph styles for line arrows
export const STYLE_UNDIRECTED = 'endArrow=none;startArrow=none;';
export const STYLE_BIDIRECTED = 'endArrow=classic;startArrow=classic;';
export const STYLE_OUTBOUND = 'endArrow=classic;startArrow=none';
export const STYLE_INBOUND = 'endArrow=none;startArrow=classic';

// Line types
export const UNDIRECTED = 'UNDIRECTED';
export const BIDIRECTED = 'BIDIRECTED';
export const OUTBOUND = 'OUTBOUND';
export const INBOUND = 'INBOUND';

// Block shapes
export const SHAPE_RECTANGLE = 'RECTANGLE';
export const SHAPE_PILL = 'PILL';
export const SHAPE_CALLOUT = 'CALLOUT';
