<!-- 
  This component uses the Overlay Scrollbars library to implement custom scrollbar UI

  Vue plugin for overlay scrollbars: https://www.npmjs.com/package/overlayscrollbars-vue
  Overlay scrollbars docs: https://kingsora.github.io/OverlayScrollbars/#!overview
-->

<template>
  <overlay-scrollbars
    :class="`custom-scroll ${className}`"
    ref="osRef"
    :options="{
      scrollbars: { autoHide, clickScrolling: true },
      overflowBehavior: {
        x: overflowX,
        y: overflowY,
      },
      resize: 'none',
      sizeAutoCapable: false,
      callbacks: {
        onOverflowAmountChanged: (e) =>
          $emit('overflowAmountChanged', { e, instance: $refs.osRef.osInstance() }),
      },
    }"
  >
    <slot />
  </overlay-scrollbars>
</template>

<script>
export default {
  name: 'Scrollable',
  props: {
    autoHide: {
      type: String,
      default: 'never',
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    overflowX: {
      type: String,
      required: false,
      default: 'scroll',
    },
    overflowY: {
      type: String,
      required: false,
      default: 'scroll',
    },
  },

  methods: {
    handleOverflow() {},
  },
};
</script>

<style lang="scss">
.custom-scroll {
  .os-scrollbar-handle {
    background-color: $scroll-bar-color !important;
    border-radius: 100px !important;
  }

  .panel {
    .os-scrollbar-handle {
      background-color: $panel-scroll-handle-color !important;
    }
  }

  .os-scrollbar-vertical {
    width: 16px;
    padding: 5px;
  }

  .os-scrollbar-horizontal {
    height: 16px;
    padding: 5px;
  }
}

.workspace {
  .os-content {
    display: flex !important;
    //justify-content: center !important;
    align-content: center !important;
  }

  .panel {
    .os-content {
      display: block !important;
    }
  }
}
</style>
