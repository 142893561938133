/*
Mutations are synchronous functions that directly modify vuex state. They can be
used inside of vue components or called by an Action to modify state.

See: https://vuex.vuejs.org/guide/mutations.html

*/

import { Outline } from '@/data/Outline';
import { packOutline, unpackers } from '@/support/utilities';
import { figureConstants as dc } from '@/data/constants';

export default {
  setStep(state, step) {
    state.step = step;
  },

  startViewingTutorial(state) {
    state.viewingTutorial = true;
  },

  stopViewingTutorial(state) {
    state.viewingTutorial = false;
  },

  setSubscribePromptMessage(state, message) {
    state.subscribePromptMessage = message;
  },

  showTutorialCloseButtons(state) {
    state.tutorialCloseButtons = true;
  },

  hideTutorialCloseButtons(state) {
    state.tutorialCloseButtons = false;
  },

  showSubscribePrompt(state) {
    state.subscribePrompt = true;
  },

  hideSubscribePrompt(state) {
    state.subscribePrompt = false;
  },

  startInserting(state) {
    state.insertingDocData = true;
  },

  stopInserting(state) {
    state.insertingDocData = false;
  },

  hideBrowserPrompt(state) {
    state.browserPrompt = false;
  },

  showBrowserPrompt(state) {
    state.showBrowserPrompt = true;
  },

  setRootScrollInstance(state, scrollInstance) {
    state.rootScroll = scrollInstance;
  },

  setFigureScrollInstance(state, scrollInstance) {
    state.figureScrollInstance = scrollInstance;
  },

  setSpecScrollInstance(state, scrollInstance) {
    state.specScrollInstance = scrollInstance;
  },

  startSequentialLoad(state) {
    state.sequential = true;
  },

  stopSequentialLoad(state) {
    state.sequential = false;
  },

  startLoading(state) {
    state.loading = true;
  },

  stopLoading(state) {
    state.loading = false;
  },

  setSequentialSkipTrue(state) {
    state.sequentialSkip = true;
  },

  setSequentialSkipFalse(state) {
    state.sequentialSkip = false;
  },

  loadNextFigure(state) {
    state.nextFigure = true;
  },

  dontLoadNextFigure(state) {
    state.nextFigure = false;
  },

  setShowWorkspace(state, val) {
    state.showWorkspace = val;
  },

  showLoadingPhrases(state) {
    state.loadingPhrases = true;
  },

  hideLoadingPhrases(state) {
    state.loadingPhrases = false;
  },

  showLogoutPrompt(state) {
    state.showLogoutPrompt = true;
  },

  hideLogoutPrompt(state) {
    state.showLogoutPrompt = false;
  },

  alterActiveProfile(state, { key, val }) {
    state.activeProfile[key] = val;
  },

  setActiveProfile(state, val) {
    state.activeProfile = val;
  },

  toggleTruncateText(state) {
    state.activeProfile.truncateText = !state.activeProfile.truncateText;
  },

  saveClaims(state, val = state.claims) {
    state.savedClaims = val;
  },

  saveOutline(state, val = JSON.stringify(packOutline(state.outlines))) {
    state.savedOutlines = val;
  },

  setFurthestStep(state, val) {
    state.furthestStep = val;
  },

  incrementStep(state) {
    if (state.step < 2) state.step++;
    if (state.step > state.furthestStep) {
      state.furthestStep = state.step;
    }
  },

  decrementStep(state) {
    if (state.step > 1) state.step--;
  },

  setLoading(state, val) {
    state.loading = val;
  },

  setDiagrams(state, val) {
    state.figures = val;
  },

  setClaims(state, doc) {
    doc = typeof doc === 'string' ? doc.split('\n') : doc;
    state.claims = doc.reduce((string, span) => {
      string += `<p>${span}</p>`;
      return string;
    }, '');
  },

  setDescription(state, doc) {
    state.specification = unpackers.unpackDescription(doc);
  },

  changeDescription(state, val) {
    state.specification = val;
  },

  changeClaims(state, val) {
    state.claims = val;
  },

  resetState(state) {
    if (!state.uploading) state.step = 1; // conditionally reset to step 1
    state.loading = false;
    state.figures = [];
    state.outlines = [
      new Outline({ tags: [], concepts: [], points: [{ text: '', children: [] }] }),
    ];
    state.claims = '';
    state.specification = '';
    state.savedClaims = null;
    state.savedOutlines = null;
    state.furthestStep = 1;
  },

  updateDiagramBlock(state, { block, index, number }) {
    const figure = state.figures[index];
    const oldNumber = figure.number;
    const startsWithNumber = new RegExp(`^${oldNumber}`);
    if (number) figure.number = number;

    state.figures.forEach((f) => {
      f.blocks.forEach((b) => {
        if (b.id === block.id) {
          b.update(block);
        }
        if (
          number &&
          startsWithNumber.test(b.label) &&
          b.label.replace(/[a-z]/gi, '').length === oldNumber.toString().length + 2
        ) {
          b.updateNumber(parseInt(number).toString(), !b.shape === dc.SHAPE_CALLOUT);
        }
      });
    });
  },

  addNewLink(state, { link, index }) {
    const diagram = state.figures[index];
    const existingLink = diagram.links.find((l) => l.id === link.id);
    if (!existingLink) {
      diagram.createNewLink(link);
    }
  },

  addNewBlock(state, { block, index }) {
    const diagram = state.figures[index];
    diagram.createNewBlock(block);
  },

  removeLink(state, { id, index }) {
    state.figures[index].removeLink(id);
  },

  removeBlock(state, { id, index }) {
    state.figures[index].removeBlock(id);
  },

  toggleProfileOverlay(state, val) {
    state.showProfileOverlay = val;
  },

  setOutlineData(state, val) {
    state.outlines = val.outlines.map((o) => {
      return new Outline(o);
    });
  },

  saveGeneratedDraft(state) {
    if (state.step < 2) state.step++;
    if (state.step > state.furthestStep) state.furthestStep = state.step;
    state.loading = false;
    state.insertingDocData = false;
  },

  addParagraph(state, val) {
    state.specification += val;
  },

  // ! --- These mutations are for the outline editor. They are not currently used ---

  // deleteOutline(state, val) {
  //   if (state.outlines.length > 1) {
  //     const index = state.outlines.findIndex((outline) => outline.id === val);
  //     state.outlines.splice(index, 1);
  //   }
  // },

  // addNewOutline(state) {
  //   const newOutline = new Outline({
  //     tags: [],
  //     concepts: [],
  //     points: [{ text: '', children: [] }],
  //   });

  //   state.outlines.push(newOutline);
  // },

  // clearOutlines(state) {
  //   const newOutline = new Outline({
  //     tags: [],
  //     concepts: [],
  //     points: [{ text: '', children: [] }],
  //   });

  //   state.outlines = [newOutline];
  // },

  // addNewConcept(state, { index, val }) {
  //   state.outlines[index].concepts.push(val);
  // },

  // removeConcept(state, { index, val }) {
  //   state.outlines[index].concepts = state.outlines[index].concepts.filter(
  //     (concept) => concept.id !== val
  //   );
  // },

  // ! --- End outline editor mutations ---
};
