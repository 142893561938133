<!-- 
  This represents a single credit card in the users list of saved cards in the
  subscribe page.
-->

<template>
  <button
    type="button"
    :class="`pp-card-root${this.active ? ' pp-card--active' : ''}`"
    @click="$emit('clicked', { e: $event, paymentMethod })"
  >
    <div class="pp-card--content">
      <v-img
        v-if="image !== false"
        :src="image"
        max-width="50"
        max-height="30"
        contain
        position="left center"
      ></v-img>
      <v-icon size="50" v-else>mdi-credit-card</v-icon>
      <span class="pp-card-text --default black-text italic" v-if="paymentMethod.isDefault"
        >Default</span
      >
      <v-spacer></v-spacer>
      <span class="pp-card-text --card-number black-text">**** {{ paymentMethod.card.last4 }}</span>
    </div>
    <v-fade-transition>
      <div class="pp-card--details" v-if="showDetails">
        <span class="pp-card-text black-text">{{ paymentMethod.billing_details.name }}</span>
        <br />
        <span class="pp-card-text black-text">{{
          paymentMethod.billing_details.address.line1
        }}</span>
        <!-- <br v-if="paymentMethod.billing_details.address.line2" /> -->
        <span class="pp-card-text black-text" v-if="paymentMethod.billing_details.address.line2"
          >, {{ paymentMethod.billing_details.address.line2 }}</span
        >
        <br />
        <span class="pp-card-text black-text">
          {{ paymentMethod.billing_details.address.city }},
          {{ paymentMethod.billing_details.address.state }},
          {{ paymentMethod.billing_details.address.postal_code }},
          {{ paymentMethod.billing_details.address.country }}
        </span>
      </div>
    </v-fade-transition>
  </button>
</template>

<script>
import visa from '@/data/static/visa.svg';
import discover from '@/data/static/discovercard.svg';
import jcb from '@/data/static/jcb.svg';
import mastercard from '@/data/static/Mastercard_2019_logo.svg';
import unionpay from '@/data/static/UnionPay_logo.svg';
import amex from '@/data/static/amex_logo.svg';

export default {
  name: 'CardBlock',
  props: {
    paymentMethod: Object,
    selectedCard: Object,
  },
  data() {
    return {
      showDetails: false,
    };
  },

  computed: {
    /**
     * @returns {boolean}
     */
    active() {
      return this.selectedCard?.id === this.paymentMethod.id;
    },

    /**
     * @returns {String|boolean}
     */
    image() {
      if (this.paymentMethod.card.brand === 'amex') {
        return amex;
      }

      if (
        this.paymentMethod.card.brand === 'diners_club' ||
        this.paymentMethod.card.brand === 'discover'
      ) {
        return discover;
      }

      if (this.paymentMethod.card.brand === 'visa') {
        return visa;
      }

      if (this.paymentMethod.card.brand === 'jcb') {
        return jcb;
      }

      if (this.paymentMethod.card.brand === 'mastercard') {
        return mastercard;
      }

      if (this.paymentMethod.card.brand === 'unionpay') {
        return unionpay;
      }
      return false;
    },
  },

  watch: {
    active: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.showDetails = val;
          }, 300);
        } else {
          this.showDetails = val;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pp-card-root {
  background: $white;
  width: 380px;
  height: 70px;
  border-radius: 4px;
  max-width: 100%;
  padding: 18px 28px;
  border: 2px solid transparent;
  transition: border 0.3s, height 0.3s, margin 0.3s;
  margin: 0 0 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover,
  &.pp-card--active {
    border: 2px solid $focus;
  }

  &.pp-card--active {
    height: 140px;
  }

  .pp-card--content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30px;
  }

  .pp-card--details {
    font-size: 12px;
    text-align: left;
    height: 50px;
  }

  .pp-card-text {
    &.--card-number {
      font-size: 18px;
      justify-self: end;
    }

    &.--default {
      font-size: 14px;
      justify-self: start;
      margin-left: 18px;
    }
  }
}
</style>
