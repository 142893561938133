<!-- 
  Password input used on the login and signup pages. Needed to make a separate
  component because the password input has a bit more functionality than a basic
  text input
-->

<template>
  <v-text-field
    v-model="text"
    :label="GEN_PASSWORD"
    :solo="solo"
    :height="height"
    :type="inputType"
    :append-icon="icon"
    :color="color"
    :rules="validate ? [required, validatePassword] : [required]"
    @click:append="hidePassword = !hidePassword"
    @keyup.enter.prevent="$emit('pressedEnter', $event)"
  />
</template>

<script>
import {
  ERROR_PASSWORD_LENGTH,
  ERROR_PASSWORD_LOWER,
  ERROR_PASSWORD_NUMBER,
  ERROR_PASSWORD_UPPER,
  GEN_PASSWORD,
} from '@/data/constants/constantsMessages';

export default {
  name: 'PasswordInput',
  props: {
    value: String,
    validate: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'default',
    },
    height: {
      type: String,
      default: '50px',
    },
    solo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      GEN_PASSWORD,
      hidePassword: true,
      rules: {
        required: (value) => !!value || 'Required',
        password: (value) => {
          return this.validatePassword(value);
        },
      },
    };
  },

  computed: {
    text: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },

    inputType() {
      return this.hidePassword ? 'password' : 'text';
    },

    icon() {
      return this.hidePassword ? 'mdi-eye-off' : 'mdi-eye';
    },
  },

  methods: {
    required(value) {
      return !!value || 'Required';
    },

    validatePassword(password) {
      const lowercaseRegex = /(?=.*[a-z])/;
      const uppercaseRegex = /(?=.*[A-Z])/;
      const numberRegex = /(?=.*[0-9])/;

      if (!lowercaseRegex.test(password)) {
        return ERROR_PASSWORD_LOWER;
      }

      if (!uppercaseRegex.test(password)) {
        return ERROR_PASSWORD_UPPER;
      }

      if (!numberRegex.test(password)) {
        return ERROR_PASSWORD_NUMBER;
      }

      if (password.length < 8) {
        return ERROR_PASSWORD_LENGTH;
      }

      return true;
    },
  },
};
</script>

<style scoped></style>
