import { TOOLTIP_DURATION_MS } from '@/data/constants/constantsTooltip';

export default {
  toggleTooltip({ state, commit }, id) {
    commit('showTooltip', id);
    const interval = setInterval(() => {
      if (state.hoveringTooltip !== id) {
        commit('hideTooltip');
        clearInterval(interval);
      }
    }, TOOLTIP_DURATION_MS);
  },
};
