<!--
  This page is used for Auth0 authentication and redirecting the user.

  When a user logs in, they are given an authentication token and taken to this page.
  This page checks the token and redirects the user to an appropriate subsequent page.
-->

<template>
  <div></div>
</template>

<script>
import { ACTION_LOGIN } from '@/data/constants/constantsGoogleAnalyticsEvents';
import { mapActions } from 'vuex';

export default {
  async mounted() {
    try {
      const res = await this.$auth.handleAuthentication();
      if (res) {
        try {
          await this.$auth.refresh(true);
        } catch (err) {
          console.error(err);
        } finally {
          this.$gtag.event(ACTION_LOGIN);
          const signedUp = window.localStorage.getItem('signedUp');
          if (signedUp) {
            window.localStorage.removeItem('signedUp');
            this.navigateToSubscribePage({ login: 'true' });
          } else {
            await this.$router.replace('/');
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    ...mapActions('draft', ['navigateToSubscribePage']),
  },
};
</script>
