<template>
  <PortalLayout
    title="Sign up"
    :loading="loading"
    :error="error.value"
    :errorMessage="error.message"
    :login="false"
    @submit="createNewUser"
    @closeError="error.value = false"
  >
    <div class="name-container">
      <v-text-field
        solo
        v-model="inputs.firstName"
        :label="FORM_FIRST"
        color="blue lighten-3"
        height="50px"
        :rules="[rules.required]"
        @keyup.enter.prevent="createNewUser"
      ></v-text-field>
      <v-text-field
        solo
        :label="FORM_LAST"
        color="blue lighten-3"
        height="50px"
        v-model="inputs.lastName"
        @keyup.enter.prevent="createNewUser"
        :rules="[rules.required]"
      />
    </div>
    <v-text-field
      solo
      v-model="inputs.email"
      :label="FORM_EMAIL"
      type="email"
      height="50px"
      @keyup.enter.prevent="createNewUser"
      :rules="[rules.required, rules.email]"
    />
    <PasswordInput v-model="inputs.password" @pressedEnter="createNewUser" />
  </PortalLayout>
</template>

<script>
import { PortalLayout } from '../layouts';
import { EMAIL_RE } from '@/data/constants';
import {
  ERROR_EMAIL_EXISTS,
  FORM_GOOGLE,
  FORM_LINKEDIN,
  FORM_RETURN_USER,
  FORM_LAST,
  FORM_FIRST,
  FORM_EMAIL,
  GEN_LOG_IN,
  GEN_SIGN_UP,
  PRIVACY_LINK,
  PRIVACY_TEXT,
  GEN_PASSWORD,
} from '@/data/constants/constantsMessages';
import PasswordInput from '@/components/signup/PasswordInput';
import { ACTION_SIGNUP } from '@/data/constants/constantsGoogleAnalyticsEvents';

export default {
  name: 'SignupView',
  components: {
    PasswordInput,
    PortalLayout,
  },
  data() {
    return {
      loading: false,
      rememberEmail: true,
      hidePassword: true,
      error: {
        value: false,
        message: '',
      },
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      rules: {
        required: (value) => !!value || 'Required',
        email: (value) => {
          return this.validateEmail(value);
        },
      },

      GEN_SIGN_UP,
      FORM_LINKEDIN,
      FORM_GOOGLE,
      FORM_EMAIL,
      FORM_RETURN_USER,
      FORM_FIRST,
      FORM_LAST,
      GEN_LOG_IN,
      PRIVACY_TEXT,
      PRIVACY_LINK,
      GEN_PASSWORD,
    };
  },
  metaInfo: {
    title: 'Sign Up',
  },

  mounted() {
    if (process.env.VUE_APP_LOGIN_DOMAIN === 'patentpal.us.auth0.com') {
      this.$auth.universalLogin();
    }
  },

  computed: {
    showAlert() {
      return process.env.VUE_APP_ENV !== 'production';
    },
  },

  methods: {
    async createNewUser() {
      const {
        inputs: { firstName, lastName, email, password },
      } = this;
      try {
        const storage = window.localStorage;
        if (this.rememberEmail) {
          storage.setItem('rememberedEmail', this.inputs.email);
          storage.setItem('rememberMe', 'true');
        } else {
          storage.removeItem('rememberedEmail');
          storage.setItem('rememberMe', 'false');
        }
        this.loading = true;
        const name = `${firstName} ${lastName}`;
        await this.$auth.signup(email, password, name, firstName, async (err) => {
          if (err) {
            console.error(err);
            this.loading = false;
            this.error.value = true;
            err.code === 'public signup is disabled'
              ? (this.error.message = `${err.description
                  .charAt(0)
                  .toUpperCase()}${err.description.substr(1)}.`)
              : (this.error.message = ERROR_EMAIL_EXISTS);
          } else {
            const localStorage = window.localStorage;
            localStorage.setItem('signedUp', 'true');
            this.$gtag.event(ACTION_SIGNUP);
            await this.$auth.login(email, password);
          }
        });
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    validateEmail(email) {
      return EMAIL_RE.test(email) || 'Invalid e-mail';
    },
  },
};
</script>
