<!-- 
  Originally used to ask users if they wanted to view the tutorial, not currently being used
-->

<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-fade-transition hide-on-leave>
      <PromptCard
        v-if="!secondCard"
        text="Looks like you're new here. Would you like to run through a tutorial?"
        cancelText="No thanks"
        okayText="Start tutorial"
        suggested
        @cancel="optOutOfTutorial"
        @okay="startTutorial"
      />
    </v-fade-transition>
  </v-dialog>
</template>

<script>
import { PromptCard } from '@/components/global';
import { mapMutations } from 'vuex';
import {
  ACTION_TUTORIAL_OPT_IN,
  ACTION_TUTORIAL_OPT_OUT,
  CATEGORY_TUTORIAL,
} from '@/data/constants/constantsGoogleAnalyticsEvents';
import { MENU_TOOLTIP_ID } from '@/data/constants/constantsTooltip';
export default {
  name: 'TutorialPrompt',
  components: { PromptCard },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      secondCard: false,
    };
  },
  methods: {
    ...mapMutations({
      toggleTooltip: 'tooltip/toggleTooltip',
    }),
    startTutorial() {
      this.$gtag.event(ACTION_TUTORIAL_OPT_IN, { event_category: CATEGORY_TUTORIAL });
      this.$emit('closePrompt');
      this.$auth.patchUserMetadata({ tutorial: true });
      if (this.$route.name !== 'home') {
        this.$router.push(
          '/',
          setTimeout(() => {
            this.$tours.homeTour.start();
          }, 500)
        );
      } else {
        this.$tours.homeTour.start();
      }
    },

    optOutOfTutorial() {
      this.$gtag.event(ACTION_TUTORIAL_OPT_OUT, { event_category: CATEGORY_TUTORIAL });
      this.$emit('closePrompt');
      this.$auth.patchUserMetadata({ tutorial: true });
      this.toggleTooltip(MENU_TOOLTIP_ID);
    },
  },
};
</script>
