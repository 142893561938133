import store from '@/vuex';

// eslint-disable-next-line no-unused-vars
import Figure from '@/data/Figure/Figure';

// eslint-disable-next-line no-unused-vars
import Block from '@/data/Figure/Block';

const placeholder = 'PLACEHOLDER';
const scrollSpeedInMS = 500;
const charCodeStart = 97;

// region custom types

/**
 * An overflow group represents the start and end indices in the array of figures
 * that are all part of the same figure, overflow figures will have figure numbers
 * like `1a`, `1b`, and `1c`
 * @typedef {Object} OverflowGroup
 * @property {Number} start - Start index of the overflow group
 * @property {Number} end - end index of the overflow group
 */

/**
 * An update chain is the start and end indices of all the figures that need to be
 * updated to avoid conflicts with the new figure number.
 *
 * E.G.
 * 1. Figures: `1`, `2`, `3`, `5`
 * 2. Change: `1` => `2`
 * 3. Conflict: This change conflicts with the figure `2` that already exits
 * 4. Solution: Increment figure `2` to `3` and all following figure until there are no more conflicts
 * 5. Figures to increment: `2`, `3`. we know that figure `3` is the end of the update chain because incrementing `3` => `4` does not conflict with any other figure numbers
 * 6. Figure `2` and `3` are in the update chain.
 * @typedef {Object} UpdateChain
 * @property {Number} start - start index of the update chain
 * @property {Number} end - end index of the update chain
 */

/**
 * {@link Fig} contains information about the {@link Figure} who's number was updated
 * @typedef {Object} Fig
 * @property {String} number - the new figure number
 * @property {String} oldNumber - The old figure number
 * @property {Boolean} overflow - Whether or not this is an overflow figure
 * @property {String} id - UUID of figure
 * @property {Number} oldIndex - Figures index before number change
 */

/**
 * This denotes if there is a conflict and where the conflict is in the array of figures
 * @typedef {Object} ConflictData
 * @property {Boolean} conflict - Whether or not there is a conflict in the array of figures
 * @property {Number} index - Where the conflict is. Will be -1 if no conflict present
 */

/**
 * This contains an {@link OverflowGroup} and its {@link UpdateChain}
 * @typedef {Object} OverflowData
 * @property {OverflowGroup?} overflowGroup
 * @property {UpdateChain?} overflowUpdateChain
 */

/**
 * This is the specification text used in the specification text editor and the
 * preview in the customization window
 * @typedef {string} SpecString
 */

// end region

/**
 * Updates figure numbers in spec and any other figures in response to a figure
 * number change by the user.
 * @param {Fig} fig - Figure that was changed
 * @return {Promise<String>} The updated specification
 */
export async function updateFigureNumbers(fig) {
  const figs = _getFigures();
  let spec = _getSpec();

  const overflow = _checkIfOverflow(fig);
  const { conflict, index } = _checkForConflicts(fig, figs);
  const { overflowGroup, overflowUpdateChain } = _checkForOverflow(fig, figs, overflow);

  spec = _fixOverflowConflicts(overflowGroup, overflowUpdateChain, figs, fig, spec);
  spec = conflict ? _fixConflict(figs, fig, index, spec, overflowGroup) : _updateLabels(fig, spec);
  spec = overflow ? _removeOverflowPlaceholders(overflowGroup, figs, spec) : spec;

  await _sortFigs(figs);

  const newIndex = figs.findIndex((f) => f.id === fig.id);

  if (fig.oldIndex !== newIndex) {
    _scrollToNewPosition(fig);
  }

  return spec;
}

/**
 * After adding an illustration to the upload we need to update the wording of
 * figure descriptions in the specification to avoid phrases like `is an
 * illustration illustrating`.
 * @param {String} figNumber
 * @returns {SpecString} Updated specification
 */
export function changeBlockDiagramToIllustration(figNumber) {
  let spec = _getSpec();
  const blockReg = _createBlockDiagramRegex(figNumber);
  const blockThatReg = _createBlockBlockDiagramThatRegex(figNumber);
  const blockOverflowReg = _createBlockDiagramOverflowRegex(figNumber);
  spec = spec
    .replace(blockReg, 'illustrates')
    .replace(blockThatReg, '')
    .replace(blockOverflowReg, 'further illustrates');

  return spec;
}

// --- Internal functions ---

/**
 * If there are no conflicts in the figures we can safely update figure labels
 * in the space without needing to use placeholders
 * @param {Fig} fig
 * @param {SpecString} spec
 * @returns
 */
function _updateLabels(fig, spec) {
  spec = _replacePartLabels({ fig, spec });
  spec = _replaceFigLabels({ fig, spec });
  return spec;
}

/**
 * If the new figure number will conflict with another figure number we need to
 * fix the conflicts before we replace the old figure number with the new
 * figure number in the spec
 *
 * This is done by inserting placeholders anywhere the old figure number is
 * referenced.
 *
 * We then loop through the figures incrementing any figure numbers that would
 * conflict with the new figure number
 *
 * Finally we replace all of the temporary placeholders with the new figure number
 * @param {Figure[]} figs
 * @param {Fig} fig
 * @param {Number} index
 * @param {SpecString} spec
 * @param {OverflowGroup} overflowGroup
 * @returns {SpecString}
 */
function _fixConflict(figs, fig, index, spec, overflowGroup) {
  spec = _insertPlaceholders(spec, fig);
  spec = _fixConflictsInFigures(figs, fig, index, spec, overflowGroup);
  spec = _removePlaceholders(spec, fig);
  return spec;
}

/**
 * @param {OverflowGroup} group
 * @param {Figure[]} figs
 * @param {SpecString} spec
 * @returns {SpecString}
 */
function _removeOverflowPlaceholders(group, figs, spec) {
  for (let i = group.start, l = group.end; i <= l; i++) {
    const currentFigure = figs[i];
    const oldVal = _createOverflowFigPlaceholder(i);
    spec = _removePlaceholders(spec, currentFigure, oldVal);
  }

  return spec;
}

/**
 * If a figure is repositioned in the list of figures this will scroll to its new position
 * @param {Fig} fig
 */
function _scrollToNewPosition(fig) {
  const editorRef = _getEditorRef();
  const $fig = document.getElementById(fig.id);

  editorRef.scroll($fig, scrollSpeedInMS);
}

/**
 * If a figure is part of an overflow group we need to update the number of all
 * the figures that belong to that overflow group: e.g. figures 1a and 1b should
 * become 2a and 2b
 * @param {Fig} fig
 * @param {Figure[]} figs
 * @param {Boolean} overflow
 * @returns {OverflowData}
 */
function _checkForOverflow(fig, figs, overflow) {
  if (overflow) {
    const overflowGroup = _findOverFlowGroup(fig, figs);
    const { conflict, index } = _checkForConflicts(fig, figs, overflow);

    const overflowUpdateChain = conflict ? _findUpdateChain(figs, fig, index, overflow) : null;

    return { overflowGroup, overflowUpdateChain };
  }

  return { overflowGroup: null, overflowUpdateChain: null };
}

/**
 * This will ensure there are no two figures in an overflow group with the same label:
 * e.g. two figures with `1a`
 * @param {OverflowGroup} overflowGroup
 * @param {UpdateChain} updateChain
 * @param {Figure[]} figs
 * @param {Fig} fig
 * @param {SpecString} spec
 * @returns {SpecString}
 */
function _fixOverflowConflicts(overflowGroup, updateChain, figs, fig, spec) {
  for (let i = overflowGroup?.end, l = overflowGroup?.start; i >= l; i--) {
    const curFig = figs[i];
    const letter = _getFigureLetter(curFig.number);
    const newNumber = parseInt(fig.number);

    if (curFig.id !== fig.id) {
      const newVal = _createOverflowFigPlaceholder(i);
      spec = _insertPlaceholders(spec, { oldNumber: curFig.number }, newVal);

      if (updateChain?.start <= i && i <= updateChain?.end) {
        _incrementFigureLetter(curFig, letter);
      }

      _updateFigure(curFig, newNumber);
    }
  }

  return spec;
}

/**
 * We have to dynamically create overflow figure placeholders because we need to
 * be able to distinguish between `1a` and `1b`.
 *
 * If we used a static placeholder then both `1a` and `1b` would become
 * `PLACEHOLDER` in the spec which would cause errors when we replace the
 * placeholder later.
 *
 * With this method we can create a 2 different placeholders for figures `1a` and
 * `1b` so that we make sure we replace the correct values later.
 * @param {Number} index
 * @returns {String}
 */
function _createOverflowFigPlaceholder(index) {
  const p = `${placeholder}${String.fromCharCode(index + charCodeStart)}`;
  return p.toUpperCase();
}

/**
 * There is a conflict if 2 or more figures have the same figure number and they
 * are not part of the same overflow group
 * @param {Object} fig
 * @param {Object[]} figs
 * @param {Boolean} [overflow=false]
 * @returns {ConflictData}
 */
function _checkForConflicts(fig, figs, overflow = false) {
  for (let i = 0, l = figs.length; i < l; i++) {
    const currentFig = figs[i];
    const currentInt = parseInt(currentFig.number);
    const focusedInt = parseInt(fig.number);
    const oldInt = parseInt(fig.oldNumber);
    const letter = _getFigureLetter(currentFig.number);
    const focusedLetter = _getFigureLetter(fig.number);

    if (overflow && currentInt === oldInt && letter === focusedLetter && currentFig.id !== fig.id) {
      // There is a conflict in overflow figures if they both have the same letter in their figure number.
      return { conflict: true, index: i };
    }
    if (
      !overflow &&
      currentInt === focusedInt &&
      currentFig.id !== fig.id &&
      currentInt !== oldInt
    ) {
      // There is a conflict if 2 different figures have the same figure number
      return { conflict: true, index: i };
    }
  }

  // No conflict detected
  return { conflict: false, index: -1 };
}

/**
 * I created this method in case we decided to flesh out how we check for overflow
 * later. We could simply write `if(fig.overflow)` but creating this function
 * makes it easier to flesh out these checks later.
 * @param {Fig} fig
 * @returns {Boolean}
 */
function _checkIfOverflow(fig) {
  return fig.overflow;
}

/**
 * This increments the letter part of an overflow figure number. `1A` would become
 * `1B`
 *
 * A potential edge case with this would be if we were trying to increment the letter
 * of `1Z`. Right now it has not been a concern because overflow figures never reach
 * this point, but it is something to be aware of
 * @param {Fig} fig
 * @param {String} letter
 */
function _incrementFigureLetter(fig, letter) {
  const charCode = _getCharCode(letter) + 1;
  const newLetter = String.fromCharCode(charCode);
  const number = parseInt(fig.number);
  const newNumber = `${number}${newLetter}`;
  fig.number = newNumber.toUpperCase();
}

/**
 * We fix conflicts in figures by finding the {@link UpdateChain} and then incrementing
 * the figure number in all of the figures within the {@link UpdateChain}
 * @param {Figure[]} figs
 * @param {Fig} fig
 * @param {Number} index
 * @param {SpecString} spec
 * @param {Boolean} overflow
 * @returns {SpecString}
 */
function _fixConflictsInFigures(figs, fig, index, spec, overflow) {
  const updateChain = _findUpdateChain(figs, fig, index);

  return _incrementFigureNumbers(updateChain, figs, spec, fig.oldNumber, overflow);
}

/**
 *
 * @param {Object} args
 * @param {Fig} args.fig
 * @param {SpecString} args.spec
 * @param {Number|String} [args.oldVal] Optional, defaults to number parsed from figure number
 * @param {Number|String} [args.newVal] Optional, defaults to number parsed from old figure number
 * @returns {SpecString}
 */
function _replacePartLabels({
  fig,
  spec,
  oldVal = parseInt(fig.oldNumber),
  newVal = parseInt(fig.number),
}) {
  const regex = _createPartLabelRegex(oldVal);

  return spec.replace(regex, newVal);
}

function _replaceFigLabels({ fig, spec, oldVal = fig.oldNumber, newVal = fig.number }) {
  const regex = _createFigLabelRegex(oldVal);

  return spec.replace(regex, `$1 ${newVal}`);
}

/**
 *
 * @param {Figure} fig
 * @param {Figure[]} figs
 * @returns {OverflowGroup}
 */
function _findOverFlowGroup(fig, figs) {
  const index = fig.oldIndex;

  const start = _findOverflowStart({ fig, figs, start: index });
  const end = _findOverflowEnd({ fig, figs, start: index });

  return { start, end };
}

/**
 *
 * @param {String} number
 * @returns {String}
 */
function _getFigureLetter(number) {
  const char = number.charAt(number.length - 1);
  if (char.toLowerCase().charCodeAt(0) < charCodeStart) {
    return String.fromCharCode(charCodeStart - 1).toUpperCase();
  }
  return char;
}

/**
 *
 * @param {Figure[]} figs
 * @param {Fig} fig
 * @param {Number} index
 * @param {Boolean} overflow
 * @returns {UpdateChain}
 */
function _findUpdateChain(figs, fig, index, overflow = false) {
  const newCharCode = _getCharCode(fig.number);
  const oldCharCode = _getCharCode(fig.oldNumber);
  const newNumber = parseInt(fig.number);
  const oldNumber = parseInt(fig.oldNumber);

  let start = 0;
  let end = 0;

  if ((overflow && newCharCode < oldCharCode) || (!overflow && newNumber < oldNumber)) {
    start = index;
    end = _findEnd({ figs, start, end: fig.oldIndex - 1, overflow });
  }

  if ((overflow && newCharCode > oldCharCode) || (!overflow && newNumber > oldNumber)) {
    start = index;
    end = _findEnd({ figs, start, overflow });
  }

  return { start, end };
}

/**
 * Increments the value of all the figure numbers in an {@link UpdateChain} in reverse order.
 * @param {UpdateChain} updateChain
 * @param {Figure[]} figs
 * @param {SpecString} spec
 * @param {String} oldFigNumber
 * @param {Boolean} overflow
 * @returns {SpecString}
 */
function _incrementFigureNumbers(updateChain, figs, spec, oldFigNumber, overflow) {
  for (let i = updateChain.end, l = updateChain.start; i >= l; i--) {
    const currentFig = figs[i];
    const currentNum = currentFig.number;
    const inOverflow = i >= overflow?.start && i <= overflow?.end;
    if (currentNum !== oldFigNumber && !inOverflow) {
      const updatedFig = _updateFigure(currentFig);
      spec = _replacePartLabels({
        fig: { number: updatedFig.number, oldNumber: currentNum },
        spec,
      });
      spec = _replaceFigLabels({ fig: { number: updatedFig.number, oldNumber: currentNum }, spec });
    }
  }

  return spec;
}

/**
 * Sorts the figures in numerical and alphabetical order. This is done at the end
 * once we have finished updating all of the figure numbers
 *
 * @param {Figure[]} figs
 */
async function _sortFigs(figs) {
  figs.sort((a, b) => {
    const nameA = a.number.toUpperCase();
    const nameB = b.number.toUpperCase();

    const intA = parseInt(a.number);
    const intB = parseInt(b.number);

    if (intA === intB) {
      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }
      return 0;
    } else {
      return intA - intB;
    }
  });
}

/**
 * Finds the end of an overflow group. The end of an overflow group is the last figure
 * who's number is the same as the old number.
 *
 * The old number is the number of the updated figure before the user changed it.
 * @param {Object} args
 * @param {Fig} args.fig
 * @param {Figure[]} args.figs
 * @param {Number} [args.start]
 * @param {Number} [args.end]
 * @returns {Number}
 */
function _findOverflowEnd({ fig, figs, start = 0, end = figs.length - 1 }) {
  for (let i = start, l = end; i < l; i++) {
    const currentFig = figs[i];
    const nextFig = figs[i + 1];
    const number = parseInt(currentFig.number);
    const nextNumber = nextFig ? parseInt(nextFig.number) : null;
    const oldNumber = parseInt(fig.oldNumber);

    if (fig.id === currentFig?.id) {
      if (oldNumber !== nextNumber) {
        return i;
      }
    } else if (number !== nextNumber && nextFig?.id !== fig.id) {
      return i;
    }
  }

  return end;
}

/**
 * Finds the start of an overflow group. We need to update all overflow figure
 * labels when a user changes the figure number of an overflow figure.
 *
 * We have to find the start because the changed figure could be in the middle
 * of the overflow group.
 *
 * This differs from finding the update chain because the
 * start of an update chain is always the first figure that has a conflict
 * @param {Object} arg
 * @param {Fig} arg.fig
 * @param {Figure[]} arg.figs
 * @param {Number} arg.start
 * @param {Number} end
 * @returns {Number}
 */
function _findOverflowStart({ fig, figs, start = figs.length - 1, end = 0 }) {
  for (let i = start, l = end; i >= l; i--) {
    const currentFig = figs[i];
    const nextFig = figs[i - 1];
    const number = parseInt(currentFig.number);
    const nextNumber = nextFig ? parseInt(nextFig.number) : null;
    const oldNumber = parseInt(fig.oldNumber);

    if (fig.id === currentFig?.id) {
      if (oldNumber !== nextNumber) {
        return i;
      }
    } else if (number !== nextNumber && nextFig?.id !== fig.id) {
      return i;
    }
  }

  return 0;
}

/**
 * This finds the end of an update chain. The end of an update chain is the first
 * figure who's incremented value does not conflict with another figure.
 * @param {Object} args - arguments object
 * @param {Figure[]} args.figs - Array of figures
 * @param {Number} args.start - Start index
 * @param {Number} [args.end] - End index. Optional, defaults to the end of the figs array
 * @param {Boolean} [args.overflow] - Denotes if this is an overflow figure. Optional defaults to false
 * @returns
 */
function _findEnd({ figs, start, end = figs.length - 1, overflow = false }) {
  for (let i = start, l = end; i <= l; i++) {
    const currentFig = figs[i];
    const nextFig = figs[i + 1];
    const currentVal = overflow ? _getCharCode(currentFig.number) : parseInt(currentFig.number);
    const nextVal = nextFig
      ? overflow
        ? _getCharCode(nextFig.number)
        : parseInt(nextFig.number)
      : null;

    if (currentVal + 1 !== nextVal && currentVal !== nextVal) {
      return i;
    }
  }

  return end;
}

/**
 *
 * @param {Figure} fig
 * @param {String} newNumber
 * @returns
 */
function _updateFigure(fig, newNumber) {
  newNumber = newNumber ? newNumber : parseInt(fig.number) + 1;
  const num = parseInt(fig.number);
  fig.number = fig.number.replace(num, newNumber);

  _updateFigureBlocks(fig.blocks, num, newNumber);

  return fig;
}

/**
 *
 * @param {Block[]} blocks
 * @param {String} oldVal
 * @param {String} newVal
 */
function _updateFigureBlocks(blocks, oldVal, newVal) {
  blocks.forEach((block) => (block.label = block.label.replace(oldVal, newVal)));
}

/**
 * Inserts placeholders into all places where the old figure number is referenced.
 *
 * These will be replaced later with the new figure number
 * @param {SpecString} spec
 * @param {Fig} fig
 * @param {String} [newVal] - Optional, Defaults to {@link placeholder} text
 * @returns
 */
function _insertPlaceholders(spec, fig, newVal = placeholder) {
  spec = _replacePartLabels({ fig, spec, newVal });
  spec = _replaceFigLabels({ fig, spec, newVal });
  return spec;
}

/**
 * Removes placeholders from the {@link SpecString} and replaces them with the new
 * figure number
 * @param {SpecString} spec
 * @param {Fig} fig
 * @param {String} [oldVal] - Optional, defaults to {@link placeholder} text
 * @returns {SpecString}
 */
function _removePlaceholders(spec, fig, oldVal = placeholder) {
  spec = _replacePartLabels({ fig, spec, oldVal });
  spec = _replaceFigLabels({ fig, spec, oldVal });
  return spec;
}

/**
 * Creates the RegExp that matches the phrasing `{fig} is a block diagram illustrating
 * @param {String} val
 * @returns {RegExp}
 */
function _createBlockDiagramRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram illustrating`, 'gi');
}

/**
 * Creates RegExp that matches the phrasing `{fig} is a block diagram that`
 * @param {String} val
 * @returns {RegExp}
 */
function _createBlockBlockDiagramThatRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram that`, 'gi');
}

/**
 *
 * @param {String} val
 * @returns {RegExp}
 */
function _createBlockDiagramOverflowRegex(val) {
  return new RegExp(`(?<=fig(?:\\.|ure)? ${val}\\s)is a block diagram further illustrating`, 'gi');
}

/**
 *
 * @param {String} val
 * @returns {RegExp}
 */
function _createFigLabelRegex(val) {
  return new RegExp(`\\b(fig(?:\\.|ure)?)\\s?${val}\\b`, 'gi');
}

/**
 * Part labels are labels for elements in the figures. For example: system `110`.
 *
 * When updating the part labels we only need to update the number that corresponds
 * with the figure number. So in the case of `110` it would be the first `1` in the label.
 *
 * If we updated figure `1` to figure `2`, `110` would need to turn into `210`
 * @param {String} val
 * @returns {RegExp}
 */
function _createPartLabelRegex(val) {
  return new RegExp(`\\b${val}(?=\\d\\d[a-z]?\\b)`, 'gi');
}

/**
 * We increment the letter in a figure label by finding it's Unicode value and
 * incrementing by 1
 * @param {String} str
 * @param {Number} index
 * @returns {Number}
 */
function _getCharCode(str, index = str.length - 1) {
  const charCode = str.toLowerCase().charCodeAt(index);
  if (charCode < charCodeStart) {
    return charCodeStart - 1;
  }
  return charCode;
}

/**
 * gets the figures from the vuex draft state
 * @returns {Figure[]}
 */
function _getFigures() {
  return store.state.draft.figures;
}

/**
 * Get the specification from the vuex draft state
 * @returns {SpecString}
 */
function _getSpec() {
  return store.state.draft.specification;
}

/**
 * Gets the figure panel scroll instance from the vuex draft state.
 *
 * We use scroll instance to scroll to the new position of an updated figure
 * @returns {Object}
 */
function _getEditorRef() {
  return store.state.draft.figureScrollInstance;
}
