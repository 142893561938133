// noinspection JSUnresolvedVariable

/**
 * Router and its configurations
 *
 * See docs: https://router.vuejs.org/
 */

import VueRouter from 'vue-router';
import { AppView, ErrorView, SignupView, SubscribeView, CallbackView, LoginView } from '@/views';

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: AppView,
    },

    {
      path: '/signup',
      name: 'signup',
      component: SignupView,
      beforeEnter: (to, from, next) => {
        router.app.$auth.isAuthenticated() ? next('/') : next();
      },
    },

    {
      path: '/subscribe',
      name: 'subscribe',
      component: SubscribeView,
    },

    {
      path: '/login',
      name: 'login',
      component: LoginView,
      beforeEnter: (to, from, next) => {
        router.app.$auth.isAuthenticated() ? next('/') : next();
      },
    },

    {
      path: '/callback',
      name: 'callback',
      component: CallbackView,
    },

    { path: '*', name: '404', component: ErrorView },
  ],
});

/**
 * Make sure the user is logged in whenever they try to access a page in the app.
 */
router.beforeEach(async (to, from, next) => {
  if (to.name === 'callback') {
    next();
  } else if (
    !router.app.$auth.isAuthenticated() &&
    window &&
    to.name !== 'login' &&
    to.name !== 'signup'
  ) {
    await router.app.$auth.checkSession();
    if (!router.app.$auth.isAuthenticated()) {
      next('/login');
    }
  }
  next();
});

export default router;
