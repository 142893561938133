/**
 * Configuration variables based on environment variables.
 * We use these variables to set keys and API URLs for different product environments.
 */

module.exports = {
  S3_BUCKET_REGION: 'us-west-1',
  S3_BUCKET_NAME: process.env.VUE_APP_S3_BUCKET,
  AWS_ID_POOL: process.env.VUE_APP_AWS_ID_POOL,
  AUTH_CLIENT_ID: process.env.VUE_APP_AUTH_CLIENT_ID,
  STRIPE_PK: process.env.VUE_APP_STRIPE_PK,
  AUTH_DOMAIN: 'patentpal.us.auth0.com',
  LOGIN_DOMAIN: process.env.VUE_APP_LOGIN_DOMAIN,
  KEY: process.env.VUE_APP_SECRET_KEY, // TODO: RENAME TO SECRET_KEY?
  TIER_CHECKS: process.env.VUE_APP_TIER_CHECKS === 'true',
  DRAFT_LIMIT: parseInt(process.env.VUE_APP_DRAFT_LIMIT),

  MONTH_SUB:
    process.env.VUE_APP_ENV === 'production'
      ? 'price_1K7mXKJypE7JzcQGyJQgqEUo'
      : 'price_1J33FHJypE7JzcQGf6WzKxzA',

  YEAR_SUB:
    process.env.VUE_APP_ENV === 'production'
      ? 'price_1K7mXKJypE7JzcQGEh7d0HGj'
      : 'price_1J33EJJypE7JzcQGUVMrArdi',

  USER_MANAGEMENT_URL:
    process.env.VUE_APP_ENV === 'production'
      ? 'https://5ebu071qr5.execute-api.us-west-1.amazonaws.com/Prod'
      : process.env.VUE_APP_ENV === 'local'
      ? 'http://127.0.0.1:3000'
      : 'https://tg0e6q222e.execute-api.us-west-1.amazonaws.com/Prod',

  REALM:
    process.env.VUE_APP_ENV === 'production' ? 'Username-Password-Authentication' : 'patentpal-dev',

  ALICE_URL:
    process.env.VUE_APP_ENV === 'production'
      ? 'https://99fd0scrjc.execute-api.us-west-1.amazonaws.com/prod/alice'
      : process.env.VUE_APP_ENV === 'staging'
      ? // ? 'https://99fd0scrjc.execute-api.us-west-1.amazonaws.com/prod/alice'
        'https://vnatskx86g.execute-api.us-west-1.amazonaws.com/demo/alice-demo'
      : process.env.VUE_APP_ENV === 'demo'
      ? 'https://vnatskx86g.execute-api.us-west-1.amazonaws.com/demo/alice-demo'
      : 'https://dcm52fuhji.execute-api.us-west-1.amazonaws.com/dev/alice-dev',

  WORD_TRANSFER:
    process.env.VUE_APP_ENV === 'production'
      ? 'https://dcm52fuhji.execute-api.us-west-1.amazonaws.com/dev/word-transfer'
      : 'https://dcm52fuhji.execute-api.us-west-1.amazonaws.com/dev/word-transfer',

  OS:
    window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1
      ? 'windows'
      : window.navigator.userAgent.indexOf('Mac') !== -1
      ? 'mac'
      : window.navigator.userAgent.indexOf('X11') !== -1
      ? 'unix'
      : window.navigator.userAgent.indexOf('Linux') !== -1
      ? 'linux'
      : 'unknown',
};
