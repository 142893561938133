<!--
  This renders the figure editor using mxGraph.

  See mxGraph docs here: https://jgraph.github.io/mxgraph/docs/js-api/files/index-txt.html
-->

<template>
  <div class="root">
    <div :id="store.id" class="graphEditor" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { DiagramHelpers as dh, mxgraph, FigureNumberUpdater } from '@/support/diagram';
import { selectFile, readDataUrl } from '@/support/utilities';
import { AnalyticEventKeys as ak, figureConstants as dc } from '@/data/constants';

const { mxClient, mxEditor, mxUtils, mxGraph } = mxgraph;

mxGraph.prototype.isCellSelectable = function (cell) {
  const style = this.getCurrentCellStyle(cell);

  return this.isCellsSelectable() && !this.isCellLocked(cell) && style['editable'] !== 0;
};

export default {
  name: 'DiagramEditor',

  props: {
    store: Object,
    index: Number,
    ignoreFigNumChanges: Boolean,
  },

  data() {
    return {
      editor: null,
      graph: null,
      parent: null,
      editingCell: null,
      diagramScaleObserver: null,
    };
  },

  mounted() {
    this.initializeDiagram();
    this.renderDiagramFromStore(this.sequential);
    this.diagramScaleObserver = new ResizeObserver(() => {
      const scale = dh.calculateScale();
      if (scale !== this.graph.view.scale) {
        this.graph.view.scale = scale;
        this.graph.refresh();
      }
    });

    this.diagramScaleObserver.observe(document.querySelector('.panel-content'));
  },

  computed: {
    ...mapState('draft', {
      sequential: (state) => state.sequential,
      activeProfile: (state) => state.activeProfile,
    }),
  },

  watch: {
    'store.number': {
      handler(number, oldNumber) {
        if (!this.ignoreFigNumChanges) {
          this.$gtag.event(ak.ACTION_CHANGE_FIG_LABEL, { event_category: ak.CATEGORY_FIG });
          this.$emit('numberChanged', {
            number,
            oldNumber,
            overflow: this.store.overflow,
            id: this.store.id,
            oldIndex: this.index,
          });
        }
      },
    },

    'activeProfile.truncateText': {
      handler() {
        this.renderDiagramFromStore();
      },
    },
  },

  methods: {
    ...mapMutations({
      addNewLink: 'draft/addNewLink',
      removeLink: 'draft/removeLink',
      removeBlock: 'draft/removeBlock',
      addNewBlock: 'draft/addNewBlock',
      loadNextFigure: 'draft/loadNextFigure',
      setErrorState: 'error/setErrorState',
      updateDiagramBlock: 'draft/updateDiagramBlock',
      changeDescription: 'draft/changeDescription',
    }),

    initializeDiagram() {
      if (!mxClient.isBrowserSupported()) {
        mxUtils.error('Browser is not supported!', 200, false);
      } else {
        const container = document.getElementById(this.store.id);
        this.editor = new mxEditor();
        this.$emit('editorInitialized', this.editor);
        this.editor.setGraphContainer(container);
        this.graph = this.editor.graph;
        this.parent = this.editor.graph.getDefaultParent();

        dh.configGraph(this.graph, this);
        this.configurePopupMenu();
      }
    },

    updateGraph(cb) {
      const { graph } = this;

      try {
        graph.getModel().beginUpdate();
        cb(graph);
      } catch (err) {
        console.error(err);
        this.$store.commit('draft/setError', true);
      } finally {
        graph.getModel().endUpdate();
      }
    },

    async renderDiagramFromStore(sequential = false) {
      const { parent } = this;

      this.updateGraph(async (graph) => {
        graph.removeCells(graph.getChildVertices(parent));

        dh.renderFigNumber(this.store.number, graph, parent);
        if (this.store.illustration) await dh.createImage(this.store.illustration, this.graph);
        await dh.renderBlocks(
          this.store.blocks,
          graph,
          parent,
          sequential,
          this.$store.state.draft.activeProfile.truncateText
        );
        if (!this.store.illustration)
          await dh.renderLinks(this.store.links, graph, parent, sequential);
        if (sequential) this.loadNextFigure();
      });
    },

    handleImageSelect() {
      selectFile((e) => {
        readDataUrl(e.target.files[0], async (result) => {
          this.store.illustration = result;
          dh.repositionCells(this.graph);
          this.renderDiagramFromStore();
          const spec = await FigureNumberUpdater.changeBlockDiagramToIllustration(
            this.store.number
          );
          this.changeDescription(spec);
        });
      });
    },

    configurePopupMenu() {
      const { graph } = this;
      graph.popupMenuHandler.factoryMethod = (menu, cell) => {
        if (cell?.style.includes('shape=callout')) {
          const showBottomLeft = cell.style !== dc.CALLOUT_BLOCK_BOTTOM_LEFT;
          const showBottomRight = cell.style !== dc.CALLOUT_BLOCK_BOTTOM_RIGHT;
          const showTopLeft = cell.style !== dc.CALLOUT_BLOCK_TOP_LEFT;
          const showTopRight = cell.style !== dc.CALLOUT_BLOCK_TOP_RIGHT;
          const showNoCallout = cell.style !== dc.CALLOUT_BLOCK_NO_CALLOUT;

          menu.addItem(
            `Top-left${showTopLeft ? '' : ' <'}`,
            null,
            () => {
              cell.block.direction = 2;
              cell.style = dc.CALLOUT_BLOCK_TOP_LEFT;
              this.graph.refresh();
            },
            null,
            null,
            showTopLeft
          );

          menu.addItem(
            `Top-right${showTopRight ? '' : ' <'}`,
            null,
            () => {
              cell.block.direction = 3;
              cell.style = dc.CALLOUT_BLOCK_TOP_RIGHT;
              this.graph.refresh();
            },
            null,
            null,
            showTopRight
          );

          menu.addItem(
            `Bottom-left${showBottomLeft ? '' : ' <'}`,
            null,
            () => {
              cell.block.direction = 0;
              cell.style = dc.CALLOUT_BLOCK_BOTTOM_LEFT;
              this.graph.refresh();
            },
            null,
            null,
            showBottomLeft
          );

          menu.addItem(
            `Bottom-right${showBottomRight ? '' : ' <'}`,
            null,
            () => {
              cell.block.direction = 1;
              cell.style = dc.CALLOUT_BLOCK_BOTTOM_RIGHT;
              this.graph.refresh();
            },
            null,
            null,
            showBottomRight
          );

          menu.addItem(
            `No callout${showNoCallout ? '' : ' <'}`,
            null,
            () => {
              cell.block.direction = 4;
              cell.style = dc.CALLOUT_BLOCK_NO_CALLOUT;
              this.graph.refresh();
            },
            null,
            null,
            showNoCallout
          );
        } else {
          menu.addItem('Upload image', null, () => {
            this.handleImageSelect();
          });
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 100%;
  &:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.graphEditor {
  background-color: #ffffff;
  width: 100%;
  height: 100% !important;
  &:hover {
    .uploadButton {
      opacity: 1;
    }
  }
  .uploadButton {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    z-index: 22;
    top: 0;
    opacity: 0;
    transition: opacity 0.1s;
  }
}
</style>
