/**
 * This is where Vuex is initialized and configured
 *
 * Vuex is a global state management system for vue. We are using a modular approach
 * to state management.
 *
 * Please be aware that since this project uses Vue2 we are using Vuex version 3.
 *
 * If you migrate to Vue3 in the future you will need to switch to vuex version 4.
 *
 * Vuex docs: https://v3.vuex.vuejs.org/
 *
 * Vuex concepts to be aware of:
 * 1. State: https://v3.vuex.vuejs.org/guide/state.html
 * 2. Mutations: https://v3.vuex.vuejs.org/guide/mutations.html
 * 3. Actions: https://v3.vuex.vuejs.org/guide/actions.html
 * 4. Getter: https://v3.vuex.vuejs.org/guide/getters.html
 * 5. Modules: https://v3.vuex.vuejs.org/guide/modules.html
 *
 */

import Vue from 'vue';
import Vuex from 'vuex';
import draft from './modules/draft';
import outline from './modules/outline';
import upload from './modules/upload';
import error from './modules/error';
import tooltip from './modules/tooltip';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    draft,
    outline,
    upload,
    error,
    tooltip,
  },
});
