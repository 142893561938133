import mxgraph from './mxGraphIndex.js';
import { v4 as uuid } from 'uuid';
import { figureConstants as fc } from '@/data/constants';

const { mxEvent } = mxgraph;
/**
 * Graph listeners are what we use to plug into various event listeners in mxGraph
 */
export default class GraphListeners {
  constructor(graph, instance) {
    this.graph = graph;
    this.instance = instance;
    this.index = instance.index;
    this.editingCell = null;
  }

  /**
   * This saves the editing cell into the GraphListeners state so we can track any changes to it
   * @param {Object} sender - mxGraph instance, it's called sender because it's what sent the event
   */
  handleEditingStarted(sender) {
    this.editingCell = startEditing(sender);
    const editor = this.graph.cellEditor;
    const text = editor.textarea;

    mxEvent.addListener(text, 'blur', () => {
      editor.focusLost();
    });
  }

  /**
   * This updates the figures cells in response to changes, if the figure number
   * was changed updateDiagramBlock will update the block numbers in this figure only
   *
   * the logic for updating the spec and other figures is in `src/support/diagram/FigureNumberUpdater.js`
   * @param {} sender
   */
  updateCells(sender) {
    const { cells } = sender.selectionModel;
    cells.forEach((cell) => {
      this.updateDiagramBlock({ block: cell, index: this.index });
    });
  }

  /**
   * This listener is triggered when a cell is connected to line
   * @param {*} sender
   * @param {*} target
   */
  handleCellConnected(sender, target) {
    cellConnected(target, this.instance.addNewLink, this.index);
  }

  /**
   * This is triggered when the user finishes editing the cell and they stop focusing it (blur)
   * @param {T} sender
   */
  handleLabelChange(sender) {
    const { updateDiagramBlock, index } = this.instance;
    labelChanged({
      sender,
      updateDiagramBlock,
      index,
      editingCell: this.editingCell,
    });
  }

  /**
   * This is triggered after a cell has been moved. This loops through all the cells
   * in the figure and updates their blocks x and y value.
   *
   * The block key on the cell is a reference to the block stored in our global state.
   * Updating the x and y values here updates the x and y values in the global state.
   * This ensures our Visio and PowerPoint exports work correctly and that the
   * figure editor renders everything correctly.
   * @param {*} sender
   * @param {*} param1
   */
  handleCellsMoved(sender, { properties: { cells } }) {
    cells.forEach((cell) => {
      cell.block.x = cell.geometry.x / fc.UNIT_SIZE;
      cell.block.y = cell.geometry.y / fc.UNIT_SIZE;
    });
  }
}

/**
 * This function formats the new text the user has added resizes the block if
 * necessary.
 *
 * Currently, the only editable block is the figure label, so this may need to be reviewed
 * if you want to add support for editing other blocks as well
 * @param {*} param0
 */
function labelChanged({ sender, editingCell, updateDiagramBlock, index }) {
  const { textarea } = sender.cellEditor;
  let label = '';
  const f = textarea.innerText
    .replace(/<br>(?=<u>)|<br>(?!<[a-z]*\d*>)|<div>(?=<u>)|<div>(?!<[a-z]*\d*>)/gi, '\n')
    .replace(/(<\/?[a-z]\/?>)/g, '');
  const filteredText = f.replace(/(\d+[a-zA-z]*)/i, (match) => {
    label = match;
    return '';
  });
  let number = null;

  const isBlock = !!editingCell.block.id;
  if (isBlock) {
    const value = filteredText.endsWith(' ')
      ? `${filteredText}<u>${label}</u>`
      : `${filteredText.trim()}<br><u>${label}</u>`;
    editingCell.setValue(value);
  } else {
    const match = label.match(/\d+\w?/);
    number = match ? match[0].toUpperCase() : '';

    if (number.length > 0) {
      editingCell.block.text = `${fc.FIG_TAG_START}FIG. ${number}${fc.FIG_TAG_END}`;
    }

    editingCell.setValue(editingCell.block.text);

    sender.updateCellSize(editingCell);
  }

  updateDiagramBlock({
    block: editingCell,
    index,
    number,
  });
}

/**
 * This removes any HTML from the cell's text so that it's not rendered in the editor
 * This returns the cell that is being edited.
 *
 * @param {Object} sender - mxGraph instance
 * @returns {Object} - mxGraph cell that is being edited
 */
function startEditing(sender) {
  const { textarea, editingCell } = sender.cellEditor;
  const figRegex = new RegExp(`${fc.FIG_TAG_START}|${fc.FIG_TAG_END}`, 'g');
  textarea.textContent = editingCell.block.label
    ? `${editingCell.block.text} ${editingCell.block.label}`
    : editingCell.block.text.replace(figRegex, '');
  return editingCell;
}

/**
 * This function gets information about the mxGraph Edge and creates a new line from it.
 *
 * This function may need review, right now we don't allow users to create new
 * lines in the figure editor so it's possible this function may need to be updated
 * if you add that feature in the future.
 * @param {*} target
 * @param {*} addNewLink
 * @param {*} index
 */
function cellConnected(target, addNewLink, index) {
  const { edge } = target.properties;
  const uuidRegex = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
  if (edge.target) {
    edge.id = edge.id.match(uuidRegex) ? edge.id : uuid();
    addNewLink({ link: edge, index });
  }
}
