/**
 * This file contains most of the code for working with mxGraph in the figure editor
 *
 * Our figure editor is build using mxGraph and implementation is very much tied to
 * mxGraph.
 *
 * You can find mxGraph docs here: https://jgraph.github.io/mxgraph/docs/js-api/files/index-txt.html
 */

import mxgraph from './mxGraphIndex.js';
import GraphListeners from './GraphListeners';
import {
  checkWillWrap,
  getMaxChars,
  truncate,
  getTextWidth,
  getImageData,
} from '@/support/utilities';
import { figureConstants as fc, AnalyticEventKeys as ak } from '@/data/constants';

const {
  mxUtils,
  mxGraphHandler,
  mxConstants,
  mxEvent,
  mxImage,
  mxEllipse,
  mxCellRenderer,
  mxRectangle,
} = mxgraph;

/**
 * Used in the figure editor to ensure the figures do not look too big or small
 * After dynamically scaling the panels
 *
 * Calculates the scale of the figures based on the width of the panels
 * @returns
 */
export function calculateScale() {
  const panelWidth = document.querySelector('.panel-content').clientWidth;
  return panelWidth / fc.PANEL_WIDTH_PX;
}

/**
 * Used to correctly render callout lines
 *
 * Calculates the point positions of the callout line in a callout block depending on its direction
 * @param {Object} cell - mxGraph cell
 * @param {Number} unit - units to use in conversions
 * @param {Number} startX - X starting position
 * @param {Number} startY - Y starting position
 * @param {Boolean} isVisio - Adjusts the calculations to accommodate the rendering differences in visio.
 * @returns
 */
export function calculateCalloutLinePoints(cell, unit, startX = 0, startY = 0, isVisio = false) {
  const height = isVisio ? 1 : cell.height;
  const center = isVisio ? startY - height / 2 : startY + height / 2;
  const bottom = isVisio ? 0 : startY + height;
  const right = isVisio ? 1 : startX + cell.width;
  const left = isVisio ? 0 : startX;
  const top = isVisio ? 1 : startY;

  if (cell.direction === 0) {
    // bottom left
    return [
      { x: left + 2 * unit, y: center }, // Starting point
      { x: left + 1 * unit, y: center }, // Middle point
      { x: left, y: bottom }, // End point
    ];
  }

  if (cell.direction === 1) {
    // bottom right
    return [
      { x: right - 2 * unit, y: center }, // Starting point
      { x: right - 1 * unit, y: center }, // Middle point
      { x: right, y: bottom }, // End point
    ];
  }

  if (cell.direction === 2) {
    // top left
    return [
      { x: left + 2 * unit, y: center }, // Starting point
      { x: left + 1 * unit, y: center }, // Middle point
      { x: left, y: top }, // End point
    ];
  }

  if (cell.direction === 3) {
    // top right
    return [
      { x: right - 2 * unit, y: center }, // Starting point
      { x: right - 1 * unit, y: center }, // Middle point
      { x: right, y: top }, // End point
    ];
  }

  if (cell.direction === 4) {
    return [];
  }

  return [];
}

/**
 * Configures mxGraph during initialization.
 *
 * If you want to enable or disable a feature or change some property about
 * mxGraph this is where you should do it.
 * @param {*} graph
 * @param {*} instance
 */
export function configGraph(graph, instance) {
  mxConstants.RECTANGLE_ROUNDING_FACTOR = 0.5;

  // mxConstants.VERTEX_SELECTION_DASHED = false;
  // mxConstants.VERTEX_SELECTION_COLOR = '#0088cf';
  // graph.cellEditor.selectText = false;

  graph.graphHandler.removeCellsFromParent = false;
  graph.graphHandler.allowLivePreview = true;
  graph.graphHandler.maxLivePreview = 10;
  graph.htmlLabels = true;
  graph.resetEdgesOnresize = true;
  graph.resetEdgesOnMove = true;
  graph.resetEdgesOnConnect = true;
  graph.vertexLabelsMovable = false;
  graph.edgeLabelsMoveable = false;
  graph.centerZoom = true;
  graph.dropEnabled = false;
  graph.swimlaneNesting = false;

  // graph.cellsMovable = false;
  // graph.cellsResizable = false;

  graph.cellsSelectable = false;
  graph.enterStopsCellEditing = true;
  graph.view.scale = calculateScale();
  graph.pageFormat = mxConstants.PAGE_FORMAT_LETTER_PORTRAIT;
  graph.autoExtend = false;
  graph.maximumGraphBounds = new mxRectangle(0, 0, fc.PANEL_WIDTH_PX, fc.PANEL_HEIGHT_PX);
  graph.setConnectable(false);
  graph.setGridEnabled(false);
  graph.setCellsDisconnectable(false);
  graph.setPanning(false);
  graph.setAllowDanglingEdges(true);
  graph.setConstrainChildren(true);
  graph.setTooltips(false);

  // This disables editing in the editor. To re-enable editing either comment out, delete this line, or change it to true
  // graph.setEnabled(false);

  // show guides when dragging blocks
  mxGraphHandler.prototype.guidesEnabled = false;
  // Prevent area from scrolling when moving blocks
  mxGraphHandler.prototype.scrollOnMove = false;

  registerCustomShapes(graph);
  addGraphListeners(graph, instance);
  setDefaultBlockStyles(graph);
  setDefaultLineStyles(graph);
}

/**
 * Created this function so we had one place to register all of our custom shapes.
 * Right now we only have one. The thinking was if we added more they could be added
 * to this.
 *
 * @param {Object} graph - mxGraph instance
 */
function registerCustomShapes(graph) {
  registerCallout(graph);
}

/**
 * This is where we create our custom callout shape and add it to mxGraph
 */
function registerCallout() {
  function CalloutShape() {
    mxEllipse.call(this);
  }

  mxUtils.extend(CalloutShape, mxEllipse);

  CalloutShape.prototype.paintVertexShape = function (c, x, y, w, h) {
    if (this.style.direction === 1 || this.style.direction === 3) {
      this.style.align = 'left';
    } else {
      this.style.align = 'right';
    }

    const points = calculateCalloutLinePoints(
      { width: w, height: h, direction: this.style.direction },
      fc.UNIT_SIZE,
      x,
      y
    );
    c.begin();
    points.forEach(({ x, y }, i) => {
      i === 0 ? c.moveTo(x, y) : c.lineTo(x, y);
    });
    c.stroke();
  };

  mxCellRenderer.registerShape('callout', CalloutShape);
}

/**
 *
 * @param {Object} graph - mxGraph instance
 */
export function setDefaultBlockStyles(graph) {
  const styles = graph.getStylesheet().getDefaultVertexStyle();
  styles[mxConstants.STYLE_FONTSIZE] = fc.FONT_SIZE;
  styles[mxConstants.STYLE_STROKECOLOR] = fc.DEFAULT_STROKE_COLOR;
  styles[mxConstants.STYLE_FILLCOLOR] = fc.DEFAULT_FILL_COLOR;
  styles[mxConstants.STYLE_FONTCOLOR] = fc.DEFAULT_FONT_COLOR;
  styles[mxConstants.STYLE_STROKEWIDTH] = fc.DEFAULT_STROKE_WIDTH;
  styles[mxConstants.STYLE_FONTFAMILY] = fc.DEFAULT_FONT_FAMILY;
  styles[mxConstants.STYLE_SPACING_LEFT] = fc.DEFAULT_LABEL_PADDING;
  styles[mxConstants.STYLE_SPACING_RIGHT] = fc.DEFAULT_LABEL_PADDING;
}

/**
 *
 * @param {Object} graph mxGraph instance
 */
export function setDefaultLineStyles(graph) {
  const style = graph.getStylesheet().getDefaultEdgeStyle();
  style[mxConstants.STYLE_CURVED] = fc.DEFAULT_EDGE_CURVE;
  // style[mxConstants.STYLE_EDGE] = dc.DEFAULT_EDGE_STYLE;
  style[mxConstants.STYLE_STROKECOLOR] = fc.DEFAULT_STROKE_COLOR;
}

/**
 * mxGraph proves an event API we can plug into to add additional features and
 * functionality to our figure editor
 * @param {Object} graph - mxGraph instance
 * @param {Object} instance - Vue instance of the figureEditor component
 */
export function addGraphListeners(graph, instance) {
  const listeners = new GraphListeners(graph, instance);
  graph.addListener(mxEvent.EDITING_STARTED, listeners.handleEditingStarted.bind(listeners));
  graph.addListener(mxEvent.CELLS_MOVED, listeners.updateCells.bind(listeners));
  graph.addListener(mxEvent.CELL_CONNECTED, listeners.handleCellConnected.bind(listeners));
  graph.addListener(mxEvent.CELLS_RESIZED, listeners.updateCells.bind(listeners));
  graph.addListener(mxEvent.LABEL_CHANGED, listeners.handleLabelChange.bind(listeners));
  graph.addListener(mxEvent.CELLS_MOVED, listeners.handleCellsMoved.bind(listeners));
  graph.addListener(mxEvent.CLICK, (sender, e) => {
    const cell = e.getProperty('cell');
    if (cell && graph.isCellEditable(cell)) {
      graph.startEditingAtCell(cell);
      const { textarea } = graph.cellEditor;
      const range = document.createRange();
      range.selectNodeContents(textarea);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      instance.$gtag.event(ak.ACTION_CLICK, { event_category: ak.CATEGORY_FIG_LABEL_EDITOR });
    }
  });
}

/**
 * This is used to reposition cells and convert them into callouts after adding
 * an illustration
 * @param {Object} graph - mxGraph instance
 */
export function repositionCells(graph) {
  const cells = graph.getChildCells();
  let widestWidth = 0;
  let horzEnd = fc.PANEL_WIDTH;
  cells.forEach((cell, i) => {
    if (cell.edge) {
      graph.removeCells([cell]);
    } else if (cell.style.includes('shape=image')) {
      graph.removeCells([cell]);
    } else if (cell.block && 'width' in cell.block) {
      const prev = getPrevCell(cells, i);
      cell.block.width = getTextWidth(cell.block.label, fc.FONT_SIZE) / fc.UNIT_SIZE + 3;
      cell.block.height = 3;
      cell.block.x = horzEnd - cell.block.width - 1;
      cell.block.y = prev ? prev.block.y + prev.block.height + 1 : 1;
      cell.block.shape = fc.SHAPE_CALLOUT;

      if (cell.block.width > widestWidth) widestWidth = cell.block.width;

      if (cell.block.y + cell.block.height >= fc.PANEL_HEIGHT) {
        horzEnd -= widestWidth;
        cell.block.y = 1;
        cell.block.x = horzEnd - cell.block.width - 1;
      }
    }
  });
}

/**
 * Used when calculating cell positions. If a cells comes before the current cell
 * we need to offset the x position of the current cell by the height of the
 * previous cell.
 * @param {Object[]} cells - Array of mxGraph cells
 * @param {Number} index - index of the current cell
 * @returns
 */
function getPrevCell(cells, index) {
  const cell = cells[index - 1];
  if (
    !cell.style.includes('shape=image') &&
    'width' in cell?.block &&
    (cell.value !== 'Start') & (cell.value !== 'End')
  ) {
    return cell;
  }
  return null;
}

/**
 * Used after a user selects an image to add to the figure
 * @param {*} url
 * @param {*} graph
 */
export function insertIllustration(url, graph) {
  const image = new mxImage(url, fc.PANEL_WIDTH_PX, fc.PANEL_HEIGHT_PX);
  graph.setBackgroundImage(image);
}

/**
 * We have to get the dimensions of an image to add it to the style property of
 * it's cell or else it will not render correctly
 * @param {} url
 * @returns
 */
async function calculateImageDimensions(url) {
  const imageData = await getImageData(url);
  const imageAspectRatio =
    imageData.height > imageData.width
      ? imageData.width / imageData.height
      : imageData.height / imageData.width;

  let height = 0;
  let width = 0;
  let x = 0;
  let y = 0;

  if (imageData.height > imageData.width) {
    height = fc.CANVAS_HEIGHT * fc.UNIT_SIZE;
    width = height * imageAspectRatio;
    const xOffset = (fc.CANVAS_WIDTH - width / fc.UNIT_SIZE) / 2;
    y = fc.PAGE_TOP_PADDING * fc.UNIT_SIZE;
    x = (fc.PAGE_LEFT_PADDING + xOffset) * fc.UNIT_SIZE;
  } else {
    width = fc.CANVAS_WIDTH * fc.UNIT_SIZE;
    height = width * imageAspectRatio;
    const yOffset = (fc.CANVAS_HEIGHT - height / fc.UNIT_SIZE) / 2;
    x = fc.PAGE_LEFT_PADDING * fc.UNIT_SIZE;
    y = (fc.PAGE_TOP_PADDING + yOffset) * fc.UNIT_SIZE;
  }

  return { height, width, x, y };
}

/**
 * Illustrations are just blocks with shape=image. This creates that block and
 * inserts it into the figure.
 * @param {string} dataUrl
 * @param {*} graph
 */
export async function createImage(dataUrl, graph) {
  const { height, width, x, y } = await calculateImageDimensions(dataUrl);

  dataUrl = dataUrl.replace(';base64', '');

  graph.insertVertex(
    graph.getDefaultParent(),
    null,
    null,
    x,
    y,
    width,
    height,
    `${fc.BLOCK_IMG_STYLE};imageWidth=${width};imageHeight=${height};image=${dataUrl}`
  );
}

/**
 * Inserts a borderless block into diagram
 */
export function insertBorderlessBlock({ currentBlock, graph, parent }) {
  graph.insertVertex(
    parent,
    null,
    `${currentBlock.text} <u>${currentBlock.label}</u>`,
    currentBlock.x * fc.UNIT_SIZE,
    currentBlock.y * fc.UNIT_SIZE,
    160,
    30,
    'align=left;strokeColor=none;fill=none;whiteSpace=wrap;'
  );
}

/**
 * mxGraph cells do not have separate propeties for text and label so we use this
 * function to combine the text and label of a block into the proper format depending
 * on if the block is a parent or leaf block.
 * @param {*} block
 * @param {*} isParent
 * @param {*} truncateText
 * @returns
 */
function formatBlockText(block, isParent = false, truncateText = true) {
  let blockWidth = isParent ? block.width * fc.UNIT_SIZE - 2 : block.width * fc.UNIT_SIZE;
  blockWidth -= (fc.DEFAULT_LABEL_PADDING + 2) * 2;
  const textToMeasure = getTextToMeasure(block);
  let maxChars = getMaxChars(textToMeasure, blockWidth, fc.FONT_SIZE);

  // Adjust maximum characters to account for the label in parent blocks or the extra space in child blocks
  isParent ? (maxChars -= block.label.length - 1) : (maxChars += 1);

  // Truncate the text at the maximum characters if it will wrap
  const willWrap = checkWillWrap(textToMeasure, blockWidth, fc.FONT_SIZE);
  const text = willWrap && truncateText ? truncate(block.text, maxChars) : block.text;

  // Determine the separator. Parent blocks separate text and labels with a space. Child blocks separate with a new line
  const separator = block.text.length === 0 ? '' : isParent ? ' ' : '\n';

  // Return the formatted block text
  return block.text && block.shape !== fc.SHAPE_CALLOUT
    ? block.label
      ? `${text}${separator}<u>${block.label}</u>`
      : text
    : `${block.label}`;
}

/**
 * When determining where to truncate the text we need to check if the label is
 * on the same line as the text, if it is we need to do extra truncating to ensure
 * label is visible on block with truncated text
 * @param block
 * @returns {string} - String to measure. If block is a parent will include the block label, otherwise it will just be the block text
 */
function getTextToMeasure(block) {
  const isParent = block.children.length > 0;
  return isParent ? (block.label ? `${block.text} <u>${block.label}</u>` : block.text) : block.text;
}

/**
 * Inserts a block into the figure
 */
export function insertBlock({ currentBlock, graph, parent }, truncate = true) {
  const isParent = currentBlock.children.length > 0;
  const dontRender = !currentBlock.label.trim() && currentBlock.shape === fc.SHAPE_CALLOUT;
  const text = formatBlockText(
    currentBlock,
    isParent && currentBlock.shape !== fc.SHAPE_CALLOUT,
    truncate
  );

  if (!dontRender) {
    const cell = graph.insertVertex(
      parent,
      currentBlock.id,
      text,
      currentBlock.x * fc.UNIT_SIZE,
      currentBlock.y * fc.UNIT_SIZE,
      currentBlock.width * fc.UNIT_SIZE,
      currentBlock.height * fc.UNIT_SIZE,
      currentBlock.shape === fc.SHAPE_CALLOUT
        ? currentBlock.direction === 0
          ? fc.CALLOUT_BLOCK_BOTTOM_LEFT
          : currentBlock.direction === 1
          ? fc.CALLOUT_BLOCK_BOTTOM_RIGHT
          : currentBlock.direction === 2
          ? fc.CALLOUT_BLOCK_TOP_LEFT
          : fc.CALLOUT_BLOCK_TOP_RIGHT
        : isParent
        ? fc.BLOCK_PARENT_STYLE
        : currentBlock.shape === fc.SHAPE_PILL
        ? fc.BLOCK_PILL_STYLE
        : fc.BLOCK_CHILD_STYLE
    );

    cell.block = currentBlock;
  }
}

/**
 * Sets arrow style based on Link type
 * @param {string} type - Link type
 * @returns {String} Return Link arrow style
 */
export function getArrowDirections(type) {
  if (type === fc.UNDIRECTED) {
    return fc.STYLE_UNDIRECTED;
  }

  if (type === fc.OUTBOUND) {
    return fc.STYLE_OUTBOUND;
  }

  if (type === fc.INBOUND) {
    return fc.STYLE_INBOUND;
  }

  if (type === fc.BIDIRECTED) {
    return fc.STYLE_BIDIRECTED;
  }
}

/**
 * Point units are 1/72 of an inch so we multiply the desired fontSize by 1/72
 *
 * @param fontSize
 * @return {number}
 */
export function convertPtToIn(fontSize) {
  return fontSize * (1 / 72);
}

export function convertPxToIn(n) {
  return n * (1 / 96);
}

/**
 * Visio files use pt units and the browser uses px so we need to convert our px
 * font size into pt
 * @returns
 */
export function getVisioFontSize() {
  const ptSize = Math.round(fc.FONT_SIZE * 0.75);
  return convertPtToIn(ptSize);
}

/**
 * Visio files use pt units and the browser uses px so we need to convert our px
 * font size into pt
 * @returns
 */
export function getVisioFigFontSize() {
  const ptSize = Math.round(fc.FIG_FONT_SIZE * 0.75);
  return convertPtToIn(ptSize);
}

/**
 * This loops through iterable data using an interval. This is how we simulate the
 * sequential load of the figures by intentionally slowing down the iteration speed.
 * @param {*} iterable
 * @param {*} onInterval
 * @param {*} ms
 * @returns
 */
function intervalLoop(iterable, onInterval, ms) {
  return new Promise((resolve) => {
    let i = 0;
    const length = iterable.length;
    const interval = setInterval(async () => {
      if (i === length) {
        clearInterval(interval);
        resolve();
      } else {
        onInterval(iterable[i]);
      }
      i++;
    }, ms);
  });
}

export async function renderBlocks(blocks, graph, parent, sequential, truncate) {
  if (sequential) {
    return intervalLoop(
      blocks,
      (block) => {
        renderBlock(block, graph, parent, truncate);
      },
      50
    );
  } else {
    blocks.forEach((block) => {
      renderBlock(block, graph, parent, truncate);
    });
  }
}

export async function renderLinks(links, graph, parent, sequential) {
  if (sequential) {
    return intervalLoop(
      links,
      (link) => {
        renderLink(link, graph, parent);
      },
      50
    );
  } else {
    links.forEach((link) => {
      renderLink(link, graph, parent);
    });
  }
}

export function renderBlock(currentBlock, graph, parent, truncate) {
  if (currentBlock.width === 0) {
    insertBorderlessBlock({ currentBlock, parent, graph });
  } else {
    insertBlock({ currentBlock, parent, graph }, truncate);
  }
}

export function renderLink(link, graph, parent) {
  const model = graph.getModel();
  const source = model.getCell(link.head);
  const target = model.getCell(link.tail);
  const arrowDirections = getArrowDirections(link.type);

  graph.insertEdge(parent, link.id, null, source, target, `${arrowDirections}`);
}

export function renderFigNumber(num, graph, parent) {
  const text = `${fc.FIG_TAG_START}FIG. ${num}${fc.FIG_TAG_END}`;
  const fig = graph.insertVertex(
    parent,
    null,
    text,
    fc.FIG_X,
    fc.FIG_Y,
    fc.FIG_WIDTH * fc.UNIT_SIZE,
    fc.FIG_HEIGHT * fc.UNIT_SIZE,
    fc.FIG_STYLE
  );
  fig.setConnectable(false);
  fig.block = { text };
  graph.updateCellSize(fig);
}
